import * as React from "react";
import { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Container,
  useToast,
  Flex,
  SimpleGrid,
  StackDivider,
  SpaceProps,
  HStack,
  Tag,
  Link,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Center
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, baseUrl } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import { FaAccessibleIcon, FaBullseye, FaSearch, FaVolumeUp } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon, LatLng } from 'leaflet'
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import headerBg from "../assets/images/footer-background.jpg";
import cloudsBg from "../assets/images/cloud-background.png";
import { Field, Formik } from "formik";
import Masonry from "react-masonry-css";
import "../style/style.css";
import useSound from "use-sound";
import fart from "../assets/audio/fart.mp3";
import { MotionBox } from "../components/motion";

const markdownTheme = {
  p: props => {
    const { children } = props;
    return (
      <Text mb={2} fontSize={'0.8em'}>
        {children}
      </Text>
    );
  },
};

const Stay = (props) => {
  const toast = useToast();

  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(true);

  const [experiencesloading, setExperiencesLoading] = useState(false);
  const [experiences, setExperiences] = useState(['Explore Outdoors', 'Explore Indoors', 'Drinking Adventure', 'Culinary Adventure']);

  const [activitiesloading, setActivitiesLoading] = useState(false);
  const [activities, setActivities] = useState(['Kayaking', 'Fishing', 'Museums', 'Cocktails', 'Breakfast', 'Coffee']);

  const [selectedDestination, setSelectedDestination] = useState({});

  const [page, setPage] = useState({ attributes: {} });

  const bgColor = useColorModeValue('white', 'gray.900');
  const headingColor = useColorModeValue('gray.700', 'white');

  const position = [40.9134413, -77.7766082];

  const [play] = useSound(fart, {
    volume: 1
  });

  // drawers
  const { isOpen, onOpen, onClose } = useDisclosure()
  const firstField = useRef()

  const initialMarkers = [{ location: new LatLng(40.9134413, -77.7766082) }, { location: new LatLng(40.7880131, -77.8701239) }];
  const [markers, setMarkers] = useState(initialMarkers);

  const breakpointColumnsObj = {
    default: 3,
    1300: 2,
    900: 1
  };

  const LocationMarkers = () => {
    return (
      <React.Fragment>
        {markers.map(marker => <Marker
          position={marker.location}
          icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
          eventHandlers={{
            click: (e) => {
              setSelectedDestination(marker.data);
              onOpen();
            },
          }}>
        </Marker>)}
      </React.Fragment>
    );
  }

  const getDestinations = (values = null) => {
    let data = [];
    axios.post(`${apiHost}/accommodations/list`, values)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setDestinations(data);

          let geomarkers = [];
          data.forEach(marker => {
            geomarkers.push({ data: marker, location: new LatLng(marker.location_latitude, marker.location_longitude) })
          });
          setMarkers(geomarkers);

          setLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getExperiences = () => {
    let data = [];
    axios.get(`${apiHost}/experiences/list`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setExperiences(data);
          setExperiencesLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getActivities = () => {
    let data = [];
    axios.get(`${apiHost}/activities/list`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setActivities(data);
          setActivitiesLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getPage = () => {
    let data = [];
    axios.get(`${apiHost}/page/get/stay`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setPage(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  const handleReset = () => {
    document.getElementById("experience").value = "";
    document.getElementById("activity").value = "";
  };

  const handleDestinationClick = (destination) => {
    setSelectedDestination(destination);
    onOpen();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getPage();
    getDestinations();
    getExperiences();
    getActivities();
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title>{page.meta_title}</title>
        <meta name="description" content={page.meta_description} />
      </Helmet>
      <Box className="home-header-container" backgroundImage={`url(${page.header_image_url})`} backgroundPosition="center center" backgroundSize="cover" minHeight="60vh">
        <Container maxW={'5xl'}>
          <Stack
            textAlign={'center'}
            align={'center'}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 28 }}>
            <MotionBox
              w="100%"
              opacity="0"
              initial={{
                translateY: 180
              }}
              animate={{
                translateY: 0,
                opacity: 1,
                transition: {
                  delay: 0.4,
                  duration: 0.5
                }
              }}
            >
              <Heading
                as={'h1'}
                fontFamily="american"
                fontWeight={500}
                fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                color="white"
                lineHeight={'100%'}
                mt={{ base: 20, md: 0 }}>
                {page.title} {' '}
                <Text as={'div'} fontWeight={500}
                  fontSize={{ base: '6rem', md: '13rem' }} color="white" textShadow="2px 2px 8px rgba(0,0,0,0.5)">
                  Happy Valley
                </Text>
              </Heading>
            </MotionBox>
          </Stack>

        </Container>
      </Box>
      <Box backgroundImage={`url(${cloudsBg})`} backgroundPosition="top center" backgroundRepeat="no-repeat" backgroundSize="fit" marginTop="-350px" paddingTop="250px">
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mt={0}>
          <Box p={5}>
            {loading ? null : (
              <Masonry breakpointCols={breakpointColumnsObj}
                className="masonry-grid"
                columnClassName="masonry-grid_column">

                {destinations?.map((destination, index) => (
                  <Box
                    w={'100%'}
                    bg={bgColor}
                    boxShadow={'0 0 10px 2px #ccc'}
                    rounded={'md'}
                    _hover={{
                      bg: '#E0CEBE'
                    }}
                    d="inline-block"
                    mb={5}
                    className="masonry"
                  >
                    <Box
                      p={4}
                    >
                      <Link onClick={() => handleDestinationClick(destination)}>
                        <Image
                          src={`${destination.main_img}`}
                          layout={'fill'}
                          w={'100%'}
                          rounded={'md'}
                        />
                      </Link>
                      <Box position="relative">
                        <Link onClick={() => handleDestinationClick(destination)}>
                          <Heading
                            color={headingColor}
                            fontSize={'3xl'}
                            fontFamily={'american'}
                            pt={3}
                            lineHeight={'100%'}
                            pb={0}>
                            {destination.name}
                          </Heading>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                ))
                }
              </Masonry>
            )}

          </Box>
          <Box>
            <Box sx={{ position: '-webkit-sticky', /* Safari */ position: 'sticky', top: '40px', }}>
              <MapContainer center={position} zoom={12} scrollWheelZoom={false} style={{ minHeight: '100vh' }}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarkers />
              </MapContainer>
            </Box>
          </Box>
        </SimpleGrid>

      </Box>

      <Drawer
        isOpen={isOpen}
        size="lg"
        placement="left"
        closeOnOverlayClick={true}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <Heading pb={0}>{selectedDestination.name}</Heading>
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing="24px">
              <Image
                src={`${selectedDestination.header_img}`}
                width={'200px'}
                rounded={'md'}
              />
              <Text>{selectedDestination.description}</Text>

              <Box>
                <MapContainer center={[selectedDestination.location_latitude, selectedDestination.location_longitude]} zoom={16} scrollWheelZoom={false} style={{ minHeight: '250px' }}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker
                    position={[selectedDestination.location_latitude, selectedDestination.location_longitude]}
                    icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}>
                    <Popup closeButton={false}>
                      {selectedDestination.address}
                    </Popup>
                  </Marker>
                </MapContainer>
              </Box>
              <Center><Text>{selectedDestination.address}</Text></Center>
            </Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            {selectedDestination ? (
              <Button as={Link} href={`/d/${selectedDestination.slug}`} >More Information</Button>
            ) : null}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

    </PageSlideFade>
  );
};

export default Stay;
