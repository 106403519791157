import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import TopNav from "components/top-nav";
import Footer from "components/footer";
import Navigation from "components/navigation";

const Layout = () => {
  return (
    <Router>
      <TopNav />
      <Box
        textAlign="center"
        fontSize="xl"
        w={"100%"}
        mx="auto"
        minHeight="800px"
      >
        <Box pt={-10} pb={0}>
          <Navigation />
        </Box>
      </Box>
      <Footer />
    </Router>
  );
};

export default Layout;
