import * as React from "react";
import {
  Heading,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Button,
  Input,
  Textarea,
  useColorModeValue,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  useToast
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, recaptchaKey } from "../utils/Cons/Constants";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { useCallback, useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { useParams } from "react-router-dom";
import axios from "axios";

const Reset = () => {
  const toast = useToast();
  const titleColor = useColorModeValue("red.300", "red.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const { token } = useParams();

  function validatePassword(value) {
    let error;
    if (!value) {
      error = "Password is required";
    } else if (value.length < 6) {
      error = "Must be 6 characters or more";
    }
    return error;
  }

  const resetPassword = (values) => {
    axios.put(`${apiHost}/users/forgot`, values)
      .then(res => {

        if (res.statusText == '' || res.statusText == 'OK') {
          showToast('Password reset successfully! Login again please.', 'success');
          window.location.href = '/login';
        }
        
        return res;
      })
      .catch(err => { showToast(err.toString(), "error") });
  }

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageSlideFade>

      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
          <Flex
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "100px", sm: "30px" }}
            bg={bgColor}
            boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Reset Your Password
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Set your new password
            </Text>
              <Formik
                enableReinitialize
                initialValues={{ oldPassword: "", newPassword: "", confirmPassword: "", forgotToken: token }}
                onSubmit={(values, actions) => {
                  if (values.newPassword == values.confirmPassword) {
                    resetPassword(values);
                  } else {
                    showToast('Password and Confirm Password must match.', 'error')
                  }
                  actions.setSubmitting(false);
                }}
              >
                {({ values, handleChange, handleSubmit, isSubmitting }) => {
                  return (
                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>

<Field
                      name="newPassword"
                      validate={validatePassword}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.newPassword && form.touched.newPassword
                          }
                        >
                          <FormLabel htmlFor="newPassword" ms="4px" fontSize="sm" fontWeight="normal">
                            Password
                          </FormLabel>
                          <InputGroup size="md">
                            <Input
                              {...field}
                              id="newPassword"
                              pr="4.5rem"
                              borderRadius="15px"
                              fontSize="sm"
                              size="lg"
                              type={show ? "text" : "password"}
                              placeholder="Your new password"
                              value={values.newPassword}
                              onChange={handleChange}
                            />
                            <InputRightElement width="4.5rem">
                              <Button
                                h="2.5rem"
                                mt="0.5rem"
                                size="sm"
                                onClick={handleClick}
                              >
                                {show ? "Hide" : "Show"}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.newPassword}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="confirmPassword"
                      validate={validatePassword}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.confirmPassword && form.touched.confirmPassword
                          }
                        >
                          <FormLabel htmlFor="confirmPassword" ms="4px" fontSize="sm" fontWeight="normal">
                            Confirm Password
                          </FormLabel>
                          <InputGroup size="md">
                            <Input
                              {...field}
                              id="confirmPassword"
                              pr="4.5rem"
                              borderRadius="15px"
                              fontSize="sm"
                              size="lg"
                              type={show ? "text" : "password"}
                              placeholder="Your new password"
                              value={values.confirmPassword}
                              onChange={handleChange}
                            />
                            <InputRightElement width="4.5rem">
                              <Button
                                h="2.5rem"
                                mt="0.5rem"
                                size="sm"
                                onClick={handleClick}
                              >
                                {show ? "Hide" : "Show"}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.confirmPassword}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                      <Button
                        fontSize="10px"
                        type="submit"
                        bg="red.300"
                        w="100%"
                        h="45"
                        mb="20px"
                        color="white"
                        mt="20px"
                        _hover={{
                          bg: "red.200",
                        }}
                        _active={{
                          bg: "red.400",
                        }}
                        isLoading={isSubmitting}
                      >
                        RESET PASSWORD
                      </Button>

                    </form>
                  );
                }}
              </Formik>
          </Flex>
        </Flex>
      </Flex>
    </PageSlideFade>
  );
};

export default Reset;
