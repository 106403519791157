import { Box, Grid, GridItem, Heading, Link, SimpleGrid, Text, Container } from "@chakra-ui/react";
import React from "react";
import parser from "html-react-parser";

const ImagesGridBlock = ({ page, block }) => {

    return (
        <Box className="category-wrapper" pt={10} backgroundImage={`url(${block.bgimg})`} backgroundPosition="center center" backgroundSize="cover">
            <Container maxW={'6xl'}>
                {block.title != null ? (
                <Box className="experiences-header" pb={10}>
                    <Heading
                        as={'h2'}
                        fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                        textAlign={{ base: 'center', md: 'center' }}
                        lineHeight={'110%'}
                        color="#17325B">
                        {block.title}
                    </Heading>
                    {block.entry.content != null ? (
                        <Text className="content-block" textAlign={{ base: 'center', md: 'center' }}>
                            {parser(block.entry.content)}
                        </Text>
                    ) : null}
                </Box>
                ) : null}
                <Box pb={10}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={5}>
                        {block.entry.images?.map((entry, index) => (
                            <Box>
                                <Link href={entry.link} _hover={{ opacity: 0.8 }} target="_blank">
                                    <Box backgroundImage={`url(${entry.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight={{ base: "355px", md: "350px", lg: "225px" }} position="relative">
                                    {entry.title != null ? (
                                        <Heading textAlign="left" position="absolute" bottom="10px" left="20px" color="white" textShadow="0px 0px 5px #000" lineHeight="1em">{entry.title}</Heading>
                                    ) : null}
                                    {entry.subtitle != null ? (
                                        <Text fontSize="2xl" textAlign="left" position="absolute" top="10px" left="20px" color="white">{entry.subtitle}</Text>
                                    ) : null}
                                    </Box>
                                </Link>
                            </Box>
                        ))}
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>
    )
};

export default ImagesGridBlock;