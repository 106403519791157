import * as React from "react";
import {
  Heading,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Button,
  Input,
  Textarea,
  useColorModeValue,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  useToast
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, recaptchaKey } from "../utils/Cons/Constants";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { useCallback, useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { useParams } from "react-router-dom";
import axios from "axios";

const Forgot = () => {
  const toast = useToast();
  const titleColor = useColorModeValue("blue.800", "blue.800");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  const forgotPassword = (values) => {
    axios.post(`${apiHost}/users/forgot`, values)
      .then(res => {

        if (res.statusText == '' || res.statusText == 'OK') {
          showToast('Check your email to complete the password reset process.', 'success');
        }
        
        return res;
      })
      .catch(err => { showToast(err.toString(), "error") });
  }

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  return (
    <PageSlideFade>

      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
          <Flex
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "100px", sm: "30px" }}
            bg={bgColor}
            boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Forgot Your Password?
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Enter your HVAB Account Email
            </Text>

            <Formik
              enableReinitialize
              initialValues={{ email: "", message: "" }}
              onSubmit={(values, actions) => {
                forgotPassword(values);

                actions.setSubmitting(false);
              }}
            >
              {({ values, handleChange, handleSubmit, isSubmitting }) => {
                return (
                  <form onSubmit={handleSubmit} style={{ width: "100%" }}>

                    <Field
                      name="email"
                      validate={validateEmail}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.email && form.touched.email
                          }
                          mb="24px"
                        >
                          <FormLabel htmlFor="email" ms="4px" fontSize="sm" fontWeight="normal">
                            Email
                          </FormLabel>
                          <Input
                            {...field}
                            id="email"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Your email address"
                            size="lg"
                            value={values.email}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Button
                      fontSize="10px"
                      type="submit"
                      bg="blue.800"
                      w="100%"
                      h="45"
                      mb="20px"
                      color="white"
                      mt="20px"
                      _hover={{
                        bg: "blue.500",
                      }}
                      _active={{
                        bg: "blue.600",
                      }}
                      isLoading={isSubmitting}
                    >
                      EMAIL ME THE RESET LINK
                    </Button>

                  </form>
                );
              }}
            </Formik>
          </Flex>
        </Flex>
      </Flex>
    </PageSlideFade>
  );
};

export default Forgot;
