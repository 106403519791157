import * as React from "react";
import { useState, useEffect, useContext } from "react";
import {
  Heading,
  HStack,
  Flex,
  Text,
  Icon,
  FormControl,
  FormLabel,
  Switch,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  Link,
  useColorModeValue
} from "@chakra-ui/react";
import { FaLinkedin, FaApple } from 'react-icons/fa';
import { PageSlideFade } from "../components/page-transitions";
import { Formik, Field } from "formik";
import { AuthContext } from "../context/AuthContext";

const Login = (props) => {
  const titleColor = useColorModeValue("blue.800", "blue.800");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("blue.200", "rgba(255, 255, 255, 0.5)");

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const authContext = useContext(AuthContext);

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validatePassword(value) {
    let error;
    if (!value) {
      error = "Password is required";
    } else if (value.length < 6) {
      error = "Must be 6 characters or more";
    }
    return error;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageSlideFade>
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
          <Flex
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "10px", sm: "30px" }}
            bg={bgColor}
            boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Welcome Back
            </Heading>
            <Text
              fontSize="xl"
              color={textColor}
              fontWeight="bold"
              textAlign="center"
              mb="22px"
            >
              Sign In With
            </Text>
            <HStack spacing="15px" justify="center" mb="22px" display="none">
              <Flex
                justify="center"
                align="center"
                w="75px"
                h="75px"
                borderRadius="15px"
                border="1px solid lightgray"
                cursor="pointer"
                transition="all .25s ease"
                _hover={{ filter: "brightness(120%)", bg: bgIcons }}
              >
                <Link href="#">
                  <Icon
                    as={FaApple}
                    w="30px"
                    h="30px"
                    _hover={{ filter: "brightness(120%)" }}
                  />
                </Link>
              </Flex>
              <Flex
                justify="center"
                align="center"
                w="75px"
                h="75px"
                borderRadius="15px"
                border="1px solid lightgray"
                cursor="pointer"
                transition="all .25s ease"
                _hover={{ filter: "brightness(120%)", bg: bgIcons }}
              >
                <Link href="#">
                  <Icon
                    as={FaLinkedin}
                    w="30px"
                    h="30px"
                    _hover={{ filter: "brightness(120%)" }}
                  />
                </Link>
              </Flex>
            </HStack>
            <Text
              fontSize="lg"
              color="gray.400"
              fontWeight="bold"
              textAlign="center"
              mb="22px"
              display="none"
            >
              or
            </Text>
            <Formik
              enableReinitialize
              initialValues={{ email: "", password: "" }}
              onSubmit={(values, actions) => {
                authContext.loginUser(values, props.history);
                actions.setSubmitting(false);
              }}
            >
              {({ values, handleChange, handleSubmit, isSubmitting }) => {
                return (
                  <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <Field
                      name="email"
                      validate={validateEmail}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.email && form.touched.email
                          }
                          mb="24px"
                        >
                          <FormLabel htmlFor="email" ms="4px" fontSize="sm" fontWeight="normal">
                            Email
                          </FormLabel>
                          <Input
                            {...field}
                            id="email"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Your email address"
                            size="lg"
                            value={values.email}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="password"
                      validate={validatePassword}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                        >
                          <FormLabel htmlFor="password" ms="4px" fontSize="sm" fontWeight="normal">
                            Password
                          </FormLabel>
                          <InputGroup size="md">
                            <Input
                              {...field}
                              id="password"
                              pr="4.5rem"
                              borderRadius="15px"
                              fontSize="sm"
                              size="lg"
                              type={show ? "text" : "password"}
                              placeholder="Your password"
                              value={values.password}
                              onChange={handleChange}
                            />
                            <InputRightElement width="4.5rem">
                              <Button
                                h="2.5rem"
                                mt="0.5rem"
                                size="sm"
                                onClick={handleClick}
                              >
                                {show ? "Hide" : "Show"}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.password}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      fontSize="10px"
                      type="submit"
                      bg="blue.700"
                      w="100%"
                      h="45"
                      mb="20px"
                      color="white"
                      mt="20px"
                      _hover={{
                        bg: "blue.400",
                      }}
                      _active={{
                        bg: "blue.500",
                      }}
                      isLoading={isSubmitting}
                    >
                      SIGN IN
                    </Button>
                  </form>
                );
              }}
            </Formik>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                Forgot your password?
                <Link color={titleColor} href="https://api.happyvalley.com/users/password/forgot" target="_blank" ms="5px" fontWeight="bold">
                  Reset
                </Link>
              </Text>
              <Text color={textColor} fontWeight="medium">
                Don't have an account?
                <Link color={titleColor} href={'/register'} ms="5px" fontWeight="bold">
                  Sign Up
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </PageSlideFade>
  );
};

export default Login;
