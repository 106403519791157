import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import React, { useState, useEffect} from "react";

const FpAdBlock = ({ page, block }) => {

    useEffect(() => {
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549538301-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549591422-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549674006-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549730608-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549760008-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549787878-0') });
    }, []);

    return (
        <Box className="signals-wrapper" pt={10} pb={10}>
            <Container maxW={'7xl'}>
                <SimpleGrid columns={{ base: 2, md: 3, lg: 6 }} spacing={2}>
                    <Box>
                        <div id='div-gpt-ad-1725549538301-0'>
                        </div>
                    </Box>
                    <Box>
                        <div id='div-gpt-ad-1725549591422-0'>
                        </div>
                    </Box>
                    <Box>
                        <div id='div-gpt-ad-1725549674006-0'>
                        </div>
                    </Box>
                    <Box>
                        <div id='div-gpt-ad-1725549730608-0'>
                        </div>
                    </Box>
                    <Box>
                        <div id='div-gpt-ad-1725549760008-0'>
                        </div>
                    </Box>
                    <Box>
                        <div id='div-gpt-ad-1725549787878-0'>
                        </div>
                    </Box>
                </SimpleGrid>
            </Container>
        </Box>
    )
};

export default FpAdBlock;