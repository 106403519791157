import React, { createContext, useContext, useState } from 'react';
import { Box, Flex, Link } from '@chakra-ui/react';
import MotionBox from './motion-box';
import MotionText from './motion-text';

const transition = {
  type: 'spring',
  mass: 0.5,
  damping: 11.5,
  stiffness: 100,
  restDelta: 0.001,
  restSpeed: 0.001,
};

const MenuContext = createContext(null);

const useMenu = () => useContext(MenuContext);

export const MenuItem = ({ item, children, ...props }) => {
  const menu = useMenu();
  return (
    <Box
      onMouseEnter={() => {
        menu.setActiveItem(item);
      }}
      zIndex="50"
      borderRadius={0}
    >
      <Link href={props.main_link}>
        <MotionText
          color="white"
          bg="transparent"
          p={"10px"}
          _hover={{
            color: props.color,
            bg: props.bg
          }}
          transition={{ duration: 0.25 }}
          cursor="pointer"
          whiteSpace="nowrap"
          textTransform="uppercase"
          borderRadius={0}
          fontWeight="100"
          fontFamily="Titling Comp Medium"
          letterSpacing="1px"
          fontSize={{ base: "1.15em", md: "0.70em", lg: "1em", xl: "1.15em" }}
          animate={{
            color:
              menu.activeItem === item ? props.color : 'white',
            bg:
              menu.activeItem === item ? props.bg : 'transparent',
          }}
        >
          {item}
        </MotionText>
      </Link>
      {menu.activeItem !== null && props.cats > 0 && (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={transition}
        >
          {menu.activeItem === item && (
            <Box
              pos="absolute"
              top="calc(100%)"
              left={{ base: "-100px", md: "-50px", lg: "-100px" }}
              sx={{ flexGrow: 1 }}
              w="100%"
            >
              <MotionBox
                layoutId="active"
                bg={props.bg}
                borderWidth="1px"
                borderColor={props.color}
                transition={transition}
              >
                <MotionBox p={4} >
                  {children}
                </MotionBox>
              </MotionBox>
            </Box>
          )}
        </MotionBox>
      )}
    </Box>
  );
};

export const Menu = ({ children }) => {
  const [activeItem, setActiveItem] = useState(null);

  return (
    <MenuContext.Provider value={{ activeItem, setActiveItem }}>
      <Flex
        as="nav"
        display="inline-flex"
        p={8}
        gap={{ base: 2, md: 1, lg: 4, xl: 6 }}
        onMouseLeave={() => setActiveItem(null)}
      >
        {children}
      </Flex>
    </MenuContext.Provider>
  );
};
