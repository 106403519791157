import { Box, Grid, GridItem, Heading, Link, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import parser from "html-react-parser";

const MediaBlock = ({ page, block }) => {

    let firstImage = {};
    let secondImage = {};
    let thirdImage = {};
    if (block.entry.images) {
        firstImage = block.entry.images[0];
        if (block.entry.images[1]) {
            secondImage = block.entry.images[1];
        }
        if (block.entry.images[2]) {
            thirdImage = block.entry.images[2];
        }
    }

    return (
        <Box className="category-wrapper">
            {block.title && block.title != '' && block.entry ? (
            <Box textAlign="left" pt={5}>
                <div class="experiences-header">
                    <Heading
                        as={'h2'}
                        fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                        textAlign={{ base: 'center', md: 'center' }}
                        lineHeight={'110%'}>
                        {block.title}
                    </Heading>
                </div>
            </Box>
            ) : null}
            <Box>
                <SimpleGrid columns={{ base: 1, md: 2 }}>
                    <Box pt={5} pr={{ base: 0, md: 5}}>
                        <Link href={firstImage.link} _hover={{ opacity: 0.8 }}>
                            <Box backgroundImage={`url(${firstImage.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="250px" position="relative">
                                <Heading textAlign="left" position="absolute" bottom="10px" left="20px" color="white" textShadow="0px 0px 5px #000" lineHeight="1em">{firstImage.title}</Heading>
                                <Text fontSize="2xl" textAlign="left" position="absolute" bottom="10px" left="20px" color="white">{firstImage.subtitle}</Text>
                            </Box>
                        </Link>
                        <Grid templateColumns='repeat(5, 1fr)'>
                            <GridItem colSpan={3}>
                                <Link href={secondImage.link} _hover={{ opacity: 0.8 }}>
                                    <Box pt={5} pr={5}>
                                        <Box backgroundImage={`url(${secondImage.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="250px" position="relative">
                                            <Heading textAlign="left" position="absolute" bottom="10px" left="20px" color="white" textShadow="0px 0px 5px #000" lineHeight="1em">{secondImage.title}</Heading>
                                            <Text fontSize="2xl" textAlign="left" position="absolute" bottom="10px" left="20px" color="white">{secondImage.subtitle}</Text>
                                        </Box>
                                    </Box>
                                </Link>
                            </GridItem>
                            <GridItem colSpan={2}>
                                <Link href={thirdImage.link} _hover={{ opacity: 0.8 }}>
                                    <Box pt={5}>
                                        <Box backgroundImage={`url(${thirdImage.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="250px" position="relative">
                                            <Heading textAlign="left" position="absolute" bottom="10px" left="20px" color="white" textShadow="0px 0px 5px #000" lineHeight="1em">{thirdImage.title}</Heading>
                                            <Text fontSize="2xl" textAlign="left" position="absolute" bottom="10px" left="20px" color="white">{thirdImage.subtitle}</Text>
                                        </Box>
                                    </Box>
                                </Link>
                            </GridItem>
                        </Grid>
                    </Box>
                    <Box>
                        {block.entry.embed != null ? (
                            <Box backgroundImage={`url(${page.header_image_url})`} backgroundPosition="center center" backgroundSize="cover" minHeight="560px">
                                {parser(block.entry.embed)}
                            </Box>
                        ) : null}
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>
    )
};

export default MediaBlock;