import * as React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import {
  Avatar as AvatarImage,
  Box,
  Stack,
  HStack,
  VStack,
  Flex,
  Text,
  Button,
  ButtonGroup,
  Link,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useColorModeValue,
  useDisclosure,
  Tag,
  FormErrorMessage,
  SimpleGrid,
  Switch,
  RadioGroup,
  Radio,
  Tabs,
  TabPanels,
  TabPanel,
  TabList,
  Tab,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  List,
  ListItem,
  useToast,
  Container,
  Checkbox,
  Grid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Table,
  TableCaption,
  Thead,
  Tr,
  Tbody,
  Th,
  Td,
  Tfoot,
  Spacer,
  Skeleton,
  Center,
  Image,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import { item, PageSlideFade } from "../components/page-transitions";
import Header from "../components/header";
import Recurrence from "../components/recurrence";
import FileInput from "../components/file-input";
import { AuthContext } from "../context/AuthContext";
import { FaHeart, FaUtensils, FaHotel, FaCampground, FaCheck, FaTimes, FaUser, FaRocket, FaTrash, FaKey } from "react-icons/fa";
import axios from "axios";
import { apiHost } from "utils/Cons/Constants";
import { Field, Formik } from "formik";
import { WithContext as ReactTags } from 'react-tag-input';
import { MotionBox } from "../components/motion";
import CardSkeleton from "../components/card-skeleton";
import InputMask from "react-input-mask";
import Avatar from "react-avatar-edit";
import moment from "moment";
import { AiFillSetting } from "react-icons/ai";
import { BsCircleFill } from "react-icons/bs";
import { FaArrowsAlt } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Icon as MapIcon } from 'leaflet';
import parser from "html-react-parser";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { timeout } from "workbox-core/_private";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useHistory } from "react-router-dom";

const ANIMATION_DURATION = 0.5;

const UNDERLINE_COLOR = "#8A8AE5";

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Profile = (props) => {
  const token = localStorage.getItem("token");

  const authContext = useContext(AuthContext);
  const history = useHistory();

  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");

  const [pageSize, setPageSize] = useState(10);

  // status dialog
  const [isAlertOpen, setIsOpen] = useState(false)
  const onAlertClose = () => setIsOpen(false)
  const cancelRef = useRef()
  const [tempStatus, setTempStatus] = useState({})

  // drawers
  const { isOpen, onOpen, onClose } = useDisclosure()
  const firstField = useRef()

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose
  } = useDisclosure()
  const firstEditField = useRef()

  const {
    isOpen: isEditDestinationOpen,
    onOpen: onEditDestinationOpen,
    onClose: onEditDestinationClose
  } = useDisclosure()
  const firstEditDestinationField = useRef()

  const {
    isOpen: isViewDestinationOpen,
    onOpen: onViewDestinationOpen,
    onClose: onViewDestinationClose
  } = useDisclosure()

  const {
    isOpen: isEditTipOpen,
    onOpen: onEditTipOpen,
    onClose: onEditTipClose
  } = useDisclosure()

  const {
    isOpen: isViewTipOpen,
    onOpen: onViewTipOpen,
    onClose: onViewTipClose
  } = useDisclosure()

  const {
    isOpen: isEditEventOpen,
    onOpen: onEditEventOpen,
    onClose: onEditEventClose
  } = useDisclosure()

  const {
    isOpen: isViewEventOpen,
    onOpen: onViewEventOpen,
    onClose: onViewEventClose
  } = useDisclosure()

  const {
    isOpen: isEditListOpen,
    onOpen: onEditListOpen,
    onClose: onEditListClose
  } = useDisclosure()

  const {
    isOpen: isViewListOpen,
    onOpen: onViewListOpen,
    onClose: onViewListClose
  } = useDisclosure()

  const {
    isOpen: isEditReservationOpen,
    onOpen: onEditReservationOpen,
    onClose: onEditReservationClose
  } = useDisclosure()

  const {
    isOpen: isViewReservationOpen,
    onOpen: onViewReservationOpen,
    onClose: onViewReservationClose
  } = useDisclosure()

  const [user, setUser] = useState();
  const [profile, setProfile] = useState({ profile_started: '' });

  const [editProfileFields, setEditProfileFields] = useState({});
  const [addDestinationFields, setAddDestinationFields] = useState({});
  const [editDestinationFields, setEditDestinationFields] = useState({});
  const [addTipFields, setAddTipFields] = useState({});
  const [editTipFields, setEditTipFields] = useState({});
  const [addListFields, setAddListFields] = useState({});
  const [editListFields, setEditListFields] = useState({});
  const [addEventFields, setAddEventFields] = useState({});
  const [editEventFields, setEditEventFields] = useState({});
  const [addReservationFields, setAddReservationFields] = useState({});
  const [editReservationFields, setEditReservationFields] = useState({});

  const [destinationsCount, setDestinationsCount] = useState('0');
  const [tipsCount, setTipsCount] = useState('0');
  const [eventsCount, setEventsCount] = useState('0');
  const [listsCount, setListsCount] = useState('0');
  const [reservationsCount, setReservationsCount] = useState('0');
  const [favoritesCount, setFavoritesCount] = useState('0');

  const [allDestinations, setAllDestinations] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allEventsCategories, setAllEventsCategories] = useState([]);
  const [allAmenities, setAllAmenities] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [tips, setTips] = useState([]);
  const [events, setEvents] = useState([]);
  const [lists, setLists] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [favorites, setFavorites] = useState([]);

  const [logoFileData, setLogoFileData] = useState('');
  const [headerFileData, setHeaderFileData] = useState('');
  const [eventImageData, setEventImageData] = useState('');
  const [tipImageData, setTipImageData] = useState('');
  const [listImageData, setListImageData] = useState('');

  const modules = {
    toolbar: [
      [{ 'header': [2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'blockquote', 'link'],        // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['clean']                                         // remove formatting button
      /* 
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'direction': 'rtl' }],                         // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }], 
      [{ 'align': [] }],
      */
    ]
  };

  const toast = useToast();

  const [selectedContact, setSelectedContact] = useState(null);

  const [sortItems, setSortItems] = useState([{ id: 99991, destination: 99991, content: "List Item 1" }, { id: 99992, destination: 99992, content: "List Item 2" }, { id: 99993, destination: 99993, content: "List Item 3" }]);

  const [sortCategories, setSortCategories] = useState([{ id: 0, category: 0, name: "" }]);

  const [sortEventsCategories, setSortEventsCategories] = useState([{ id: 0, category: 0, name: "" }]);

  const [sortAmenities, setSortAmenities] = useState([{ id: 0, amenity: 0, name: "" }]);

  const [sortPhotos, setSortPhotos] = useState([{ id: 0, file: "" }]);

  const [tags, setTags] = useState([]);

  const [date, setDate] = useState(new Date());

  const bgPrevButton = "gray.100";
  const iconColor = "white";
  const [activeBullets, setActiveBullets] = useState({
    about: true,
    account: false,
    address: false
  });

  const [checkboxes, setCheckboxes] = useState({
    eat: false,
    stay: false,
    explore: false
  });

  const aboutTab = useRef();
  const accountTab = useRef();
  const addressTab = useRef();

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    console.log(tag);
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const resetTags = () => {
    setTags([]);
  };

  const countries = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: 'Cote D\'Ivoire', code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: 'Lao People\'S Democratic Republic', code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' }
  ];

  const states = [
    {
      "abbr": "AL",
      "name": " Alabama"
    },
    {
      "abbr": "AK",
      "name": " Alaska"
    },
    {
      "abbr": "AZ",
      "name": " Arizona"
    },
    {
      "abbr": "AR",
      "name": " Arkansas"
    },
    {
      "abbr": "CA",
      "name": " California"
    },
    {
      "abbr": "CO",
      "name": " Colorado"
    },
    {
      "abbr": "CT",
      "name": " Connecticut"
    },
    {
      "abbr": "DE",
      "name": " Delaware"
    },
    {
      "abbr": "FL",
      "name": " Florida"
    },
    {
      "abbr": "GA",
      "name": " Georgia"
    },
    {
      "abbr": "HI",
      "name": " Hawaii"
    },
    {
      "abbr": "ID",
      "name": " Idaho"
    },
    {
      "abbr": "IL",
      "name": " Illinois"
    },
    {
      "abbr": "IN",
      "name": " Indiana"
    },
    {
      "abbr": "IA",
      "name": " Iowa"
    },
    {
      "abbr": "KS",
      "name": " Kansas"
    },
    {
      "abbr": "KY",
      "name": " Kentucky"
    },
    {
      "abbr": "LA",
      "name": " Louisiana"
    },
    {
      "abbr": "ME",
      "name": " Maine"
    },
    {
      "abbr": "MD",
      "name": " Maryland"
    },
    {
      "abbr": "MA",
      "name": " Massachusetts"
    },
    {
      "abbr": "MI",
      "name": " Michigan"
    },
    {
      "abbr": "MN",
      "name": " Minnesota"
    },
    {
      "abbr": "MS",
      "name": " Mississippi"
    },
    {
      "abbr": "MO",
      "name": " Missouri"
    },
    {
      "abbr": "MT",
      "name": " Montana"
    },
    {
      "abbr": "NE",
      "name": " Nebraska"
    },
    {
      "abbr": "NV",
      "name": " Nevada"
    },
    {
      "abbr": "NH",
      "name": " New Hampshire"
    },
    {
      "abbr": "NJ",
      "name": " New Jersey"
    },
    {
      "abbr": "NM",
      "name": " New Mexico"
    },
    {
      "abbr": "NY",
      "name": " New York"
    },
    {
      "abbr": "NC",
      "name": " North Carolina"
    },
    {
      "abbr": "ND",
      "name": "  North Dakota"
    },
    {
      "abbr": "OH",
      "name": " Ohio"
    },
    {
      "abbr": "OK",
      "name": " Oklahoma"
    },
    {
      "abbr": "OR",
      "name": " Oregon"
    },
    {
      "abbr": "PA",
      "name": " Pennsylvania"
    },
    {
      "abbr": "RI",
      "name": " Rhode Island"
    },
    {
      "abbr": "SC",
      "name": " South Carolina"
    },
    {
      "abbr": "SD",
      "name": " South Dakota"
    },
    {
      "abbr": "TN",
      "name": " Tennessee"
    },
    {
      "abbr": "TX",
      "name": " Texas"
    },
    {
      "abbr": "UT",
      "name": " Utah"
    },
    {
      "abbr": "VT",
      "name": " Vermont"
    },
    {
      "abbr": "VA",
      "name": " Virginia"
    },
    {
      "abbr": "WA",
      "name": " Washington"
    },
    {
      "abbr": "WV",
      "name": " West Virginia"
    },
    {
      "abbr": "WI",
      "name": " Wisconsin"
    },
    {
      "abbr": "WY",
      "name": " Wyoming"
    }
  ];

  const [preview, setPreview] = useState({});

  let avatarColor = '';
  let statusText = '';

  const editDestinationOpen = (destination) => {
    let cats_list = [];
    if (destination.d_categories && destination.d_categories.length > 0) {
      destination.d_categories.forEach((c, index) => {
        cats_list.push({
          id: c.id,
          category: c.id,
          name: c.name,
        })
      });
    }
    setSortCategories(cats_list);

    let amenities_list = [];
    if (destination.d_amenities && destination.d_amenities.length > 0) {
      destination.d_amenities.forEach((a, index) => {
        amenities_list.push({
          id: a.id,
          amenity: a.id,
          name: a.name,
        })
      });
    }
    setSortAmenities(amenities_list);

    let gallery_list = [];
    if (destination.d_photos && destination.d_photos.length > 0) {
      destination.d_photos.forEach((p, index) => {
        gallery_list.push({
          id: p.id,
          file: '',
          src: p.src,
        })
      });
    }
    setSortPhotos(gallery_list);

    setEditDestinationFields({
      id: destination.id,
      ed_email: destination.email,
      ed_phoneNumber: destination.phone,
      ed_company: destination.name,
      ed_address: destination.address,
      ed_city: destination.city,
      ed_zip: destination.zip,
      ed_content: destination.content,
      ed_website: destination.website,
      ed_map_link: destination.map_link,
      ed_reservations_link: destination.reservations_link,
      ed_restaurant_link: destination.restaurant_link,
      ed_golf_link: destination.golf_link,
      ed_facebook_link: destination.facebook_link,
      ed_twitter_link: destination.twitter_link,
      ed_instagram_link: destination.instagram_link,
      ed_youtube_link: destination.youtube_link,
      ed_pinterest_link: destination.pinterest_link,
      ed_calendar: destination.google_calendar,
      ed_ics_calendar: destination.ics_calendar,
      main_img: destination.main_img,
      header_img: destination.header_img,
    })
    onEditDestinationOpen();
  }

  const viewDestinationOpen = (destination) => {
    let color = 'gray';
    if (destination.favorited) {
      color = 'red';
    }
    setEditDestinationFields({
      id: destination.id,
      destination: destination,
      color: color,
    })
    onViewDestinationOpen();
  }

  const viewEventOpen = (event) => {
    let color = 'gray';
    if (event.favorited) {
      color = 'red';
    }
    setEditEventFields({
      id: event.id,
      event: event,
      color: color,
    })
    onViewEventOpen();
  }

  const viewTipOpen = (tip) => {
    let color = 'gray';
    if (tip.favorited) {
      color = 'red';
    }
    setEditTipFields({
      id: tip.id,
      tip: tip,
      color: color,
    })
    onViewTipOpen();
  }

  const viewListOpen = (list) => {
    let color = 'gray';
    if (list.favorited) {
      color = 'red';
    }
    setEditListFields({
      id: list.id,
      list: list,
      color: color,
    })
    onViewListOpen();
  }

  const editTipOpen = (tip) => {
    setEditTipFields({
      id: tip.id,
      et_title: tip.title,
      et_content: tip.tip,
      et_destination: tip.destination_id,
      et_website: tip.website,
      et_social_link: tip.social_link
    })
    onEditTipOpen();
  }

  const datetimeLocal = (datetime) => {
    const dt = new Date(datetime);
    dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
    return dt.toISOString().slice(0, 16);
  }

  const editEventOpen = (event) => {
    let cats_list = [];
    if (event.d_categories && event.d_categories.length > 0) {
      event.d_categories.forEach((c, index) => {
        cats_list.push({
          id: c.id,
          category: c.id,
          name: c.name,
        })
      });
    }
    setSortEventsCategories(cats_list);

    debugger;

    setEditEventFields({
      id: event.id,
      ee_title: event.title,
      ee_start_at: datetimeLocal(event.starts),
      ee_end_at: datetimeLocal(event.ends),
      ee_content: event.entry.content,
      ee_destination: event.destination_id,
      ee_email: event.email,
      ee_phone: event.phone,
      ee_website: event.website,
      ee_social_link: event.social_link,
    })
    onEditEventOpen();
  }

  const editListOpen = (list) => {
    let items_list = [];
    if (list.items.length > 0) {
      list.items.forEach((i, index) => {
        items_list.push({
          id: i.destination_id,
          content: "List Item " + index,
          information: i.content,
          destination: i.destination_id,
        })
      });
    }

    setSortItems(items_list);
    setEditListFields({
      id: list.id,
      el_title: list.title,
      el_content: list.content,
      el_destination: list.destination_id
    })
    onEditListOpen();
  }

  const getData = () => {

    let data = {};
    axios.get(`${apiHost}/api/user`, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        setUser(res.data);
        data = res.data;
        setProfile({ profile_started: '' });
        setProfile({
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          phoneNumber: data.phone,
          city: data.city,
          zip: data.zip_code,
          state: data.state,
          country: data.country,
          company_name: data.company_name,
          company_address: data.company_address ? data.company_address : '',
          company_city: data.company_city ? data.company_city : '',
          company_zip: data.company_zip ? data.company_zip : '',
          profile_started: data.profile_started ? data.profile_started : '',
          company_content: data.company_content,
          website: data.website,
          social_link: data.social_link,
          calendar: data.calendar,
          image: data.img,
        });

        setEditProfileFields({
          ep_firstName: data.first_name,
          ep_lastName: data.last_name,
          ep_email: data.email,
          ep_phoneNumber: data.phone,
          ep_city: data.city,
          ep_zip: data.zip_code,
          ep_state: data.state,
          ep_country: data.country,
          ep_image: data.img,
        });

        if (data.interests_list.length > 0) {
          setCheckboxes({
            eat: data.interests_list.includes('Eat'),
            stay: data.interests_list.includes('Stay'),
            explore: data.interests_list.includes('Explore')
          })
        }

        return res;
      })
      .catch(err => {
        authContext.logout();

      });

    return data;
  };

  const getAllCategories = () => {
    let data = [];
    axios.post(`${apiHost}/categories/list`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setAllCategories(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getAllEventsCategories = () => {
    let data = [];
    axios.post(`${apiHost}/events/categories`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setAllEventsCategories(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getAllAmenities = () => {
    let data = [];
    axios.post(`${apiHost}/amenities/list`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setAllAmenities(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getAllDestinations = () => {
    let data = [];
    axios.post(`${apiHost}/destinations/search`, { order: "name" })
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setAllDestinations(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getDestinations = () => {
    let data = {};
    axios.get(`${apiHost}/api/destinations/user`, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);

        setDestinationsCount(data.length);
        setDestinations(data);

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const createDestination = (values) => {
    let image = null;
    if (logoFileData) {
      image = logoFileData;
    }

    let header_image = null;
    if (headerFileData) {
      header_image = headerFileData;
    }

    let data = {
      company_name: values.ad_company,
      email: values.ad_email,
      phone: values.ad_phoneNumber,
      address: values.ad_address,
      city: values.ad_city,
      zip_code: values.ad_zip,
      description: values.ad_content,
      calendar: values.ad_calendar,
      ics_calendar: values.ad_ics_calendar,
      website: values.ad_website,
      map_link: values.ad_map_link,
      reservations_link: values.ad_reservations_link,
      restaurant_link: values.ad_restaurant_link,
      golf_link: values.ad_golf_link,
      facebook_link: values.ad_facebook_link,
      twitter_link: values.ad_twitter_link,
      instagram_link: values.ad_instagram_link,
      youtube_link: values.ad_youtube_link,
      pinterest_link: values.ad_pinterest_link,
      image: image,
      header_image: header_image,
      categories: sortCategories,
      amenities: sortAmenities,
      photos: sortPhotos,
    };
    axios.post(`${apiHost}/api/destinations/create`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);
        onResetDestination();

        getDestinations();

        showToast("Added destination: " + values.ad_company, 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const updateDestination = (values) => {
    let image = null;
    if (logoFileData) {
      image = logoFileData;
    }

    let header_image = null;
    if (headerFileData) {
      header_image = headerFileData;
    }

    let data = {
      id: editDestinationFields.id,
      company_name: values.ed_company,
      email: values.ed_email,
      phone: values.ed_phoneNumber,
      address: values.ed_address,
      city: values.ed_city,
      zip_code: values.ed_zip,
      description: values.ed_content,
      calendar: values.ed_calendar,
      ics_calendar: values.ed_ics_calendar,
      website: values.ed_website,
      map_link: values.ed_map_link,
      reservations_link: values.ed_reservations_link,
      restaurant_link: values.ed_restaurant_link,
      golf_link: values.ed_golf_link,
      facebook_link: values.ed_facebook_link,
      twitter_link: values.ed_twitter_link,
      instagram_link: values.ed_instagram_link,
      youtube_link: values.ed_youtube_link,
      pinterest_link: values.ed_pinterest_link,
      image: image,
      header_image: header_image,
      categories: sortCategories,
      amenities: sortAmenities,
      photos: sortPhotos,
    };
    console.log(data);
    axios.post(`${apiHost}/api/destinations/update`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);
        onEditDestinationClose();
        resetCatList();
        resetAmenityList();
        resetPhotoList();
        getDestinations();

        showToast("Updated destination: " + data.name, 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const removeDestination = () => {
    let data = {
      id: editDestinationFields.id,
    };
    console.log(data);
    axios.post(`${apiHost}/api/destinations/delete`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);
        onEditDestinationClose();
        resetAmenityList();
        resetCatList();
        resetPhotoList();
        getDestinations();

        showToast("Destination removed!", 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getTips = () => {
    let data = {};
    axios.get(`${apiHost}/api/tips/user`, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;

        setTipsCount(data.length);
        setTips(data);

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const createTip = (values) => {
    let image = null;
    if (tipImageData) {
      image = tipImageData;
    }
    let data = {
      title: values.tip_title,
      tip: values.tip_content,
      destination: values.tip_destination,
      website: values.tip_website,
      social_link: values.tip_social_link,
      image: image,
    };
    axios.post(`${apiHost}/api/tips/create`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        onResetTip();
        getTips();

        showToast("Tip added: " + data.title, 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const updateTip = (values) => {
    let image = null;
    if (tipImageData) {
      image = tipImageData;
    }
    let data = {
      id: editTipFields.id,
      title: values.et_title,
      tip: values.et_content,
      destination: values.et_destination,
      website: values.et_website,
      social_link: values.et_social_link,
      image: image,
    };
    axios.post(`${apiHost}/api/tips/update`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        onEditTipClose();
        getTips();

        showToast("Tip updated: " + data.title, 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const removeTip = () => {
    let data = {
      id: editTipFields.id,
    };
    console.log(data);
    axios.post(`${apiHost}/api/tips/delete`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);
        onEditTipClose();
        getTips();

        showToast("Tip removed!", 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getEvents = () => {
    let data = {};
    axios.get(`${apiHost}/api/events/user`, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);

        setEventsCount(data.length);
        setEvents(data);

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const createEvent = (values) => {
    let image = null;
    if (eventImageData) {
      image = eventImageData;
    }

    let data = {
      title: values.ae_title,
      start_at: values.ae_start_at,
      end_at: values.ae_end_at,
      content: values.ae_content,
      destination: values.ae_destination,
      categories: sortEventsCategories,
      email: values.ae_email,
      phone: values.ae_phone,
      website: values.ae_website,
      social_link: values.ae_social_link,
      recurrence: values.recurrence,
      r_yearly_first: values.r_yearly_first,
      r_yearly_second: values.r_yearly_second,
      r_yearly_month: values.r_yearly_month,
      r_yearly_day: values.r_yearly_day,
      r_yearly_weekday: values.r_yearly_weekday,
      r_yearly_second_month: values.r_yearly_second_month,
      r_monthly_first: values.r_monthly_first,
      r_monthly_second: values.r_monthly_second,
      r_monthly_months: values.r_monthly_months,
      r_monthly_day: values.r_monthly_day,
      r_yearly_occurrence: values.r_yearly_occurrence,
      r_monthly_occurrence: values.r_monthly_occurrence,
      r_monthly_weekday: values.r_monthly_weekday,
      r_weekly_weeks: values.r_weekly_weeks,
      r_weekly_day: values.r_weekly_day,
      r_daily_days: values.r_daily_days,
      r_end_date: values.r_end_date,
      image: image,
    };

    axios.post(`${apiHost}/api/events/create`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);
        onResetEvent();
        getEvents();

        showToast("Event added: " + data.title, 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const updateEvent = (values) => {
    let image = null;
    if (eventImageData) {
      image = eventImageData;
    }

    let data = {
      id: editEventFields.id,
      title: values.ee_title,
      start_at: values.ee_start_at,
      end_at: values.ee_end_at,
      content: values.ee_content,
      destination: values.ee_destination,
      categories: sortEventsCategories,
      email: values.ee_email,
      phone: values.ee_phone,
      website: values.ee_website,
      social_link: values.ee_social_link,
      recurrence: values.recurrence,
      r_yearly_first: values.r_yearly_first,
      r_yearly_second: values.r_yearly_second,
      r_yearly_month: values.r_yearly_month,
      r_yearly_day: values.r_yearly_day,
      r_yearly_weekday: values.r_yearly_weekday,
      r_yearly_second_month: values.r_yearly_second_month,
      r_monthly_first: values.r_monthly_first,
      r_monthly_second: values.r_monthly_second,
      r_monthly_months: values.r_monthly_months,
      r_monthly_day: values.r_monthly_day,
      r_yearly_occurrence: values.r_yearly_occurrence,
      r_monthly_occurrence: values.r_monthly_occurrence,
      r_monthly_weekday: values.r_monthly_weekday,
      r_weekly_weeks: values.r_weekly_weeks,
      r_weekly_day: values.r_weekly_day,
      r_daily_days: values.r_daily_days,
      r_end_date: values.r_end_date,
      image: image,
    };
    console.log(data);
    axios.post(`${apiHost}/api/events/update`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);
        onEditEventClose();
        getEvents();

        showToast("Event updated: " + data.title, 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const removeEvent = () => {
    let data = {
      id: editEventFields.id,
    };
    console.log(data);
    axios.post(`${apiHost}/api/events/delete`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);
        onEditEventClose();
        getEvents();

        showToast("Event removed!", 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getLists = () => {
    let data = {};
    axios.get(`${apiHost}/api/lists/user`, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);

        setListsCount(data.length);
        setLists(data);

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const createList = (values) => {
    let image = null;
    if (listImageData) {
      image = listImageData;
    }
    let data = {
      title: values.list_title,
      content: values.list_content,
      destination: values.list_destination,
      image: image,
      items: sortItems,
    };
    axios.post(`${apiHost}/api/lists/create`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);
        onResetList();
        getLists();

        showToast("List added: " + values.list_title, 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const updateList = (values) => {
    let image = null;
    if (listImageData) {
      image = listImageData;
    }
    let data = {
      id: editListFields.id,
      title: values.el_title,
      content: values.el_content,
      destination: values.el_destination,
      image: image,
      items: sortItems,
    };
    console.log(data);
    axios.post(`${apiHost}/api/lists/update`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);
        onEditListClose();
        getLists();

        showToast("List updated: " + data.title, 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const removeList = () => {
    let data = {
      id: editListFields.id,
    };
    console.log(data);
    axios.post(`${apiHost}/api/lists/delete`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);
        onEditListClose();
        getLists();

        showToast("List removed!", 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getReservations = () => {
    let data = {};
    axios.get(`${apiHost}/api/reservations/user`, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);

        setReservationsCount(data.length);
        setReservations(data);

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const createReservation = (values) => {
    let data = {
      start_at: values.ar_start_date,
      end_at: values.ar_end_date,
      email: values.ar_email,
      phone: values.ar_phoneNumber,
      destination: values.ar_destination,
      reservation: values.ar_reservation,
    };
    axios.post(`${apiHost}/api/reservations/create`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);
        onResetReservation();
        getReservations();

        showToast("Reservation added!", 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getFavorites = () => {
    let data = {};
    axios.get(`${apiHost}/api/favorites/user`, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);

        setFavoritesCount(data.length);
        setFavorites(data);

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const createFavorite = (values, source) => {
    axios.post(`${apiHost}/api/favorites/create`, values, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        let removed = false;

        if (editDestinationFields.destination && editDestinationFields.destination.favorited && source == 'destination') {
          const d = editDestinationFields.destination;
          showToast("Favorite removed!", 'success');
          removed = true;
          d.favorited = false;
          setEditDestinationFields({
            id: d.id,
            destination: d,
            color: 'gray',
          })
        } else if (editDestinationFields.destination) {
          editDestinationFields.destination.favorited = true;
          setEditDestinationFields({
            id: editDestinationFields.destination.id,
            destination: editDestinationFields.destination,
            color: 'red',
          })
        }

        if (editEventFields.event && editEventFields.event.favorited && source == 'event') {
          const e = editEventFields.event;
          showToast("Favorite removed!", 'success');
          removed = true;
          e.favorited = false;
          setEditEventFields({
            id: e.id,
            event: e,
            color: 'gray',
          })
        } else if (editEventFields.event) {
          editEventFields.event.favorited = true;
          setEditEventFields({
            id: editEventFields.event.id,
            event: editEventFields.event,
            color: 'red',
          })
        }

        if (editTipFields.tip && editTipFields.tip.favorited && source == 'tip') {
          const t = editTipFields.tip;
          showToast("Favorite removed!", 'success');
          removed = true;
          t.favorited = false;
          setEditTipFields({
            id: t.id,
            tip: t,
            color: 'gray',
          })
        } else if (editTipFields.tip) {
          editTipFields.tip.favorited = true;
          setEditTipFields({
            id: editTipFields.tip.id,
            tip: editTipFields.tip,
            color: 'red',
          })
        }

        if (editListFields.list && editListFields.list.favorited && source == 'list') {
          const l = editListFields.list;
          showToast("Favorite removed!", 'success');
          removed = true;
          l.favorited = false;
          setEditListFields({
            id: l.id,
            list: l,
            color: 'gray',
          })
        } else if (editListFields.list) {
          editListFields.list.favorited = true;
          setEditListFields({
            id: editListFields.list.id,
            list: editListFields.list,
            color: 'red',
          })
        }

        if (!removed) {
          showToast("Favorite added!", 'success');
        }

        getFavorites();
        getDestinations();
        getEvents();
        getTips();
        getLists();

        return res;
      })
      .catch(err => console.log(err));

    return true;
  };

  const saveProfile = (currentCheckbox) => {
    const data = { ...checkboxes };
    if (currentCheckbox.eat !== undefined)
      data.eat = currentCheckbox.eat;
    if (currentCheckbox.stay !== undefined)
      data.stay = currentCheckbox.stay;
    if (currentCheckbox.explore !== undefined)
      data.explore = currentCheckbox.explore;

    axios.post(`${apiHost}/api/profile/interests`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        showToast("Profile Updated!", 'success');

        return res;
      })
      .catch(err => console.log(err));

    return true;
  };

  const removeFavorite = (selectedFavorite) => {
    let data = {
      id: selectedFavorite.id,
    };
    console.log(data);
    axios.post(`${apiHost}/api/favorites/delete`, data, {
      headers: { "Authorization": "Bearer " + token }
    })
      .then(res => {
        data = res.data;
        console.log(data);
        getFavorites();
        getDestinations();
        getTips();
        getLists();
        getEvents();

        showToast("Favorite removed!", 'success');

        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const viewFavorite = (selectedFavorite) => {
    let link = '';
    switch (selectedFavorite.type) {
      case 'Destination':
        link = `/d/${selectedFavorite.destination.slug}`
        break;
      case 'Event':
        link = `/events/${selectedFavorite.event.slug}`
        break;
      case 'Tip':
        link = `/tips/${selectedFavorite.tip.slug}`
        break;
      case 'List':
        link = `/lists/${selectedFavorite.list.slug}`
        break;
      default:
        link = '/';
        break;
    }
    history.push(link);
  };

  const onResetDestination = () => {
    setAddDestinationFields({
      ad_company: '',
      ad_email: '',
      ad_phoneNumber: '',
      ad_address: '',
      ad_city: '',
      ad_zip: '',
      ad_calendar: '',
      ad_website: '',
      ad_social_link: '',
      ad_content: '',
    });
  };

  const onResetTip = () => {
    setAddTipFields({
      tip_title: '',
      tip_content: '',
    });
  };

  const onResetList = () => {
    setAddListFields({
      list_title: '',
      list_content: '',
      list_destination: '',
    });
    setSortItems([{ id: 99991, destination: 99991, content: "List Item 1", information: "" }, { id: 99992, destination: 99992, content: "List Item 2", information: "" }, { id: 99993, destination: 99993, content: "List Item 3", information: "" }]);
  };

  const onResetReservation = () => {
    setAddReservationFields({
      ar_start_date: '',
      ar_end_date: '',
      ar_email: '',
      ar_phoneNumber: '',
      ar_destination: '',
      ar_reservation: '',
    });
  };

  const onResetEvent = () => {
    setAddEventFields({
      ae_title: '',
      ae_start_at: '',
      ae_end_at: '',
      ae_content: '',
      ae_destination: '',
      ae_email: '',
      ae_phone: '',
      ae_website: '',
      ae_social_link: '',
    });
  };

  const onCrop = (preview) => {
    setPreview(preview);
  };

  const onCloseCrop = (preview) => {
    setPreview(null);
  }

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 5000000) {
      alert("File is too big!");
      elem.target.value = "";
    };
  }

  const onEditSave = () => {
    getData();
    onEditClose();
  }

  const onWizardSave = () => {
    setTimeout(() => {
      getData();
      getData();
    }, 1000);
  }

  const onLogoChange = e => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, onLoadLogo);
  };

  const getBase64 = (file, onLoad) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };

  const onLoadLogo = fileString => {
    setLogoFileData(fileString);
  };

  const onHeaderChange = e => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, onHeaderLogo);
  };

  const onHeaderLogo = fileString => {
    setHeaderFileData(fileString);
  };

  const onEventImageChange = e => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, onLoadEventImage);
  };

  const onLoadEventImage = fileString => {
    setEventImageData(fileString);
  };

  const onTipImageChange = e => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, onLoadTipImage);
  };

  const onLoadTipImage = fileString => {
    setTipImageData(fileString);
  };

  const onListImageChange = e => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, onLoadListImage);
  };

  const onLoadListImage = fileString => {
    setListImageData(fileString);
  };

  function validateTitle(value) {
    let error;
    if (!value) {
      error = "Title is required";
    }
    return error;
  }

  function validateStart(value) {
    let error;
    if (!value) {
      error = "Start Date is required";
    }
    return error;
  }

  function validateEnd(value) {
    let error;
    if (!value) {
      error = "End Date is required";
    }
    return error;
  }

  function validateEvent(value) {
    let error;
    if (!value) {
      error = "Event information is required";
    }
    return error;
  }

  function validateTip(value) {
    let error;
    if (!value) {
      error = "Tip information is required";
    }
    return error;
  }

  function validateDestination(value) {
    let error;
    if (!value || value <= 0) {
      error = "Destination is required";
    }
    return error;
  }

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validateBusinessName(value) {
    let error;
    if (!value) {
      error = "Business Name is required";
    }
    return error;
  }

  function validatePhone(value) {
    let error;
    if (!value) {
      error = "Phone Number is required";
    }
    return error;
  }

  function validateFirstName(value) {
    let error;
    if (!value) {
      error = "First Name is required";
    }
    return error;
  }

  function validateLastName(value) {
    let error;
    if (!value) {
      error = "Last Name is required";
    }
    return error;
  }

  function validateAddress(value) {
    let error;
    if (!value) {
      error = "Address is required";
    }
    return error;
  }

  function validateCity(value) {
    let error;
    if (!value) {
      error = "City is required";
    }
    return error;
  }

  function validateZip(value) {
    let error;
    if (!value) {
      error = "Zip is required";
    }
    return error;
  }

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sortItems,
      result.source.index,
      result.destination.index
    );

    setSortItems(items);
  }

  const onDragEndCategories = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sortCategories,
      result.source.index,
      result.destination.index
    );

    setSortCategories(items);
  }

  const onDragEndEventsCategories = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sortEventsCategories,
      result.source.index,
      result.destination.index
    );

    setSortEventsCategories(items);
  }

  const onDragEndAmenities = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sortAmenities,
      result.source.index,
      result.destination.index
    );

    setSortAmenities(items);
  }

  const onDragEndPhotos = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sortPhotos,
      result.source.index,
      result.destination.index
    );

    setSortPhotos(items);
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 5,
    margin: `0 0 2px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#efefef",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: 5,
    width: '100%'
  });

  const addListItem = () => {
    let newItems = Array.from(sortItems);
    const nextItem = sortItems.length + 1;
    newItems.push({ id: nextItem, destination: nextItem, content: "List Item " + nextItem });
    setSortItems(newItems);
  };

  const resetList = () => {
    setSortItems([
      { id: 99991, destination: 99991, content: "List Item 1" },
      { id: 99992, destination: 99992, content: "List Item 2" },
      { id: 99993, destination: 99993, content: "List Item 3" }
    ]);
  };

  const addCatItem = () => {
    let newItems = Array.from(sortCategories);
    const nextItem = sortCategories.length + 9999;
    newItems.push({ id: nextItem, category: nextItem, name: "" });
    setSortCategories(newItems);
  };

  const resetCatList = () => {
    setSortCategories([
      { id: 9999, category: 9999, name: "" },
    ]);
  };

  const addEventsCatItem = () => {
    let newItems = Array.from(sortEventsCategories);
    const nextItem = sortEventsCategories.length + 9999;
    newItems.push({ id: nextItem, category: nextItem, name: "" });
    setSortEventsCategories(newItems);
  };

  const resetEventsCatList = () => {
    setSortEventsCategories([
      { id: 9999, category: 9999, name: "" },
    ]);
  };

  const addAmenityItem = () => {
    let newItems = Array.from(sortAmenities);
    const nextItem = sortAmenities.length + 9999;
    newItems.push({ id: nextItem, amenity: nextItem, name: "" });
    setSortAmenities(newItems);
  };

  const resetAmenityList = () => {
    setSortAmenities([
      { id: 9999, amenity: 9999, name: "" },
    ]);
  };

  const addPhotoItem = () => {
    let newItems = Array.from(sortPhotos);
    const nextItem = sortPhotos.length + 9999;
    newItems.push({ id: nextItem, file: "" });
    setSortPhotos(newItems);
  };

  const resetPhotoList = () => {
    setSortPhotos([
      { id: 9999, file: "" },
    ]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    getDestinations();
    getTips();
    getLists();
    getReservations();
    getEvents();
    getAllDestinations();
    getFavorites();
    getAllCategories();
    getAllAmenities();
    getAllEventsCategories();
  }, []);

  return (
    <Container maxW={'5xl'} pt={10} pb={10}>
      {!user && (
        <Box>
          <Skeleton height="750px" />
        </Box>
      )}

      {user && profile.profile_started == '' && (
        <Flex
          direction="column"
          minH="100vh"
          align="center"
          pt={{ base: "50px", lg: "75px" }}
          mb={10}
          bg="white"
          borderRadius="lg"
          boxShadow={'sm'}
        >
          <Flex
            direction="column"
            textAlign="center"
            mb={"25px"}
            w={'80%'}
          >
            <Text
              color={textColor}
              fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
              fontWeight="bold"
              mb="8px"
            >
              Build Your HVAB Profile
            </Text>
            <Text
              color="gray.400"
              fontWeight="normal"
              fontSize={{ base: "sm", md: "lg" }}
            >
              This information will let us know more about you and your business. Our site will adapt to your profile when you're signed in.
            </Text>
          </Flex>
          <Tabs variant="unstyled" mt="24px" display="flex" flexDirection="column">
            <TabList
              display="flex"
              align="center"
              alignSelf="center"
              justifySelf="center"
            >
              <Tab
                ref={aboutTab}
                _focus="none"
                w={{ base: "120px", md: "250px", lg: "300px" }}
                onClick={() =>
                  setActiveBullets({
                    about: true,
                    account: false,
                    address: false
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { base: "120px", md: "250px", lg: "300px" },
                    height: "3px",
                    bg: activeBullets.account ? textColor : "gray.200",
                    left: { base: "12px", md: "26px" },
                    top: { base: activeBullets.about ? "6px" : "4px", md: null },
                    position: "absolute",
                    bottom: activeBullets.about ? "40px" : "38px",
                    zIndex: 0,
                    transition: "all .3s ease"
                  }}
                >
                  <Icon
                    as={BsCircleFill}
                    color={activeBullets.about ? textColor : "gray.300"}
                    w={activeBullets.about ? "16px" : "12px"}
                    h={activeBullets.about ? "16px" : "12px"}
                    mb="8px"
                    zIndex={2}
                  />
                  <Text
                    color={activeBullets.about ? { textColor } : "gray.300"}
                    fontWeight={activeBullets.about ? "bold" : "normal"}
                    display={{ base: "none", md: "block" }}
                    fontSize="sm"
                  >
                    About
                  </Text>
                </Flex>
              </Tab>
              <Tab
                ref={accountTab}
                _focus="none"
                w={{ base: "120px", md: "250px", lg: "300px" }}
                onClick={() =>
                  setActiveBullets({
                    about: true,
                    account: true,
                    address: false
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { base: "120px", md: "250px", lg: "300px" },
                    height: "3px",
                    bg: activeBullets.address ? textColor : "gray.200",
                    left: { base: "12px", md: "28px" },
                    top: { base: activeBullets.account ? "6px" : "4px", md: null },
                    position: "absolute",
                    bottom: activeBullets.account ? "40px" : "38px",
                    zIndex: 0,
                    transition: "all .3s ease"
                  }}
                >
                  <Icon
                    as={BsCircleFill}
                    color={activeBullets.account ? textColor : "gray.300"}
                    w={activeBullets.account ? "16px" : "12px"}
                    h={activeBullets.account ? "16px" : "12px"}
                    mb="8px"
                    zIndex={2}
                  />
                  <Text
                    color={activeBullets.account ? { textColor } : "gray.300"}
                    fontWeight={activeBullets.account ? "bold" : "normal"}
                    transition="all .3s ease"
                    fontSize="sm"
                    _hover={{ color: textColor }}
                    display={{ base: "none", md: "block" }}
                  >
                    Account
                  </Text>
                </Flex>
              </Tab>
              <Tab
                ref={addressTab}
                _focus="none"
                w={{ base: "120px", md: "250px", lg: "300px" }}
                onClick={() =>
                  setActiveBullets({
                    about: true,
                    account: true,
                    address: true
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { base: "120px", md: "250px", lg: "300px" },
                    height: "3px",
                    // bg: activeBullets.profile ? textColor : "gray.200",
                    left: { base: "12px", md: "32px" },
                    top: { base: activeBullets.address ? "6px" : "4px", md: null },
                    position: "absolute",
                    bottom: activeBullets.address ? "40px" : "38px",
                    zIndex: 0,
                    transition: "all .3s ease"
                  }}
                >
                  <Icon
                    as={BsCircleFill}
                    color={activeBullets.address ? textColor : "gray.300"}
                    w={activeBullets.address ? "16px" : "12px"}
                    h={activeBullets.address ? "16px" : "12px"}
                    mb="8px"
                    zIndex={2}
                  />
                  <Text
                    color={activeBullets.address ? { textColor } : "gray.300"}
                    fontWeight={activeBullets.address ? "bold" : "normal"}
                    transition="all .3s ease"
                    fontSize="sm"
                    _hover={{ color: textColor }}
                    display={{ base: "none", md: "block" }}
                  >
                    Destination
                  </Text>
                </Flex>
              </Tab>
            </TabList>
            <Formik
              enableReinitialize
              initialValues={profile}
              onSubmit={(values, actions) => {
                if (preview) {
                  values.new_image = preview;
                }
                const checks = { ...checkboxes };
                values.eat = checks.eat;
                values.stay = checks.stay;
                values.explore = checks.explore;

                if (logoFileData) {
                  values.company_image = logoFileData;
                }
                authContext.updateUser(values, preview, props.history, true, onWizardSave.bind(this));
                actions.setSubmitting(false);
              }}
            >
              {({ values, handleChange, handleSubmit, isSubmitting }) => {
                return (
                  <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <TabPanels mt="24px" maxW={{ md: "90%", lg: "100%" }} mx="auto">
                      <TabPanel w={{ base: "330px", md: "600px" }} mx="auto">
                        <Box>
                          <Flex mb="40px">
                            <Flex
                              direction="column"
                              align="center"
                              justify="center"
                              textAlign="center"
                              w="80%"
                              mx="auto"
                            >
                              <Text
                                color={textColor}
                                fontSize="lg"
                                fontWeight="bold"
                                mb="4px"
                              >
                                Let's start with the basic information
                              </Text>
                              <Text color="gray.400" fontWeight="normal" fontSize="sm">
                                Let us know your name and email address. Use an address you
                                don't mind other users contacting you at
                              </Text>
                            </Flex>
                          </Flex>
                          <Box>
                            <Flex direction="column" w="100%">


                              <FormControl
                                mb="12px"
                              >
                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal" textAlign={{ base: 'center', md: 'left' }}>
                                  Profile Photo
                                </FormLabel>
                                <SimpleGrid columns={{ base: 1, md: 2 }}>
                                  <Box>
                                    <AvatarImage
                                      size="lg"
                                      width={100}
                                      height={100}
                                      layout="fixed"
                                      src={preview}
                                      borderWidth="3px"
                                      borderColor={avatarColor}
                                    />
                                  </Box>
                                  <Box>
                                    <Avatar
                                      label="Upload an Image"
                                      labelStyle={{ fontSize: "0.8em" }}
                                      width={300}
                                      height={200}
                                      imageHeight={200}
                                      cropRadius={80}
                                      onCrop={onCrop}
                                      onClose={onCloseCrop}
                                      onBeforeFileLoad={onBeforeFileLoad}
                                    />
                                  </Box>

                                </SimpleGrid>
                              </FormControl>

                              <Field
                                name="firstName"
                                validate={validateFirstName}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    isInvalid={
                                      form.errors.firstName && form.touched.firstName
                                    }
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="email" ms="4px" fontSize="sm" fontWeight="normal">
                                      First Name
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      ref={firstEditField}
                                      id="firstName"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="First Name"
                                      size="lg"
                                      value={values.firstName}
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {form.errors.firstName}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                              <Field
                                name="lastName"
                                validate={validateLastName}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    isInvalid={
                                      form.errors.lastName && form.touched.lastName
                                    }
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="email" ms="4px" fontSize="sm" fontWeight="normal">
                                      Last Name
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="lastName"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Last Name"
                                      size="lg"
                                      value={values.lastName}
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {form.errors.lastName}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="email"
                                validate={validateEmail}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    isInvalid={
                                      form.errors.email && form.touched.email
                                    }
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="email" ms="4px" fontSize="sm" fontWeight="normal">
                                      Email
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="email"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Email"
                                      size="lg"
                                      value={values.email}
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {form.errors.email}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                              <Field
                                name="phoneNumber"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="phoneNumber" ms="4px" fontSize="sm" fontWeight="normal">
                                      Phone
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      as={InputMask}
                                      id="phoneNumber"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Phone"
                                      size="lg"
                                      value={values.phoneNumber}
                                      onChange={handleChange}
                                      mask="999-999-9999"
                                    />
                                  </FormControl>
                                )}
                              </Field>



                              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                <Field
                                  name="city"
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      mb="12px"
                                    >
                                      <FormLabel htmlFor="city" ms="4px" fontSize="sm" fontWeight="normal">
                                        City
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="city"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="City"
                                        size="lg"
                                        value={values.city}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  )}
                                </Field>

                                <Field
                                  name="zip"
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      mb="12px"
                                    >
                                      <FormLabel htmlFor="zip" ms="4px" fontSize="sm" fontWeight="normal">
                                        Zip
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="zip"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="Zip"
                                        size="lg"
                                        value={values.zip}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  )}
                                </Field>
                              </SimpleGrid>
                              <Field
                                name="state"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl id='state' mb="12px">
                                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">State</FormLabel>
                                    <Select  {...field}
                                      id="state"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Select State"
                                      size="lg"
                                      value={values.state}
                                      onChange={handleChange}>
                                      {states?.map((state, index) => (
                                        <option key={`state-${index}`} value={state.abbr}>{state.name}</option>
                                      ))
                                      }
                                    </Select>
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="country"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl id='country' mb="12px">
                                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">Country</FormLabel>
                                    <Select  {...field}
                                      id="country"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Select Country"
                                      size="lg"
                                      value={values.country}
                                      onChange={handleChange}>
                                      {countries?.map((country, index) => (
                                        <option key={`country-${index}`} value={country.code}>{country.name}</option>
                                      ))
                                      }
                                    </Select>
                                  </FormControl>
                                )}
                              </Field>

                              <Button
                                variant="solid"
                                alignSelf="flex-end"
                                mt="24px"
                                mb={20}
                                onClick={() => accountTab.current.click()}
                              >
                                NEXT
                              </Button>
                            </Flex>
                          </Box>
                        </Box>
                      </TabPanel>
                      <TabPanel w={{ base: "330px", md: "600px" }} mx="auto">
                        <Box>
                          <Flex mb="40px">
                            <Flex
                              direction="column"
                              align="center"
                              justify="center"
                              textAlign="center"
                              w="80%"
                              mx="auto"
                            >
                              <Text
                                color={textColor}
                                fontSize="lg"
                                fontWeight="bold"
                                mb="4px"
                              >
                                What do you like to do in Happy Valley?
                              </Text>
                              <Text color="gray.400" fontWeight="normal" fontSize="sm">
                                What do you enjoy doing in your spare time?
                              </Text>
                            </Flex>
                          </Flex>
                          <Box>
                            <Flex direction="column" w="100%">
                              <Stack
                                direction={{ base: "column", md: "row" }}
                                spacing={{ base: "20px", lg: "35px" }}
                                alignSelf="center"
                                justifySelf="center"
                                mb="24px"
                              >
                                <Flex direction="column" align="center">
                                  <FormLabel w="150px" h="150px" cursor="pointer" mb="16px">
                                    <Flex
                                      w="100%"
                                      h="100%"
                                      borderRadius="12px"
                                      justify="center"
                                      transition=".5s all ease"
                                      border="1px solid lightgray"
                                      align="center"
                                      bg={checkboxes.eat ? "#8A8AE5" : "#E5E5FF"}
                                      _hover={{ opacity: "0.8" }}
                                    >
                                      <Checkbox
                                        onChange={() =>
                                          setCheckboxes((prevCheckboxes) => {
                                            return {
                                              ...prevCheckboxes,
                                              eat: !prevCheckboxes.eat
                                            };
                                          })
                                        }
                                        display="none"
                                      />
                                      <Icon
                                        as={FaUtensils}
                                        w="54px"
                                        h="54px"
                                        color={checkboxes.eat ? "#fff" : iconColor}
                                      />
                                    </Flex>
                                  </FormLabel>
                                  <Text color={textColor} fontWeight="bold" fontSize="md">
                                    Eat
                                  </Text>
                                </Flex>
                                <Flex direction="column" align="center">
                                  <FormLabel w="150px" h="150px" cursor="pointer" mb="16px">
                                    <Flex
                                      w="100%"
                                      h="100%"
                                      borderRadius="12px"
                                      justify="center"
                                      transition=".5s all ease"
                                      border="1px solid lightgray"
                                      align="center"
                                      bg={checkboxes.stay ? "#8A8AE5" : "#E5E5FF"}
                                      _hover={{ opacity: "0.8" }}
                                    >
                                      <Checkbox
                                        onChange={() =>
                                          setCheckboxes((prevCheckboxes) => {
                                            return {
                                              ...prevCheckboxes,
                                              stay: !prevCheckboxes.stay
                                            };
                                          })
                                        }
                                        display="none"
                                      />
                                      <Icon
                                        as={FaHotel}
                                        w="54px"
                                        h="54px"
                                        color={checkboxes.stay ? "#fff" : iconColor}
                                      />
                                    </Flex>
                                  </FormLabel>
                                  <Text color={textColor} fontWeight="bold" fontSize="md">
                                    Stay
                                  </Text>
                                </Flex>
                                <Flex direction="column" align="center">
                                  <FormLabel w="150px" h="150px" cursor="pointer" mb="16px">
                                    <Flex
                                      w="100%"
                                      h="100%"
                                      borderRadius="12px"
                                      justify="center"
                                      transition=".5s all ease"
                                      border="1px solid lightgray"
                                      align="center"
                                      bg={checkboxes.explore ? "#8A8AE5" : "#E5E5FF"}
                                      _hover={{ opacity: "0.8" }}
                                    >
                                      <Checkbox
                                        onChange={() =>
                                          setCheckboxes((prevCheckboxes) => {
                                            return {
                                              ...prevCheckboxes,
                                              explore: !prevCheckboxes.explore
                                            };
                                          })
                                        }
                                        display="none"
                                      />
                                      <Icon
                                        as={FaCampground}
                                        w="54px"
                                        h="54px"
                                        color={checkboxes.explore ? "#fff" : iconColor}
                                      />
                                    </Flex>
                                  </FormLabel>
                                  <Text color={textColor} fontWeight="bold" fontSize="md">
                                    Explore
                                  </Text>
                                </Flex>
                              </Stack>

                              <Flex justify="space-between">
                                <Button
                                  variant="solid"
                                  bg={bgPrevButton}
                                  alignSelf="flex-end"
                                  mt="24px"
                                  onClick={() => aboutTab.current.click()}
                                >
                                  PREV
                                </Button>
                                <Button
                                  variant="solid"
                                  alignSelf="flex-end"
                                  mt="24px"
                                  onClick={() => addressTab.current.click()}
                                >
                                  NEXT
                                </Button>
                              </Flex>
                            </Flex>
                          </Box>
                        </Box>
                      </TabPanel>
                      <TabPanel w={{ base: "330px", md: "600px" }} mx="auto">
                        <Box>
                          <Flex mb="40px">
                            <Flex
                              direction="column"
                              align="center"
                              justify="center"
                              textAlign="center"
                              w="80%"
                              mx="auto"
                            >
                              <Text
                                color={textColor}
                                fontSize="lg"
                                fontWeight="bold"
                                mb="4px"
                              >
                                Do you own or manage a Happy Valley destination?
                              </Text>
                              <Text color="gray.400" fontWeight="normal" fontSize="sm">
                                Let us know more about it to become a member.
                              </Text>
                            </Flex>
                          </Flex>
                          <Box>
                            <Flex direction="column" w="100%">

                              <Field
                                name="company_name"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="company_name" ms="4px" fontSize="sm" fontWeight="normal">
                                      Business Name
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="company_name"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="HVAB"
                                      size="lg"
                                      value={values.company_name}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="company_name"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="company_name" ms="4px" fontSize="sm" fontWeight="normal">
                                      Or Claim Existing Destination
                                    </FormLabel>
                                    <Select  {...field}
                                      id="company_name"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Select Destination"
                                      size="lg"
                                      value={values.company_name}
                                      onChange={handleChange}>
                                      {allDestinations?.map((d, index) => (
                                        <option key={`claim-d-${index}`} value={d.name}>{d.name}</option>
                                      ))
                                      }
                                    </Select>

                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="company_address"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="company_address" ms="4px" fontSize="sm" fontWeight="normal">
                                      Address
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="company_address"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Address"
                                      size="lg"
                                      value={values.company_address}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                <Field
                                  name="company_city"
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      mb="12px"
                                    >
                                      <FormLabel htmlFor="company_city" ms="4px" fontSize="sm" fontWeight="normal">
                                        City
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="company_city"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="City"
                                        size="lg"
                                        value={values.company_city}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  )}
                                </Field>

                                <Field
                                  name="company_zip"
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      mb="12px"
                                    >
                                      <FormLabel htmlFor="company_zip" ms="4px" fontSize="sm" fontWeight="normal">
                                        Zip
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="company_zip"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="Zip"
                                        size="lg"
                                        value={values.company_zip}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  )}
                                </Field>
                              </SimpleGrid>

                              <Field
                                name="company_content"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="company_content" ms="4px" fontSize="sm" fontWeight="normal">
                                      Destination Information
                                    </FormLabel>
                                    <ReactQuill theme="snow" value={values.company_content} onChange={field.onChange(field.name)} placeholder="About my exciting destination!" modules={modules} />
                                  </FormControl>
                                )}
                              </Field>

                              <FormControl
                                mb="12px"
                              >
                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                  Business Logo or Photo (size: 1000px x 1000px)
                                </FormLabel>
                                <HStack>
                                  <FileInput accept="image/*" onChange={onLogoChange}></FileInput>
                                </HStack>
                              </FormControl>

                              <Field
                                name="website"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="website" ms="4px" fontSize="sm" fontWeight="normal">
                                      Website (example: https://happyvalley.com)
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="website"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.website}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="calendar"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="calendar" ms="4px" fontSize="sm" fontWeight="normal">
                                      Events Public Google Calendar ID <Link color="gray" href="https://happyvalley.com/public-google-calendar" target="_blank">how to find</Link>
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="city"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="atlasobscura.com_3un9ajpqcp8gapehkt4ahlu6io@group.calendar.google.com"
                                      size="lg"
                                      value={values.calendar}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Flex justify="space-between" mb={20}>
                                <Button
                                  variant="solid"
                                  bg={bgPrevButton}
                                  alignSelf="flex-end"
                                  mt="24px"
                                  onClick={() => accountTab.current.click()}
                                >
                                  PREV
                                </Button>
                                <Button
                                  alignSelf="flex-end"
                                  mt="24px"
                                  variant="solid"
                                  type="submit"
                                  isLoading={isSubmitting}
                                >
                                  SAVE PROFILE
                                </Button>
                              </Flex>
                            </Flex>
                          </Box>
                        </Box>
                      </TabPanel>
                    </TabPanels>
                  </form>
                );
              }}
            </Formik>
          </Tabs>
        </Flex>
      )}

      {user && profile.profile_started !== '' && (
        <Box>
          <Header underlineColor={UNDERLINE_COLOR} mt={0} mb={0}>
            <Text float="left" mr={2} display="none">My Profile</Text>
            <ButtonGroup ml={2} mr={2} mb={2} float={{ base: "left", md: "left" }}>
              <Button size="sm" aria-label="Edit Profile" colorScheme="green" onClick={onEditOpen}>Edit</Button>
              <Button size="sm" aria-label="Change Password" colorScheme="green" as={Link} href="https://api.happyvalley.com/users/password/forgot" target="_blank">Change Password</Button>
            </ButtonGroup>
            <ButtonGroup ml={2} mr={2} mb={2} float={{ base: "left", md: "right" }}>
              <Button size="sm" mr={2} aria-label="Sign Out" colorScheme="green" onClick={() => authContext.logout(props.history)}>Sign Out</Button>
            </ButtonGroup>
          </Header>
          <Box
            p={4}
            bg={"white"}
            rounded="xl"
            borderWidth="1px"
            borderColor={"gray.100"}
            w="100%"
            h="100%"
            textAlign="left"
            align="start"
            spacing={4}
            mt={5}
            ml={0}
            _hover={{ shadow: "lg" }}
          >
            <SimpleGrid columns={{ base: 1, md: 2 }}>
              <HStack>
                <AvatarImage
                  size="lg"
                  width={100}
                  height={100}
                  layout="fixed"
                  src={profile.image}
                  borderWidth="3px"
                  borderColor={avatarColor}
                />
                <VStack align="start" justify="flex-start">
                  <VStack spacing={0} align="start">
                    <Text
                      fontWeight="bold"
                      fontSize="md"
                      noOfLines={1}
                    >
                      {profile.firstName} {profile.lastName}
                    </Text>
                    <Text
                      fontSize="md"
                      color={textColor}
                    // noOfLines={{ base: isOpen ? 5 : 2 }}
                    >
                      {profile.email}
                    </Text>
                    <Text
                      fontSize="md"
                      color={textColor}
                    // noOfLines={{ base: isOpen ? 5 : 2 }}
                    >
                      {profile.phoneNumber}
                    </Text>
                    <Text
                      fontSize="sm"
                      color={textColor}
                    // noOfLines={{ base: isOpen ? 5 : 2 }}
                    >
                      {profile.city} {profile.state} {profile.zip} {profile.country}
                    </Text>
                  </VStack>
                </VStack>
              </HStack>
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "20px", md: "5px", lg: "25px" }}
                alignSelf="center"
                justifySelf="center"
                mr={{ base: 0, md: 10 }}
                mt={{ base: 5, md: 0 }}
              >
                <Flex direction="column" align="center">
                  <FormLabel w="150px" h="150px" cursor="pointer" mb="16px">
                    <Flex
                      w="100%"
                      h="100%"
                      borderRadius="12px"
                      justify="center"
                      transition=".5s all ease"
                      border="1px solid lightgray"
                      align="center"
                      bg={checkboxes.eat ? "#8A8AE5" : "#E5E5FF"}
                      _hover={{ opacity: "0.8" }}
                    >
                      <Checkbox
                        onChange={() =>
                          setCheckboxes((prevCheckboxes) => {
                            saveProfile({ eat: !checkboxes.eat });

                            return {
                              ...prevCheckboxes,
                              eat: !prevCheckboxes.eat
                            };
                          })
                        }
                        display="none"
                      />
                      <Icon
                        w="54px"
                        h="54px"
                        as={FaUtensils}
                        color={checkboxes.eat ? "#fff" : iconColor}
                      />
                    </Flex>
                  </FormLabel>
                  <Text color={textColor} fontWeight="bold" fontSize="md">
                    Eat
                  </Text>
                </Flex>
                <Flex direction="column" align="center">
                  <FormLabel w="150px" h="150px" cursor="pointer" mb="16px">
                    <Flex
                      w="100%"
                      h="100%"
                      borderRadius="12px"
                      justify="center"
                      transition=".5s all ease"
                      border="1px solid lightgray"
                      align="center"
                      bg={checkboxes.stay ? "#8A8AE5" : "#E5E5FF"}
                      _hover={{ opacity: "0.8" }}
                    >
                      <Checkbox
                        onChange={() =>
                          setCheckboxes((prevCheckboxes) => {
                            saveProfile({ stay: !checkboxes.stay });

                            return {
                              ...prevCheckboxes,
                              stay: !prevCheckboxes.stay
                            };
                          })
                        }
                        display="none"
                      />
                      <Icon
                        w="54px"
                        h="54px"
                        as={FaHotel}
                        color={checkboxes.stay ? "#fff" : iconColor}
                      />
                    </Flex>
                  </FormLabel>
                  <Text color={textColor} fontWeight="bold" fontSize="md">
                    Stay
                  </Text>
                </Flex>
                <Flex direction="column" align="center">
                  <FormLabel w="150px" h="150px" cursor="pointer" mb="16px">
                    <Flex
                      w="100%"
                      h="100%"
                      borderRadius="12px"
                      justify="center"
                      transition=".5s all ease"
                      border="1px solid lightgray"
                      align="center"
                      bg={checkboxes.explore ? "#8A8AE5" : "#E5E5FF"}
                      _hover={{ opacity: "0.8" }}
                    >
                      <Checkbox
                        onChange={() =>
                          setCheckboxes((prevCheckboxes) => {
                            saveProfile({ explore: !checkboxes.explore });

                            return {
                              ...prevCheckboxes,
                              explore: !prevCheckboxes.explore
                            };
                          })
                        }
                        display="none"
                      />
                      <Icon
                        w="54px"
                        h="54px"
                        as={FaCampground}
                        color={checkboxes.explore ? "#fff" : iconColor}
                      />
                    </Flex>
                  </FormLabel>
                  <Text color={textColor} fontWeight="bold" fontSize="md">
                    Explore
                  </Text>
                </Flex>
              </Stack>
            </SimpleGrid>
          </Box>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: '5', md: '6' }} mt={5}>
            <Box
              px={{ base: '4', md: '6' }}
              py={{ base: '5', md: '6' }}
              bg="white"
              borderRadius="lg"
              boxShadow={'sm'}
            >
              <Stack>
                <Text fontSize="sm" color="muted">
                  Your Destinations
                </Text>
                <Text>{destinationsCount}</Text>
              </Stack>
            </Box>
            <Box
              px={{ base: '4', md: '6' }}
              py={{ base: '5', md: '6' }}
              bg="white"
              borderRadius="lg"
              boxShadow={'sm'}
            >
              <Stack>
                <Text fontSize="sm" color="muted">
                  Your Events
                </Text>
                <Text>{eventsCount}</Text>
              </Stack>
            </Box>
            <Box
              px={{ base: '4', md: '6' }}
              py={{ base: '5', md: '6' }}
              bg="white"
              borderRadius="lg"
              boxShadow={'sm'}
            >
              <Stack>
                <Text fontSize="sm" color="muted">
                  Your Favorites
                </Text>
                <Text>{favoritesCount}</Text>
              </Stack>
            </Box>
            <Box
              px={{ base: '4', md: '6' }}
              py={{ base: '5', md: '6' }}
              bg="white"
              borderRadius="lg"
              boxShadow={'sm'}
            >
              <Stack>
                <Text fontSize="sm" color="muted">
                  Your Local Tips
                </Text>
                <Text>{tipsCount}</Text>
              </Stack>
            </Box>
            <Box
              px={{ base: '4', md: '6' }}
              py={{ base: '5', md: '6' }}
              bg="white"
              borderRadius="lg"
              boxShadow={'sm'}
            >
              <Stack>
                <Text fontSize="sm" color="muted">
                  Your Lists
                </Text>
                <Text>{listsCount}</Text>
              </Stack>
            </Box>
            <Box
              px={{ base: '4', md: '6' }}
              py={{ base: '5', md: '6' }}
              bg="white"
              borderRadius="lg"
              boxShadow={'sm'}
            >
              <Stack>
                <Text fontSize="sm" color="muted">
                  Your Reservations
                </Text>
                <Text>{reservationsCount}</Text>
              </Stack>
            </Box>

          </SimpleGrid>
          <Tabs variant="enclosed" w="100%" bg="white" mt={5}>
            <TabList overflowX="auto">
              <Tab
                whiteSpace={'nowrap'}
                textTransform="uppercase"
                pt={'25px'}
                borderRadius={0}
                fontWeight="bold"
                _selected={{ color: "#000", bg: "#efefef" }}
              >
                Destinations
              </Tab>
              <Tab
                whiteSpace={'nowrap'}
                textTransform="uppercase"
                pt={'25px'}
                borderRadius={0}
                fontWeight="bold"
                _selected={{ color: "#000", bg: "#efefef" }}
              >
                Events
              </Tab>
              <Tab
                whiteSpace={'nowrap'}
                textTransform="uppercase"
                pt={'25px'}
                borderRadius={0}
                fontWeight="bold"
                _selected={{ color: "#000", bg: "#efefef" }}
              >
                Tips
              </Tab>
              <Tab
                whiteSpace={'nowrap'}
                textTransform="uppercase"
                pt={'25px'}
                borderRadius={0}
                fontWeight="bold"
                _selected={{ color: "#000", bg: "#efefef" }}
              >
                Lists
              </Tab>
              <Tab
                whiteSpace={'nowrap'}
                textTransform="uppercase"
                pt={'25px'}
                borderRadius={0}
                fontWeight="bold"
                _selected={{ color: "#000", bg: "#efefef" }}
              >
                Reservations
              </Tab>
              <Tab
                whiteSpace={'nowrap'}
                textTransform="uppercase"
                pt={'25px'}
                borderRadius={0}
                fontWeight="bold"
                _selected={{ color: "#000", bg: "#efefef" }}
              >
                Favorites
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>

                {destinations.length <= 0 && (
                  <Box><h2>Add Happy Valley Destinations you own or manage!</h2></Box>
                )}

                <Accordion allowToggle defaultIndex={-1}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex='1' textAlign='left'>
                          Add Destination
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} maxW={{ md: "80%" }} mx="auto">
                      <Formik
                        enableReinitialize
                        initialValues={addDestinationFields}
                        onSubmit={(values, actions) => {
                          createDestination(values);
                          actions.setSubmitting(false);
                        }}
                      >
                        {({ values, handleChange, handleSubmit, resetForm, isSubmitting }) => {
                          return (
                            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                              <Field
                                name="ad_company"
                                validate={validateBusinessName}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                    isInvalid={
                                      form.errors.ad_company && form.touched.ad_company
                                    }
                                  >
                                    <FormLabel htmlFor="ad_company" ms="4px" fontSize="sm" fontWeight="normal">
                                      Business Name
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_company"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="HVAB"
                                      size="lg"
                                      value={values.ad_company}
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {form.errors.ad_company}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mb="12px">
                                <Field
                                  name="ad_email"
                                  validate={validateEmail}
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      isInvalid={
                                        form.errors.ad_email && form.touched.ad_email
                                      }
                                    >
                                      <FormLabel htmlFor="ad_email" ms="4px" fontSize="sm" fontWeight="normal">
                                        Email
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="ad_email"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="youremail@domain.com"
                                        size="lg"
                                        value={values.ad_email}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {form.errors.ad_email}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                                <Field
                                  name="ad_phoneNumber"
                                  validate={validatePhone}
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      isInvalid={
                                        form.errors.ad_phoneNumber && form.touched.ad_phoneNumber
                                      }
                                    >
                                      <FormLabel htmlFor="ad_phoneNumber" ms="4px" fontSize="sm" fontWeight="normal">
                                        Phone
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        as={InputMask}
                                        id="ad_phoneNumber"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="###-###-####"
                                        size="lg"
                                        value={values.ad_phoneNumber}
                                        onChange={handleChange}
                                        mask="999-999-9999"
                                      />
                                      <FormErrorMessage>
                                        {form.errors.ad_phoneNumber}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                              </SimpleGrid>
                              <Field
                                name="ad_address"
                                validate={validateAddress}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                    isInvalid={
                                      form.errors.ad_address && form.touched.ad_address
                                    }
                                  >
                                    <FormLabel htmlFor="ad_address" ms="4px" fontSize="sm" fontWeight="normal">
                                      Address
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_address"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="204 W Beaver Ave"
                                      size="lg"
                                      value={values.ad_address}
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {form.errors.ad_address}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                <Field
                                  name="ad_city"
                                  validate={validateCity}
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      mb="12px"
                                      isInvalid={
                                        form.errors.ad_city && form.touched.ad_city
                                      }
                                    >
                                      <FormLabel htmlFor="ad_city" ms="4px" fontSize="sm" fontWeight="normal">
                                        City
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="ad_city"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="State College"
                                        size="lg"
                                        value={values.ad_city}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {form.errors.ad_city}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field
                                  name="ad_zip"
                                  validate={validateZip}
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      mb="12px"
                                      isInvalid={
                                        form.errors.ad_zip && form.touched.ad_zip
                                      }
                                    >
                                      <FormLabel htmlFor="ad_zip" ms="4px" fontSize="sm" fontWeight="normal">
                                        Zip
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="ad_zip"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="16801"
                                        size="lg"
                                        value={values.ad_zip}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {form.errors.ad_zip}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                              </SimpleGrid>

                              <Field
                                name="ad_content"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_content" ms="4px" fontSize="sm" fontWeight="normal">
                                      Destination Information
                                    </FormLabel>
                                    <ReactQuill theme="snow" value={values.ad_content} onChange={field.onChange(field.name)} placeholder="About my exciting destination!" modules={modules} />
                                  </FormControl>
                                )}
                              </Field>

                              <FormControl
                                mb="12px"
                              >
                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                  Business Logo or Photo (size: 1000px x 1000px)
                                </FormLabel>
                                <HStack>
                                  <FileInput accept="image/*" onChange={onLogoChange}></FileInput>
                                </HStack>
                              </FormControl>

                              <FormControl
                                mb="12px"
                              >
                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                  Page Header Photo (size: 2000px x 1000px)
                                </FormLabel>
                                <HStack>
                                  <FileInput accept="image/*" onChange={onHeaderChange}></FileInput>
                                </HStack>
                              </FormControl>

                              <FormControl
                                mb="12px"
                              >
                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                  Categories
                                    </FormLabel>
                                <DragDropContext onDragEnd={onDragEndCategories}>
                                  <Droppable droppableId="droppable-cat">
                                    {(provided, snapshot) => (
                                      <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                      >
                                        {sortCategories.map((item, index) => (
                                          <Draggable key={`dragck-${item.id}`} draggableId={`dragce-${item.id}`} index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                  snapshot.isDragging,
                                                  provided.draggableProps.style
                                                )}
                                              >
                                                <Box pl={1}>
                                                  <HStack>
                                                    <FaArrowsAlt />
                                                    <Select
                                                      borderRadius="15px"
                                                      fontSize="sm"
                                                      placeholder="Select Category"
                                                      size="lg"
                                                      bg="white"
                                                      value={item.category}
                                                      onChange={(ev) => { item.category = ev.target.value; handleChange(ev); }}
                                                    >
                                                      {allCategories?.map((c, ii) => (
                                                        <option key={`ldacc-${index}-${ii}`} value={c.id}>{c.name}</option>
                                                      ))
                                                      }
                                                    </Select>
                                                  </HStack>
                                                </Box>

                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>

                                <Flex mt={2}>
                                  <Spacer />
                                  <ButtonGroup>
                                    <Button onClick={resetCatList} size="sm">Reset Categories</Button>
                                    <Button onClick={addCatItem} size="sm">Add Category</Button>
                                  </ButtonGroup>
                                </Flex>
                              </FormControl>

                              <FormControl
                                mb="12px"
                              >
                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                  Amenities
                                    </FormLabel>
                                <DragDropContext onDragEnd={onDragEndAmenities}>
                                  <Droppable droppableId="droppable-am">
                                    {(provided, snapshot) => (
                                      <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                      >
                                        {sortAmenities.map((item, index) => (
                                          <Draggable key={`dragak-${item.id}`} draggableId={`dragae-${item.id}`} index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                  snapshot.isDragging,
                                                  provided.draggableProps.style
                                                )}
                                              >
                                                <Box pl={1}>
                                                  <HStack>
                                                    <FaArrowsAlt />
                                                    <Select
                                                      borderRadius="15px"
                                                      fontSize="sm"
                                                      placeholder="Select Amenity"
                                                      size="lg"
                                                      bg="white"
                                                      value={item.amenity}
                                                      onChange={(ev) => { item.amenity = ev.target.value; handleChange(ev); }}
                                                    >
                                                      {allAmenities?.map((a, ii) => (
                                                        <option key={`ldam-${index}-${ii}`} value={a.id}>{a.name}</option>
                                                      ))
                                                      }
                                                    </Select>
                                                  </HStack>
                                                </Box>

                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>

                                <Flex mt={2}>
                                  <Spacer />
                                  <ButtonGroup>
                                    <Button onClick={resetAmenityList} size="sm">Reset Amenities</Button>
                                    <Button onClick={addAmenityItem} size="sm">Add Amenity</Button>
                                  </ButtonGroup>
                                </Flex>
                              </FormControl>


                              <FormControl
                                mb="12px"
                              >
                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                  Gallery Photos (size: 1000px x 1000px)
                                    </FormLabel>
                                <DragDropContext onDragEnd={onDragEndPhotos}>
                                  <Droppable droppableId="droppable-ph">
                                    {(provided, snapshot) => (
                                      <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                      >
                                        {sortPhotos.map((item, index) => (
                                          <Draggable key={`draggk-${item.id}`} draggableId={`dragge-${item.id}`} index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                  snapshot.isDragging,
                                                  provided.draggableProps.style
                                                )}
                                              >
                                                <Box pl={1}>
                                                  <HStack>
                                                    <FaArrowsAlt />
                                                    <FileInput accept="image/*" onChange={(ev) => {
                                                      let reader = new FileReader();
                                                      reader.readAsDataURL(ev.target.files[0]);
                                                      reader.onload = () => {
                                                        item.file = reader.result;
                                                        handleChange(ev);
                                                      };
                                                    }}></FileInput>
                                                  </HStack>
                                                </Box>

                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>

                                <Flex mt={2}>
                                  <Spacer />
                                  <ButtonGroup>
                                    <Button onClick={resetPhotoList} size="sm">Reset Gallery</Button>
                                    <Button onClick={addPhotoItem} size="sm">Add Gallery Photo</Button>
                                  </ButtonGroup>
                                </Flex>
                              </FormControl>

                              <Field
                                name="ad_website"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_website" ms="4px" fontSize="sm" fontWeight="normal">
                                      Website (example: https://happyvalley.com)
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_website"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ad_website}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ad_reservations_link"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_reservations_link" ms="4px" fontSize="sm" fontWeight="normal">
                                      Reservations Link (example: https://happyvalley.com)
                          </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_reservations_link"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ad_reservations_link}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ad_map_link"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_map_link" ms="4px" fontSize="sm" fontWeight="normal">
                                      Map Link (example: https://happyvalley.com)
                          </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_map_link"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ad_map_link}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ad_restaurant_link"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_restaurant_link" ms="4px" fontSize="sm" fontWeight="normal">
                                      Menu Link (example: https://happyvalley.com)
                          </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_restaurant_link"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ad_restaurant_link}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ad_golf_link"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_golf_link" ms="4px" fontSize="sm" fontWeight="normal">
                                      Golf Link (example: https://happyvalley.com)
                          </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_golf_link"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ad_golf_link}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ad_facebook_link"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_facebook_link" ms="4px" fontSize="sm" fontWeight="normal">
                                      Facebook Link
                          </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_facebook_link"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ad_facebook_link}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ad_twitter_link"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_twitter_link" ms="4px" fontSize="sm" fontWeight="normal">
                                      Twitter/X Link
                          </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_twitter_link"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ad_twitter_link}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ad_instagram_link"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_instagram_link" ms="4px" fontSize="sm" fontWeight="normal">
                                      Instagram/Threads Link
                          </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_instagram_link"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ad_instagram_link}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ad_youtube_link"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_youtube_link" ms="4px" fontSize="sm" fontWeight="normal">
                                      YouTube Link
                          </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_youtube_link"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ad_youtube_link}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ad_pinterest_link"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_pinterest_link" ms="4px" fontSize="sm" fontWeight="normal">
                                      Pinterest Link
                          </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_pinterest_link"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ad_pinterest_link}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ad_calendar"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_calendar" ms="4px" fontSize="sm" fontWeight="normal">
                                      Events Public Google Calendar ID <Link color="gray" href="https://happyvalley.com/public-google-calendar" target="_blank">how to find</Link>
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_calendar"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="atlasobscura.com_3un9ajpqcp8gapehkt4ahlu6io@group.calendar.google.com"
                                      size="lg"
                                      value={values.ad_calendar}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ad_ics_calendar"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ad_ics_calendar" ms="4px" fontSize="sm" fontWeight="normal">
                                      Events ICS/iCal Calendar Link
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="ad_ics_calendar"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ad_ics_calendar}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Button variant="outline" mr={3} onClick={() => { resetForm(values); onResetDestination(); }}>
                                Reset
                              </Button>
                              <Button
                                colorScheme="green"
                                type="submit"
                                isLoading={isSubmitting}
                              >
                                Save Destination
                              </Button>

                            </form>
                          );
                        }}
                      </Formik>
                    </AccordionPanel>
                    {destinations.length > 0 && (
                      <Box overflowX="auto">
                        <Table variant='striped' colorScheme='blue' size='sm'>
                          <Thead>
                            <Tr>
                              <Th>Business</Th>
                              <Th>Location</Th>
                              <Th><Tooltip label='Approved Member Destinations are included on happyvalley.com - HVAB reviews destinations regularly.'>Approved</Tooltip></Th>
                              <Th w="20%">Actions</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {destinations?.map((destination, index) => (
                              <Tr>
                                <Td>{destination.name}</Td>
                                <Td>{destination.city}</Td>
                                <Td>
                                  {(() => {
                                    if (destination.approved == 1) {
                                      return (
                                        <Icon as={FaCheck} />
                                      )
                                    } else {
                                      return (
                                        <Icon as={FaTimes} />
                                      )
                                    }
                                  })()}
                                </Td>
                                <Td>
                                  <ButtonGroup>
                                    <Button size="sm" onClick={() => editDestinationOpen(destination)}>Edit</Button>
                                    <Button size="sm" onClick={() => viewDestinationOpen(destination)}>View</Button>
                                  </ButtonGroup>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Box>
                    )}
                    <Flex display="none">
                      <Spacer />
                      <Box p={2} >
                        <Select
                          flex={1}
                          w={32}
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(e.target.value);
                          }}
                        >
                          {[10, 25, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                          <option key="all" value="all">Show All</option>
                        </Select>
                      </Box>
                    </Flex>

                  </AccordionItem>
                </Accordion>
              </TabPanel>

              <TabPanel>

                {events.length <= 0 && (
                  <Box><h2>Add Happy Valley Events your destinations host!</h2></Box>
                )}

                <Accordion allowToggle defaultIndex={-1}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex='1' textAlign='left'>
                          Add Local Event
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} maxW={{ md: "80%" }} mx="auto">
                      <Formik
                        enableReinitialize
                        initialValues={addEventFields}
                        onSubmit={(values, actions) => {
                          console.log(values);
                          createEvent(values);
                          actions.setSubmitting(false);
                        }}
                      >
                        {({ values, handleChange, handleSubmit, resetForm, isSubmitting }) => {
                          return (
                            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                              <Field
                                name="ae_title"
                                validate={validateTitle}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                    isInvalid={
                                      form.errors.ae_title && form.touched.ae_title
                                    }
                                  >
                                    <FormLabel htmlFor="ae_title" ms="4px" fontSize="sm" fontWeight="normal">
                                      Event Title
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="ae_title"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="HVAB"
                                      size="lg"
                                      value={values.ae_title}
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {form.errors.ae_title}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                <Field
                                  name="ae_start_at"
                                  validate={validateStart}
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      mb="12px"
                                      isInvalid={
                                        form.errors.ae_start_at && form.touched.ae_start_at
                                      }
                                    >
                                      <FormLabel htmlFor="ae_start_at" ms="4px" fontSize="sm" fontWeight="normal">
                                        Start Date
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="ae_start_at"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        placeholder="Select Date and Time"
                                        size="lg"
                                        type="datetime-local"
                                        value={values.ae_start_at}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {form.errors.ae_start_at}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                                <Field
                                  name="ae_end_at"
                                  validate={validateEnd}
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      mb="12px"
                                      isInvalid={
                                        form.errors.ae_end_at && form.touched.ae_end_at
                                      }
                                    >
                                      <FormLabel htmlFor="ae_end_at" ms="4px" fontSize="sm" fontWeight="normal">
                                        End Date
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="ae_end_at"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        placeholder="Select Date and Time"
                                        size="lg"
                                        type="datetime-local"
                                        value={values.ae_end_at}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {form.errors.ae_end_at}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                              </SimpleGrid>

                              <Field
                                name="ae_repeat"
                                width={"100%"}
                                component={Recurrence}
                                rules={values.ae_repeat}
                              />

                              <Field
                                name="ae_content"
                                validate={validateEvent}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                    isInvalid={
                                      form.errors.ae_content && form.touched.ae_content
                                    }
                                  >
                                    <FormLabel htmlFor="ae_content" ms="4px" fontSize="sm" fontWeight="normal">
                                      Event Information
                                    </FormLabel>
                                    <ReactQuill theme="snow" value={values.ae_content} onChange={field.onChange(field.name)} placeholder="About my exciting event!" modules={modules} />
                                    <FormErrorMessage>
                                      {form.errors.ae_content}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ae_destination"
                                validate={validateDestination}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl id='ae_destination' mb="12px" isInvalid={
                                    form.errors.ae_destination && form.touched.ae_destination
                                  }>
                                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">Destination</FormLabel>
                                    <Select  {...field}
                                      id="ae_destination"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Select Destination"
                                      size="lg"
                                      value={values.ae_destination}
                                      onChange={handleChange}>
                                      {destinations?.map((d, index) => (
                                        <option key={`ae-d-${index}`} value={d.id}>{d.name}</option>
                                      ))
                                      }
                                    </Select>
                                    <FormErrorMessage>
                                      {form.errors.ae_destination}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <FormControl
                                mb="12px"
                              >
                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                  Categories
                                    </FormLabel>
                                <DragDropContext onDragEnd={onDragEndEventsCategories}>
                                  <Droppable droppableId="droppable-cat">
                                    {(provided, snapshot) => (
                                      <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                      >
                                        {sortEventsCategories.map((item, index) => (
                                          <Draggable key={`dragcke-${item.id}`} draggableId={`dragcee-${item.id}`} index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                  snapshot.isDragging,
                                                  provided.draggableProps.style
                                                )}
                                              >
                                                <Box pl={1}>
                                                  <HStack>
                                                    <FaArrowsAlt />
                                                    <Select
                                                      borderRadius="15px"
                                                      fontSize="sm"
                                                      placeholder="Select Category"
                                                      size="lg"
                                                      bg="white"
                                                      value={item.category}
                                                      onChange={(ev) => { item.category = ev.target.value; handleChange(ev); }}
                                                    >
                                                      {allEventsCategories?.map((c, ii) => (
                                                        <option key={`ldacce-${index}-${ii}`} value={c.id}>{c.name}</option>
                                                      ))
                                                      }
                                                    </Select>
                                                  </HStack>
                                                </Box>

                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>

                                <Flex mt={2}>
                                  <Spacer />
                                  <ButtonGroup>
                                    <Button onClick={resetEventsCatList} size="sm">Reset Categories</Button>
                                    <Button onClick={addEventsCatItem} size="sm">Add Category</Button>
                                  </ButtonGroup>
                                </Flex>
                              </FormControl>

                              <FormControl
                                mb="12px"
                              >
                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                  Event Photo
                                </FormLabel>
                                <HStack>
                                  <FileInput accept="image/*" onChange={onEventImageChange}></FileInput>
                                </HStack>
                              </FormControl>

                              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                <Field
                                  name="ae_email"
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      mb="12px"
                                    >
                                      <FormLabel htmlFor="ae_email" ms="4px" fontSize="sm" fontWeight="normal">
                                        Email
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="ae_email"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="Email"
                                        size="lg"
                                        value={values.ae_email}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  )}
                                </Field>
                                <Field
                                  name="ae_phone"
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      mb="12px"
                                    >
                                      <FormLabel htmlFor="ae_phone" ms="4px" fontSize="sm" fontWeight="normal">
                                        Phone
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        as={InputMask}
                                        id="ae_phone"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="Phone"
                                        size="lg"
                                        value={values.ae_phone}
                                        onChange={handleChange}
                                        mask="999-999-9999"
                                      />
                                    </FormControl>
                                  )}
                                </Field>
                              </SimpleGrid>

                              <Field
                                name="ae_website"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ae_website" ms="4px" fontSize="sm" fontWeight="normal">
                                      Website (example: https://happyvalley.com)
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="ae_website"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ae_website}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ae_social"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ae_social" ms="4px" fontSize="sm" fontWeight="normal">
                                      Social Link
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="ae_social"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.ae_social}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>


                              <Button variant="outline" mr={3} onClick={() => { resetForm(values); onResetEvent(); }}>
                                Reset
                              </Button>
                              <Button
                                colorScheme="green"
                                type="submit"
                                isLoading={isSubmitting}
                              >
                                Save Event
                              </Button>

                            </form>
                          );
                        }}
                      </Formik>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>

                {events.length > 0 && (
                  <Box overflowX="auto">
                    <Table variant='striped' colorScheme='blue' size='sm'>
                      <Thead>
                        <Tr>
                          <Th>Event</Th>
                          <Th>Dates</Th>
                          <Th>Destination</Th>
                          <Th><Tooltip label='Events for approved destinations are included on happyvalley.com and managed by HVAB.'>Approved</Tooltip></Th>
                          <Th w="20%">Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {events?.map((event, index) => (
                          <Tr>
                            <Td>{event.title}</Td>
                            <Td><strong>{event.starts}</strong><br />{event.ends}</Td>
                            <Td>{event.destination_name}</Td>
                            <Td>{event.approved}</Td>
                            <Td>
                              <ButtonGroup>
                                <Button size="sm" onClick={() => editEventOpen(event)}>Edit</Button>
                                <Button size="sm" onClick={() => viewEventOpen(event)}>View</Button>
                              </ButtonGroup>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                    <Flex display="none">
                      <Spacer />
                      <Box p={2}>
                        <Select
                          flex={1}
                          w={32}
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(e.target.value);
                          }}
                        >
                          {[10, 25, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                          <option key="all" value="all">Show All</option>
                        </Select>
                      </Box>
                    </Flex>
                  </Box>
                )}
              </TabPanel>

              <TabPanel>
                {tips.length <= 0 && (
                  <Box><h2>Add Happy Valley Local Tips for any destination!</h2></Box>
                )}

                <Accordion allowToggle defaultIndex={-1}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex='1' textAlign='left'>
                          Add Local Tip
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} maxW={{ md: "80%" }} mx="auto">
                      <Formik
                        enableReinitialize
                        initialValues={addTipFields}
                        onSubmit={(values, actions) => {
                          createTip(values);
                          actions.setSubmitting(false);
                        }}
                      >
                        {({ values, handleChange, handleSubmit, resetForm, isSubmitting }) => {
                          return (
                            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                              <Field
                                name="tip_title"
                                validate={validateTitle}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                    isInvalid={
                                      form.errors.tip_title && form.touched.tip_title
                                    }
                                  >
                                    <FormLabel htmlFor="tip_title" ms="4px" fontSize="sm" fontWeight="normal">
                                      Tip Title
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="tip_title"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Visit HVAB in State College on weekdays!"
                                      size="lg"
                                      value={values.tip_title}
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {form.errors.tip_title}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="tip_content"
                                validate={validateTip}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                    isInvalid={
                                      form.errors.tip_content && form.touched.tip_content
                                    }
                                  >
                                    <FormLabel htmlFor="tip_content" ms="4px" fontSize="sm" fontWeight="normal">
                                      Local Tip Information
                                    </FormLabel>
                                    <ReactQuill theme="snow" value={values.tip_content} onChange={field.onChange(field.name)} placeholder="My local tip for this destination!" modules={modules} />
                                    <FormErrorMessage>
                                      {form.errors.tip_content}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="tip_destination"
                                validate={validateDestination}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    id='tip_destination'
                                    mb="12px"
                                    isInvalid={
                                      form.errors.tip_destination && form.touched.tip_destination
                                    }>
                                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">Destination</FormLabel>
                                    <Select  {...field}
                                      id="tip_destination"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Select Destination"
                                      size="lg"
                                      value={values.tip_destination}
                                      onChange={handleChange}>
                                      {allDestinations?.map((d, index) => (
                                        <option key={`tip-d-${index}`} value={d.id}>{d.name}</option>
                                      ))
                                      }
                                    </Select>
                                    <FormErrorMessage>
                                      {form.errors.tip_destination}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <FormControl
                                mb="12px"
                              >
                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                  Tip Photo
                                </FormLabel>
                                <HStack>
                                  <FileInput accept="image/*" onChange={onTipImageChange}></FileInput>
                                </HStack>
                              </FormControl>

                              <Field
                                name="tip_website"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="tip_website" ms="4px" fontSize="sm" fontWeight="normal">
                                      Website (example: https://happyvalley.com)
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="tip_website"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.tip_website}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="tip_social"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="tip_social" ms="4px" fontSize="sm" fontWeight="normal">
                                      Social Link
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="tip_social"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="url"
                                      placeholder="https://"
                                      size="lg"
                                      value={values.tip_social}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                )}
                              </Field>


                              <Button type="reset" variant="outline" mr={3} onClick={() => { resetForm(values); onResetTip(); }}>
                                Reset
                              </Button>
                              <Button
                                colorScheme="green"
                                type="submit"
                                isLoading={isSubmitting}
                              >
                                Save Tip
                              </Button>

                            </form>
                          );
                        }}
                      </Formik>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>

                {tips.length > 0 && (
                  <Box overflowX="auto">
                    <Table variant='striped' colorScheme='blue' size='sm'>
                      <Thead>
                        <Tr>
                          <Th>Tip</Th>
                          <Th>Destination</Th>
                          <Th><Tooltip label='Approved tips are included on happyvalley.com with your profile picture. Tips are regularly reviewed by HVAB.'>Approved</Tooltip></Th>
                          <Th w="20%">Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {tips?.map((tip, index) => (
                          <Tr>
                            <Td>{tip.title}</Td>
                            <Td>{tip.destination_name}</Td>
                            <Td>
                              {(() => {
                                if (tip.approved == 1) {
                                  return (
                                    <Icon as={FaCheck} />
                                  )
                                } else {
                                  return (
                                    <Icon as={FaTimes} />
                                  )
                                }
                              })()}
                            </Td>
                            <Td>
                              <ButtonGroup>
                                <Button size="sm" onClick={() => editTipOpen(tip)}>Edit</Button>
                                <Button size="sm" onClick={() => viewTipOpen(tip)}>View</Button>
                              </ButtonGroup>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                )}


                <Flex display="none">
                  <Spacer />
                  <Box p={2}>
                    <Select
                      flex={1}
                      w={32}
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(e.target.value);
                      }}
                    >
                      {[10, 25, 50, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                      <option key="all" value="all">Show All</option>
                    </Select>
                  </Box>
                </Flex>
              </TabPanel>

              <TabPanel>
                {lists.length <= 0 && (
                  <Box><h2>Add Happy Valley Destination Lists!</h2></Box>
                )}

                <Accordion allowToggle defaultIndex={-1}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex='1' textAlign='left'>
                          Add Local List
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} maxW={{ md: "80%" }} mx="auto">
                      <Formik
                        enableReinitialize
                        initialValues={addListFields}
                        onSubmit={(values, actions) => {
                          let itemsError = false;
                          sortItems.forEach((item) => {
                            if (!item.destination) {
                              itemsError = true;
                            }
                          });
                          if (itemsError) {
                            showToast('A list must include at least 3 items with destinations selected.', 'error');
                          }
                          createList(values);
                          actions.setSubmitting(false);
                        }}
                      >
                        {({ values, handleChange, handleSubmit, resetForm, isSubmitting }) => {
                          return (
                            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                              <Field
                                name="list_title"
                                validate={validateTitle}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                    isInvalid={
                                      form.errors.list_title && form.touched.list_title
                                    }
                                  >
                                    <FormLabel htmlFor="list_title" ms="4px" fontSize="sm" fontWeight="normal">
                                      List Title
                                    </FormLabel>
                                    <Input
                                      {...field}
                                      id="list_title"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Take a Happy Valley Agventures tour!"
                                      size="lg"
                                      value={values.list_title}
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {form.errors.list_title}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="list_content"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="list_content" ms="4px" fontSize="sm" fontWeight="normal">
                                      Local List Information
                                    </FormLabel>
                                    <ReactQuill theme="snow" value={values.list_content} onChange={field.onChange(field.name)} placeholder="A list of the best destinations in Happy Valley!" modules={modules} />
                                  </FormControl>
                                )}
                              </Field>

                              <FormControl
                                mb="12px"
                              >

                                <DragDropContext onDragEnd={onDragEnd}>
                                  <Droppable droppableId="droppable-list-add">
                                    {(provided, snapshot) => (
                                      <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                      >
                                        {sortItems.map((item, index) => (
                                          <Draggable key={`dka-${item.id}`} draggableId={`dragal-${item.id}`} index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                  snapshot.isDragging,
                                                  provided.draggableProps.style
                                                )}
                                              >
                                                <Box pl={1}>
                                                  <HStack>
                                                    <Box minWidth="5%">
                                                      <FaArrowsAlt />
                                                    </Box>
                                                    <Box minWidth="70%" bg="white" borderBottomRadius={15}>
                                                      <ReactQuill theme="snow" value={item.information} onChange={(content) => { item.information = content }} placeholder={item.content} modules={modules} />
                                                    </Box>
                                                    <Select
                                                      borderRadius="15px"
                                                      fontSize="sm"
                                                      placeholder="Select Destination"
                                                      size="lg"
                                                      bg="white"
                                                      value={item.destination}
                                                      onChange={(ev) => { item.destination = ev.target.value; handleChange(ev); }}
                                                    >
                                                      {allDestinations?.map((d, ii) => (
                                                        <option key={`llda-${index}-${ii}`} value={d.id}>{d.name}</option>
                                                      ))
                                                      }
                                                    </Select>
                                                  </HStack>
                                                </Box>

                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>

                                <Flex mt={2}>
                                  <Spacer />
                                  <ButtonGroup>
                                    <Button onClick={resetList} size="sm">Reset List</Button>
                                    <Button onClick={addListItem} size="sm">Add List Item</Button>
                                  </ButtonGroup>
                                </Flex>

                              </FormControl>

                              <FormControl
                                mb="12px"
                              >
                                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                  List Photo
                                </FormLabel>
                                <HStack>
                                  <FileInput accept="image/*" onChange={onListImageChange}></FileInput>
                                </HStack>
                              </FormControl>

                              <Field
                                name="list_destination"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl id='list_destination' mb="12px">
                                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">Main List Destination</FormLabel>
                                    <Select  {...field}
                                      id="list_destination"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Select Destination"
                                      size="lg"
                                      value={values.list_destination}
                                      onChange={handleChange}>
                                      {allDestinations?.map((d, index) => (
                                        <option key={`ld-${index}`} value={d.id}>{d.name}</option>
                                      ))
                                      }
                                    </Select>
                                  </FormControl>
                                )}
                              </Field>

                              <Button variant="outline" mr={3} onClick={() => { resetForm(values); onResetList(); }}>
                                Reset
                              </Button>
                              <Button
                                colorScheme="green"
                                type="submit"
                                isLoading={isSubmitting}
                              >
                                Save List
                              </Button>

                            </form>
                          );
                        }}
                      </Formik>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>

                {lists.length > 0 && (
                  <Box overflowX="auto">
                    <Table variant='striped' colorScheme='blue' size='sm'>
                      <Thead>
                        <Tr>
                          <Th>List</Th>
                          <Th>Items</Th>
                          <Th><Tooltip label='Approved lists are included on happyvalley.com with your profile picture. Lists are regularly reviewed by HVAB.'>Approved</Tooltip></Th>
                          <Th w="20%">Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {lists?.map((list, index) => (
                          <Tr>
                            <Td>{list.title}</Td>
                            <Td>{list.items.length}</Td>
                            <Td>{(() => {
                              if (list.approved == 1) {
                                return (
                                  <Icon as={FaCheck} />
                                )
                              } else {
                                return (
                                  <Icon as={FaTimes} />
                                )
                              }
                            })()}</Td>
                            <Td>
                              <ButtonGroup>
                                <Button size="sm" onClick={() => editListOpen(list)}>Edit</Button>
                                <Button size="sm" onClick={() => viewListOpen(list)}>View</Button>
                              </ButtonGroup>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                )}


                <Flex display="none">
                  <Spacer />
                  <Box p={2}>
                    <Select
                      flex={1}
                      w={32}
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(e.target.value);
                      }}
                    >
                      {[10, 25, 50, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                      <option key="all" value="all">Show All</option>
                    </Select>
                  </Box>
                </Flex>


              </TabPanel>

              <TabPanel>

                {reservations.length <= 0 && (
                  <Box><h2>Submit a reservation request to any of our Happy Valley Destinations!</h2></Box>
                )}

                <Accordion allowToggle defaultIndex={-1}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex='1' textAlign='left'>
                          Make Reservation
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} maxW={{ md: "80%" }} mx="auto">
                      <Formik
                        enableReinitialize
                        initialValues={addReservationFields}
                        onSubmit={(values, actions) => {
                          createReservation(values);
                          actions.setSubmitting(false);
                        }}
                      >
                        {({ values, handleChange, handleSubmit, resetForm, isSubmitting }) => {
                          return (
                            <form onSubmit={handleSubmit} style={{ width: "100%" }}>

                              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                <Field
                                  name="ar_start_date"
                                  validate={validateStart}
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      mb="12px"
                                      isInvalid={
                                        form.errors.ar_start_date && form.touched.ar_start_date
                                      }
                                    >
                                      <FormLabel htmlFor="ar_start_date" ms="4px" fontSize="sm" fontWeight="normal">
                                        Start Date
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="ar_start_date"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        placeholder="Select Date and Time"
                                        size="lg"
                                        type="datetime-local"
                                        value={values.ar_start_date}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {form.errors.ar_start_date}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                                <Field
                                  name="ar_end_date"
                                  validate={validateEnd}
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      mb="12px"
                                      isInvalid={
                                        form.errors.ar_end_date && form.touched.ar_end_date
                                      }
                                    >
                                      <FormLabel htmlFor="ar_end_date" ms="4px" fontSize="sm" fontWeight="normal">
                                        End Date
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="ar_end_date"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        placeholder="Select Date and Time"
                                        size="lg"
                                        type="datetime-local"
                                        value={values.ar_end_date}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {form.errors.ar_end_date}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                              </SimpleGrid>

                              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                                <Field
                                  name="ar_email"
                                  validate={validateEmail}
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      isInvalid={
                                        form.errors.ar_email && form.touched.ar_email
                                      }
                                      mb="12px"
                                    >
                                      <FormLabel htmlFor="ar_email" ms="4px" fontSize="sm" fontWeight="normal">
                                        Email
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        id="ar_email"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="Email"
                                        size="lg"
                                        value={values.ar_email}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {form.errors.ar_email}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                                <Field
                                  name="ar_phoneNumber"
                                  validate={validatePhone}
                                  width={"100%"}
                                >
                                  {({ field, form }) => (
                                    <FormControl
                                      isInvalid={
                                        form.errors.ar_phoneNumber && form.touched.ar_phoneNumber
                                      }
                                      mb="12px"
                                    >
                                      <FormLabel htmlFor="ar_phoneNumber" ms="4px" fontSize="sm" fontWeight="normal">
                                        Phone
                                      </FormLabel>
                                      <Input
                                        {...field}
                                        as={InputMask}
                                        id="ar_phoneNumber"
                                        borderRadius="15px"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="Phone"
                                        size="lg"
                                        value={values.ar_phoneNumber}
                                        onChange={handleChange}
                                        mask="999-999-9999"
                                      />
                                      <FormErrorMessage>
                                        {form.errors.ar_phoneNumber}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                              </SimpleGrid>
                              <Field
                                name="ar_destination"
                                validate={validateDestination}
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    isInvalid={
                                      form.errors.ar_destination && form.touched.ar_destination
                                    }
                                    id='ar_destination'
                                    mb="12px">
                                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">Destination</FormLabel>
                                    <Select  {...field}
                                      id="ar_destination"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      type="text"
                                      placeholder="Select Destination"
                                      size="lg"
                                      value={values.ar_destination}
                                      onChange={handleChange}>
                                      {allDestinations?.map((destination, index) => (
                                        <option key={`ar-d-${index}`} value={destination.id}>{destination.name}</option>
                                      ))
                                      }
                                    </Select>
                                    <FormErrorMessage>
                                      {form.errors.ar_destination}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <Field
                                name="ar_reservation"
                                width={"100%"}
                              >
                                {({ field, form }) => (
                                  <FormControl
                                    mb="12px"
                                  >
                                    <FormLabel htmlFor="ar_reservation" ms="4px" fontSize="sm" fontWeight="normal">
                                      Reservation
                                    </FormLabel>
                                    <Textarea
                                      {...field}
                                      id="ar_reservation"
                                      borderRadius="15px"
                                      fontSize="sm"
                                      placeholder="Any reservation questions or information for the destination"
                                      size="lg"
                                      value={values.ar_reservation}
                                      onChange={handleChange}
                                    ></Textarea>
                                  </FormControl>
                                )}
                              </Field>

                              <Button variant="outline" mr={3} onClick={() => { resetForm(values); onResetReservation(); }}>
                                Reset
                              </Button>
                              <Button
                                colorScheme="green"
                                type="submit"
                                isLoading={isSubmitting}
                              >
                                Send Message
                              </Button>

                            </form>
                          );
                        }}
                      </Formik>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>

                {reservations.length > 0 && (
                  <Box overflowX="auto">
                    <Table variant='striped' colorScheme='blue' size='sm'>
                      <Thead>
                        <Tr>
                          <Th>Reservation Dates</Th>
                          <Th>Destination</Th>
                          <Th><Tooltip label='Reservations are forwarded to the destination and HVAB. Please contact the destination directly for any questions.'>Sent</Tooltip></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {reservations?.map((r, index) => (
                          <Tr>
                            <Td>{r.start} - {r.end}</Td>
                            <Td>{r.destination_name}</Td>
                            <Td>{(() => {
                              if (r.approved == 1) {
                                return (
                                  <Icon as={FaCheck} />
                                )
                              } else {
                                return (
                                  <Icon as={FaTimes} />
                                )
                              }
                            })()}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                )}


                <Flex display="none">
                  <Spacer />
                  <Box p={2}>
                    <Select
                      flex={1}
                      w={32}
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(e.target.value);
                      }}
                    >
                      {[10, 25, 50, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                      <option key="all" value="all">Show All</option>
                    </Select>
                  </Box>
                </Flex>
              </TabPanel>

              <TabPanel>
                {favorites.length <= 0 && (
                  <Box><h2><Icon as={FaHeart} /> Add Favorite destinations, events, posts, tips or lists to your profile!</h2></Box>
                )}
                {favorites.length > 0 && (
                  <Box overflowX="auto">
                    <Table variant='striped' colorScheme='blue' size='sm'>
                      <Thead>
                        <Tr>
                          <Th>Type</Th>
                          <Th>Title</Th>
                          <Th>Added On</Th>
                          <Th w="20%">Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {favorites?.map((f, index) => (
                          <Tr>
                            <Td>{f.type}</Td>
                            <Td>{f.title}</Td>
                            <Td>{f.fav_at}</Td>
                            <Td>
                              <ButtonGroup>
                                <Button size="sm" onClick={() => { removeFavorite(f); }}>Remove</Button>
                                <Button size="sm" onClick={() => { viewFavorite(f); }}>View Page</Button>
                              </ButtonGroup>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                )}

                <Flex display="none">
                  <Spacer />
                  <Box p={2} >
                    <Select
                      flex={1}
                      w={32}
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(e.target.value);
                      }}
                    >
                      {[10, 25, 50, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                      <option key="all" value="all">Show All</option>
                    </Select>
                  </Box>
                </Flex>
              </TabPanel>



            </TabPanels>
          </Tabs>
        </Box>
      )}

      <Drawer
        isOpen={isEditOpen}
        placement="left"
        size="md"
        initialFocusRef={firstEditField}
        closeOnOverlayClick={false}
        onClose={onEditClose}
      >
        <DrawerOverlay />
        <Formik
          enableReinitialize
          initialValues={editProfileFields}
          onSubmit={(values, actions) => {
            let formValues = {};
            formValues.firstName = values.ep_firstName;
            formValues.lastName = values.ep_lastName;
            formValues.email = values.ep_email;
            formValues.phoneNumber = values.ep_phoneNumber;
            formValues.city = values.ep_city;
            formValues.zip = values.ep_zip;
            formValues.state = values.ep_state;
            formValues.country = values.ep_country;
            if (preview) {
              formValues.new_image = preview;
            }
            if (logoFileData) {
              values.company_image = logoFileData;
            }
            authContext.updateUser(formValues, preview, props.history, true, onEditSave.bind(this));
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    Edit My Profile
                  </DrawerHeader>

                  <DrawerBody>

                    <FormControl
                      mb="12px"
                    >
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Profile Photo
                      </FormLabel>
                      <HStack>
                        <Avatar
                          label="Upload an Image"
                          labelStyle={{ fontSize: "0.8em", cursor: "pointer" }}
                          width={240}
                          height={200}
                          imageHeight={200}
                          cropRadius={80}
                          onCrop={onCrop}
                          onClose={onCloseCrop}
                          onBeforeFileLoad={onBeforeFileLoad}
                        />
                        <AvatarImage
                          size="lg"
                          width={100}
                          height={100}
                          layout="fixed"
                          src={preview}
                          borderWidth="3px"
                          borderColor={avatarColor}
                        />
                      </HStack>
                    </FormControl>

                    <Field
                      name="ep_firstName"
                      validate={validateFirstName}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.ep_firstName && form.touched.ep_firstName
                          }
                          mb="12px"
                        >
                          <FormLabel htmlFor="ep_firstName" ms="4px" fontSize="sm" fontWeight="normal">
                            First Name
                          </FormLabel>
                          <Input
                            {...field}
                            ref={firstEditField}
                            id="ep_firstName"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="First Name"
                            size="lg"
                            value={values.ep_firstName}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {form.errors.ep_firstName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="ep_lastName"
                      validate={validateLastName}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.ep_lastName && form.touched.ep_lastName
                          }
                          mb="12px"
                        >
                          <FormLabel htmlFor="ep_lastName" ms="4px" fontSize="sm" fontWeight="normal">
                            Last Name
                          </FormLabel>
                          <Input
                            {...field}
                            id="ep_lastName"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Last Name"
                            size="lg"
                            value={values.ep_lastName}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {form.errors.ep_lastName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ep_email"
                      validate={validateEmail}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.ep_email && form.touched.ep_email
                          }
                          mb="12px"
                        >
                          <FormLabel htmlFor="ep_email" ms="4px" fontSize="sm" fontWeight="normal">
                            Email
                          </FormLabel>
                          <Input
                            {...field}
                            id="ep_email"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Email"
                            size="lg"
                            value={values.ep_email}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {form.errors.ep_email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="ep_phoneNumber"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ep_phoneNumber" ms="4px" fontSize="sm" fontWeight="normal">
                            Phone
                          </FormLabel>
                          <Input
                            {...field}
                            as={InputMask}
                            id="ep_phoneNumber"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Phone"
                            size="lg"
                            value={values.ep_phoneNumber}
                            onChange={handleChange}
                            mask="999-999-9999"
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ep_city"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ep_city" ms="4px" fontSize="sm" fontWeight="normal">
                            City
                          </FormLabel>
                          <Input
                            {...field}
                            id="ep_city"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="City"
                            size="lg"
                            value={values.ep_city}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ep_zip"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ep_zip" ms="4px" fontSize="sm" fontWeight="normal">
                            Zip
                          </FormLabel>
                          <Input
                            {...field}
                            id="ep_zip"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Zip"
                            size="lg"
                            value={values.ep_zip}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ep_state"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl id='ep_state' mb="12px">
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">State</FormLabel>
                          <Select  {...field}
                            id="ep_state"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Select State"
                            size="lg"
                            value={values.ep_state}
                            onChange={handleChange}>
                            {states?.map((state, index) => (
                              <option key={`state-${index}`} value={state.abbr}>{state.name}</option>
                            ))
                            }
                          </Select>
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ep_country"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl id='ep_country' mb="12px">
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">Country</FormLabel>
                          <Select  {...field}
                            id="ep_country"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Select Country"
                            size="lg"
                            value={values.ep_country}
                            onChange={handleChange}>
                            {countries?.map((country, index) => (
                              <option key={`country-${index}`} value={country.code}>{country.name}</option>
                            ))
                            }
                          </Select>
                        </FormControl>
                      )}
                    </Field>

                    <Box height="30vh">

                    </Box>

                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <Button variant="outline" mr={3} onClick={onEditClose}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="green"
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      Save My Profile
                    </Button>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>

      <Drawer
        isOpen={isEditDestinationOpen}
        placement="left"
        size="lg"
        initialFocusRef={firstEditDestinationField}
        closeOnOverlayClick={false}
        onClose={() => { onEditDestinationClose(); resetCatList(); resetAmenityList(); resetPhotoList(); }}
      >
        <DrawerOverlay />
        <Formik
          enableReinitialize
          initialValues={editDestinationFields}
          onSubmit={(values, actions) => {
            updateDestination(values);
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    Edit Destination
                  </DrawerHeader>

                  <DrawerBody>

                    <Field
                      name="ed_company"
                      validate={validateBusinessName}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                          isInvalid={
                            form.errors.ed_company && form.touched.ed_company
                          }
                        >
                          <FormLabel htmlFor="ed_company" ms="4px" fontSize="sm" fontWeight="normal">
                            Business Name
                          </FormLabel>
                          <Input
                            {...field}
                            ref={firstEditDestinationField}
                            id="ed_company"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="HVAB"
                            size="lg"
                            value={values.ed_company}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {form.errors.ed_company}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                      <Field
                        name="ed_email"
                        validate={validateEmail}
                        width={"100%"}
                      >
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.ed_email && form.touched.ed_email
                            }
                          >
                            <FormLabel htmlFor="ed_email" ms="4px" fontSize="sm" fontWeight="normal">
                              Email
                            </FormLabel>
                            <Input
                              {...field}
                              id="ed_email"
                              borderRadius="15px"
                              fontSize="sm"
                              type="text"
                              placeholder="youremail@domain.com"
                              size="lg"
                              value={values.ed_email}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {form.errors.ed_email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="ed_phoneNumber"
                        validate={validatePhone}
                        width={"100%"}
                      >
                        {({ field, form }) => (
                          <FormControl
                            mb="12px"
                            isInvalid={
                              form.errors.ed_phoneNumber && form.touched.ed_phoneNumber
                            }
                          >
                            <FormLabel htmlFor="ed_phoneNumber" ms="4px" fontSize="sm" fontWeight="normal">
                              Phone
                            </FormLabel>
                            <Input
                              {...field}
                              as={InputMask}
                              id="ed_phoneNumber"
                              borderRadius="15px"
                              fontSize="sm"
                              type="text"
                              placeholder="###-###-#####"
                              size="lg"
                              value={values.ed_phoneNumber}
                              onChange={handleChange}
                              mask="999-999-9999"
                            />
                            <FormErrorMessage>
                              {form.errors.ed_phoneNumber}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </SimpleGrid>
                    <Field
                      name="ed_address"
                      validate={validateAddress}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                          isInvalid={
                            form.errors.ed_address && form.touched.ed_address
                          }
                        >
                          <FormLabel htmlFor="ed_address" ms="4px" fontSize="sm" fontWeight="normal">
                            Address
                          </FormLabel>
                          <Input
                            {...field}
                            id="ed_address"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="204 W Beaver Ave"
                            size="lg"
                            value={values.ed_address}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {form.errors.ed_address}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                      <Field
                        name="ed_city"
                        validate={validateCity}
                        width={"100%"}
                      >
                        {({ field, form }) => (
                          <FormControl
                            mb="12px"
                            isInvalid={
                              form.errors.ed_city && form.touched.ed_city
                            }
                          >
                            <FormLabel htmlFor="ed_city" ms="4px" fontSize="sm" fontWeight="normal">
                              City
                            </FormLabel>
                            <Input
                              {...field}
                              id="ed_city"
                              borderRadius="15px"
                              fontSize="sm"
                              type="text"
                              placeholder="State College"
                              size="lg"
                              value={values.ed_city}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {form.errors.ed_city}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field
                        name="ed_zip"
                        validate={validateZip}
                        width={"100%"}
                      >
                        {({ field, form }) => (
                          <FormControl
                            mb="12px"
                            isInvalid={
                              form.errors.ed_zip && form.touched.ed_zip
                            }
                          >
                            <FormLabel htmlFor="ed_zip" ms="4px" fontSize="sm" fontWeight="normal">
                              Zip
                            </FormLabel>
                            <Input
                              {...field}
                              id="ed_zip"
                              borderRadius="15px"
                              fontSize="sm"
                              type="text"
                              placeholder="16801"
                              size="lg"
                              value={values.ed_zip}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {form.errors.ed_zip}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </SimpleGrid>

                    <Field
                      name="ed_content"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_content" ms="4px" fontSize="sm" fontWeight="normal">
                            Destination Information
                          </FormLabel>
                          <ReactQuill theme="snow" value={values.ed_content} onChange={field.onChange(field.name)} placeholder="About my exciting destination!" modules={modules} />
                        </FormControl>
                      )}
                    </Field>

                    <FormControl
                      mb="12px"
                    >
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Business Logo or Photo (size: 1000px x 1000px)
                      </FormLabel>
                      <HStack>
                        <FileInput accept="image/*" onChange={onLogoChange}></FileInput>
                        <Image src={values.main_img} w="50px" />
                      </HStack>
                    </FormControl>

                    <FormControl
                      mb="12px"
                    >
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Page Header Photo (size: 2000px x 1000px)
                      </FormLabel>
                      <HStack>
                        <FileInput accept="image/*" onChange={onHeaderChange}></FileInput>
                        <Image src={values.header_img} w="50px" />
                      </HStack>
                    </FormControl>

                    <FormControl
                      mb="12px"
                    >
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Categories
                                    </FormLabel>
                      <DragDropContext onDragEnd={onDragEndCategories}>
                        <Droppable droppableId="droppable-cat-e">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {sortCategories.map((item, index) => (
                                <Draggable key={`dragck-${item.id}`} draggableId={`dragce-${item.id}`} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <Box pl={1}>
                                        <HStack>
                                          <FaArrowsAlt />
                                          <Select
                                            borderRadius="15px"
                                            fontSize="sm"
                                            placeholder="Select Category"
                                            size="lg"
                                            bg="white"
                                            defaultValue={item.id}
                                            value={item.category}
                                            onChange={(ev) => { item.category = ev.target.value; handleChange(ev); }}
                                          >
                                            {allCategories?.map((c, ii) => (
                                              <option key={`ldc-${index}-${ii}`} value={c.id}>{c.name}</option>
                                            ))
                                            }
                                          </Select>
                                        </HStack>
                                      </Box>

                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                      <Flex mt={2}>
                        <Spacer />
                        <ButtonGroup flexDirection={{ base: "column", md: "row" }}>
                          <Button onClick={resetCatList} size="sm">Reset Categories</Button>
                          <Button onClick={addCatItem} size="sm" mt={{ base: 2, md: 0 }}>Add Category</Button>
                        </ButtonGroup>
                      </Flex>
                    </FormControl>

                    <FormControl
                      mb="12px"
                    >
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Amenities
                                    </FormLabel>
                      <DragDropContext onDragEnd={onDragEndAmenities}>
                        <Droppable droppableId="droppable-am-e">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {sortAmenities.map((item, index) => (
                                <Draggable key={`dragak-${item.id}`} draggableId={`dragae-${item.id}`} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <Box pl={1}>
                                        <HStack>
                                          <FaArrowsAlt />
                                          <Select
                                            borderRadius="15px"
                                            fontSize="sm"
                                            placeholder="Select Amenity"
                                            size="lg"
                                            bg="white"
                                            defaultValue={item.id}
                                            value={item.amenity}
                                            onChange={(ev) => { item.amenity = ev.target.value; handleChange(ev); }}
                                          >
                                            {allAmenities?.map((a, ii) => (
                                              <option key={`ldam-${index}-${ii}`} value={a.id}>{a.name}</option>
                                            ))
                                            }
                                          </Select>
                                        </HStack>
                                      </Box>

                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                      <Flex mt={2}>
                        <Spacer />
                        <ButtonGroup flexDirection={{ base: "column", md: "row" }}>
                          <Button onClick={resetAmenityList} size="sm">Reset Amenities</Button>
                          <Button onClick={addAmenityItem} size="sm" mt={{ base: 2, md: 0 }}>Add Amenity</Button>
                        </ButtonGroup>
                      </Flex>
                    </FormControl>


                    <FormControl
                      mb="12px"
                    >
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Gallery Photos (size: 1000px x 1000px)
                                    </FormLabel>
                      <DragDropContext onDragEnd={onDragEndPhotos}>
                        <Droppable droppableId="droppable-ph-e">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {sortPhotos.map((item, index) => (
                                <Draggable key={`draggk-${item.id}`} draggableId={`dragge-${item.id}`} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <Box pl={1}>
                                        <HStack>
                                          <FaArrowsAlt />
                                          <FileInput accept="image/*" onChange={(ev) => {
                                            let reader = new FileReader();
                                            reader.readAsDataURL(ev.target.files[0]);
                                            reader.onload = () => {
                                              item.file = reader.result;
                                              handleChange(ev);
                                            };
                                          }}></FileInput>
                                          <Image src={item.src} w="50px" />
                                        </HStack>
                                      </Box>

                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                      <Flex mt={2}>
                        <Spacer />
                        <ButtonGroup flexDirection={{ base: "column", md: "row" }}>
                          <Button onClick={resetPhotoList} size="sm">Reset Gallery</Button>
                          <Button onClick={addPhotoItem} size="sm" mt={{ base: 2, md: 0 }}>Add Gallery Photo</Button>
                        </ButtonGroup>
                      </Flex>
                    </FormControl>

                    <Field
                      name="ed_website"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_website" ms="4px" fontSize="sm" fontWeight="normal">
                            Website (example: https://happyvalley.com)
                          </FormLabel>
                          <Input
                            {...field}
                            id="ed_website"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ed_website}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ed_reservations_link"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_reservations_link" ms="4px" fontSize="sm" fontWeight="normal">
                            Reservations Link (example: https://happyvalley.com)
                          </FormLabel>
                          <Input
                            {...field}
                            id="ed_reservations_link"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ed_reservations_link}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ed_map_link"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_map_link" ms="4px" fontSize="sm" fontWeight="normal">
                            Map Link (example: https://happyvalley.com)
                          </FormLabel>
                          <Input
                            {...field}
                            id="ed_map_link"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ed_map_link}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ed_restaurant_link"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_restaurant_link" ms="4px" fontSize="sm" fontWeight="normal">
                            Menu Link (example: https://happyvalley.com)
                          </FormLabel>
                          <Input
                            {...field}
                            id="ed_restaurant_link"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ed_restaurant_link}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ed_golf_link"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_golf_link" ms="4px" fontSize="sm" fontWeight="normal">
                            Golf Link (example: https://happyvalley.com)
                          </FormLabel>
                          <Input
                            {...field}
                            id="ed_golf_link"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ed_golf_link}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ed_facebook_link"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_facebook_link" ms="4px" fontSize="sm" fontWeight="normal">
                            Facebook Link
                          </FormLabel>
                          <Input
                            {...field}
                            id="ed_facebook_link"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ed_facebook_link}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ed_twitter_link"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_twitter_link" ms="4px" fontSize="sm" fontWeight="normal">
                            Twitter/X Link
                          </FormLabel>
                          <Input
                            {...field}
                            id="ed_twitter_link"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ed_twitter_link}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ed_instagram_link"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_instagram_link" ms="4px" fontSize="sm" fontWeight="normal">
                            Instagram/Threads Link
                          </FormLabel>
                          <Input
                            {...field}
                            id="ed_instagram_link"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ed_instagram_link}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ed_youtube_link"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_youtube_link" ms="4px" fontSize="sm" fontWeight="normal">
                            YouTube Link
                          </FormLabel>
                          <Input
                            {...field}
                            id="ed_youtube_link"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ed_youtube_link}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ed_pinterest_link"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_pinterest_link" ms="4px" fontSize="sm" fontWeight="normal">
                            Pinterest Link
                          </FormLabel>
                          <Input
                            {...field}
                            id="ed_pinterest_link"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ed_pinterest_link}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ed_calendar"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_calendar" ms="4px" fontSize="sm" fontWeight="normal">
                            Events Public Google Calendar ID <Link color="gray" href="https://happyvalley.com/public-google-calendar" target="_blank">how to find</Link>
                          </FormLabel>
                          <Input
                            {...field}
                            id="ed_calendar"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="atlasobscura.com_3un9ajpqcp8gapehkt4ahlu6io@group.calendar.google.com"
                            size="lg"
                            value={values.ed_calendar}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ed_ics_calendar"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ed_ics_calendar" ms="4px" fontSize="sm" fontWeight="normal">
                            Events ICS/iCal Calendar Link
                                    </FormLabel>
                          <Input
                            {...field}
                            id="ed_ics_calendar"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ed_ics_calendar}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <ButtonGroup>
                      <Button variant="outline" onClick={() => { onEditDestinationClose(); resetCatList(); resetAmenityList(); resetPhotoList(); }}>
                        Cancel
                      </Button>
                      <Button
                        variant="outline"
                        onClick={removeDestination}
                      >
                        Remove
                      </Button>
                      <Button
                        colorScheme="green"
                        type="submit"
                        isLoading={isSubmitting}
                      >
                        Save
                      </Button>
                    </ButtonGroup>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>

      <Drawer
        isOpen={isViewDestinationOpen}
        placement="left"
        size="lg"
        closeOnOverlayClick={true}
        onClose={onViewDestinationClose}
      >
        <DrawerOverlay />
        <Formik
          enableReinitialize
          initialValues={{}}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    <Icon as={FaHeart} mr={2} color={editDestinationFields.color} cursor="pointer" onClick={() => { createFavorite({ destination: editDestinationFields.destination.id }, 'destination'); }} /> {editDestinationFields.destination.name}
                  </DrawerHeader>

                  <DrawerBody>
                    <Stack spacing="24px">
                      <Center>
                        {editDestinationFields.destination.main_img && (
                          <Box
                            backgroundImage={`${editDestinationFields.destination.main_img}`}
                            backgroundSize="cover"
                            backgroundPosition="center center"
                            height="300px"
                            width={'100%'}
                            borderBottomStartRadius={'md'}
                          />
                        )}
                      </Center>

                      {editDestinationFields.destination.content ? (
                        <Box className="content-block">
                          {parser(editDestinationFields.destination.content)}
                        </Box>
                      ) : null}

                      <Box>
                        <MapContainer center={[editDestinationFields.destination.location_latitude, editDestinationFields.destination.location_longitude]} zoom={16} scrollWheelZoom={false} style={{ minHeight: '250px' }}>
                          <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker
                            position={[editDestinationFields.destination.location_latitude, editDestinationFields.destination.location_longitude]}
                            icon={new MapIcon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}>
                            <Popup closeButton={false}>
                              {editDestinationFields.destination.address}
                            </Popup>
                          </Marker>
                        </MapContainer>
                      </Box>

                      <Stack>
                        <Center><Text>{editDestinationFields.destination.address}</Text></Center>
                        <Center><Link href={`tel:+1${editDestinationFields.destination.phone}`}>{editDestinationFields.destination.phone}</Link></Center>
                      </Stack>
                    </Stack>
                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <ButtonGroup>
                      <Button variant="outline" onClick={onViewDestinationClose}>
                        Cancel
                      </Button>
                      <Button
                        as={Link}
                        colorScheme="green"
                        type="submit"
                        isLoading={isSubmitting}
                        target="_blank"
                        href={`/d/${editDestinationFields.destination.slug}`}
                      >
                        View Page
                      </Button>
                    </ButtonGroup>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>

      <Drawer
        isOpen={isViewEventOpen}
        placement="left"
        size="lg"
        closeOnOverlayClick={true}
        onClose={onViewEventClose}
      >
        <DrawerOverlay />
        <Formik
          enableReinitialize
          initialValues={{}}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    <Icon as={FaHeart} mr={2} color={editEventFields.color} cursor="pointer" onClick={() => { createFavorite({ event: editEventFields.event.id }, 'event'); }} /> {editEventFields.event.title}
                  </DrawerHeader>

                  <DrawerBody>
                    <Stack spacing="24px">
                      <Center>

                        {editEventFields.event.main_img && (
                          <Box
                            backgroundImage={`${editEventFields.event.main_img}`}
                            backgroundSize="cover"
                            backgroundPosition="center center"
                            height="300px"
                            width={'100%'}
                            borderBottomStartRadius={'md'}
                          />
                        )}

                      </Center>

                      {editEventFields.event.entry.content != null ? (
                        <Text>{parser(editEventFields.event.entry.content)}</Text>
                      ) : null}

                      <Stack>
                        <Center><Text>Starts At {editEventFields.event.starts}</Text></Center>
                        <Center><Text>Ends At {editEventFields.event.ends}</Text></Center>
                      </Stack>

                      <Stack>
                        <Center><Text>{editEventFields.event.email}</Text></Center>
                        <Center><Link href={`tel:+1${editEventFields.event.phone}`}>{editEventFields.event.phone}</Link></Center>
                      </Stack>
                    </Stack>
                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <ButtonGroup>
                      <Button variant="outline" onClick={onViewEventClose}>
                        Cancel
                      </Button>
                      <Button
                        as={Link}
                        colorScheme="green"
                        type="submit"
                        isLoading={isSubmitting}
                        target="_blank"
                        href={`/events/${editEventFields.event.slug}`}
                      >
                        View Page
                      </Button>
                    </ButtonGroup>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>

      <Drawer
        isOpen={isViewTipOpen}
        placement="left"
        size="lg"
        closeOnOverlayClick={true}
        onClose={onViewTipClose}
      >
        <DrawerOverlay />
        <Formik
          enableReinitialize
          initialValues={{}}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    <Icon as={FaHeart} mr={2} color={editTipFields.color} cursor="pointer" onClick={() => { createFavorite({ tip: editTipFields.tip.id }, 'tip'); }} /> {editTipFields.tip.title}
                  </DrawerHeader>

                  <DrawerBody>
                    <Stack spacing="24px">
                      <Center>
                        {editTipFields.tip.main_img && (
                          <Box
                            backgroundImage={`${editTipFields.tip.main_img}`}
                            backgroundSize="cover"
                            backgroundPosition="center center"
                            height="300px"
                            width={'100%'}
                            borderBottomStartRadius={'md'}
                          />
                        )}
                      </Center>

                      {editTipFields.tip.tip ? (
                        <Stack>
                          <Center><Text>{editTipFields.tip.destination_name}</Text></Center>
                          <Center><Text>{parser(editTipFields.tip.tip)}</Text></Center>
                        </Stack>
                      ) : null}

                    </Stack>
                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <ButtonGroup>
                      <Button variant="outline" onClick={onViewTipClose}>
                        Cancel
                      </Button>
                      <Button
                        as={Link}
                        colorScheme="green"
                        type="submit"
                        isLoading={isSubmitting}
                        target="_blank"
                        href={`/tips/${editTipFields.tip.slug}`}
                      >
                        View Page
                      </Button>
                    </ButtonGroup>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>

      <Drawer
        isOpen={isViewListOpen}
        placement="left"
        size="lg"
        closeOnOverlayClick={true}
        onClose={onViewListClose}
      >
        <DrawerOverlay />
        <Formik
          enableReinitialize
          initialValues={{}}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    <Icon as={FaHeart} mr={2} color={editListFields.color} cursor="pointer" onClick={() => { createFavorite({ list: editListFields.list.id }, 'list'); }} /> {editListFields.list.title}
                  </DrawerHeader>

                  <DrawerBody>
                    <Stack spacing="24px">
                      <Center>
                        {editListFields.list.main_img && (
                          <Box
                            backgroundImage={`${editListFields.list.main_img}`}
                            backgroundSize="cover"
                            backgroundPosition="center center"
                            height="300px"
                            width={'100%'}
                            borderBottomStartRadius={'md'}
                          />
                        )}
                      </Center>

                      {editListFields.list.content ? (
                        <Stack>
                          <Center><Text>{parser(editListFields.list.content)}</Text></Center>
                        </Stack>
                      ) : null}

                      {editListFields.list.items?.map((item, index) => (
                        <Box>
                          <h3>#{index + 1} - {item.destination_name}</h3>
                          {item.content ? (
                            <Center>{parser(item.content)}</Center>
                          ) : null}
                        </Box>
                      ))}

                    </Stack>
                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <ButtonGroup>
                      <Button variant="outline" onClick={onViewListClose}>
                        Cancel
                      </Button>
                      <Button
                        as={Link}
                        colorScheme="green"
                        type="submit"
                        isLoading={isSubmitting}
                        target="_blank"
                        href={`/lists/${editListFields.list.slug}`}
                      >
                        View Page
                      </Button>
                    </ButtonGroup>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>

      <Drawer
        isOpen={isEditTipOpen}
        placement="left"
        size="lg"
        initialFocusRef={firstEditDestinationField}
        closeOnOverlayClick={false}
        onClose={onEditTipClose}
      >
        <DrawerOverlay />
        <Formik
          enableReinitialize
          initialValues={editTipFields}
          onSubmit={(values, actions) => {
            updateTip(values);
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    Edit Tip
                  </DrawerHeader>

                  <DrawerBody>

                    <Field
                      name="et_title"
                      validate={validateTitle}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                          isInvalid={
                            form.errors.et_title && form.touched.et_title
                          }
                        >
                          <FormLabel htmlFor="et_title" ms="4px" fontSize="sm" fontWeight="normal">
                            Tip Title
                          </FormLabel>
                          <Input
                            {...field}
                            id="et_title"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Visit HVAB in State College on weekdays!"
                            size="lg"
                            value={values.et_title}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {form.errors.et_title}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="et_content"
                      validate={validateTip}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                          isInvalid={
                            form.errors.et_content && form.touched.et_content
                          }
                        >
                          <FormLabel htmlFor="et_content" ms="4px" fontSize="sm" fontWeight="normal">
                            Local Tip Information
                          </FormLabel>
                          <ReactQuill theme="snow" value={values.et_content} onChange={field.onChange(field.name)} placeholder="My local tip for this destination!" modules={modules} />
                          <FormErrorMessage>
                            {form.errors.et_content}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="et_destination"
                      validate={validateDestination}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          id='et_destination'
                          isInvalid={
                            form.errors.et_destination && form.touched.et_destination
                          }
                          mb="12px">
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">Destination</FormLabel>
                          <Select  {...field}
                            id="et_destination"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Select Destination"
                            size="lg"
                            value={values.et_destination}
                            onChange={handleChange}>
                            {allDestinations?.map((d, index) => (
                              <option key={`state-${index}`} value={d.id}>{d.name}</option>
                            ))
                            }
                          </Select>
                          <FormErrorMessage>
                            {form.errors.et_destination}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <FormControl
                      mb="12px"
                    >
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Tip Photo
                      </FormLabel>
                      <HStack>
                        <FileInput accept="image/*" onChange={onTipImageChange}></FileInput>
                      </HStack>
                    </FormControl>

                    <Field
                      name="et_website"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="et_website" ms="4px" fontSize="sm" fontWeight="normal">
                            Website (example: https://happyvalley.com)
                          </FormLabel>
                          <Input
                            {...field}
                            id="et_website"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.et_website}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="et_social"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="et_social" ms="4px" fontSize="sm" fontWeight="normal">
                            Social Link
                          </FormLabel>
                          <Input
                            {...field}
                            id="et_social"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.et_social}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <ButtonGroup>
                      <Button variant="outline" onClick={onEditTipClose}>
                        Cancel
                      </Button>
                      <Button
                        variant="outline"
                        onClick={removeTip}
                      >
                        Remove
                      </Button>
                      <Button
                        colorScheme="green"
                        type="submit"
                        isLoading={isSubmitting}
                      >
                        Save
                      </Button>
                    </ButtonGroup>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>

      <Drawer
        isOpen={isEditEventOpen}
        placement="left"
        size="lg"
        initialFocusRef={firstEditDestinationField}
        closeOnOverlayClick={false}
        onClose={onEditEventClose}
      >
        <DrawerOverlay />
        <Formik
          enableReinitialize
          initialValues={editEventFields}
          onSubmit={(values, actions) => {
            updateEvent(values);
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    Edit Event
                  </DrawerHeader>

                  <DrawerBody>

                    <Field
                      name="ee_title"
                      validate={validateTitle}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                          isInvalid={
                            form.errors.ee_title && form.touched.ee_title
                          }
                        >
                          <FormLabel htmlFor="ee_title" ms="4px" fontSize="sm" fontWeight="normal">
                            Event Title
                          </FormLabel>
                          <Input
                            {...field}
                            ref={firstEditDestinationField}
                            id="ee_title"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="HVAB"
                            size="lg"
                            value={values.ee_title}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {form.errors.ee_title}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                      <Field
                        name="ee_start_at"
                        validate={validateStart}
                        width={"100%"}
                      >
                        {({ field, form }) => (
                          <FormControl
                            mb="12px"
                            isInvalid={
                              form.errors.ee_start_at && form.touched.ee_start_at
                            }
                          >
                            <FormLabel htmlFor="ee_start_at" ms="4px" fontSize="sm" fontWeight="normal">
                              Start Date
                            </FormLabel>
                            <Input
                              {...field}
                              id="ee_start_at"
                              borderRadius="15px"
                              fontSize="sm"
                              placeholder="Select Date and Time"
                              size="lg"
                              type="datetime-local"
                              value={values.ee_start_at}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {form.errors.ee_start_at}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="ee_end_at"
                        validate={validateEnd}
                        width={"100%"}
                      >
                        {({ field, form }) => (
                          <FormControl
                            mb="12px"
                            isInvalid={
                              form.errors.ee_end_at && form.touched.ee_end_at
                            }
                          >
                            <FormLabel htmlFor="ee_end_at" ms="4px" fontSize="sm" fontWeight="normal">
                              End Date
                            </FormLabel>
                            <Input
                              {...field}
                              id="ee_end_at"
                              borderRadius="15px"
                              fontSize="sm"
                              placeholder="Select Date and Time"
                              size="lg"
                              type="datetime-local"
                              value={values.ee_end_at}
                              onChange={handleChange}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </SimpleGrid>

                    <Field
                      name="ee_repeat"
                      width={"100%"}
                      component={Recurrence}
                      rules={values.ee_repeat}
                    />

                    <Field
                      name="ee_content"
                      validate={validateEvent}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                          isInvalid={
                            form.errors.ee_content && form.touched.ee_content
                          }
                        >
                          <FormLabel htmlFor="ee_content" ms="4px" fontSize="sm" fontWeight="normal">
                            Event Information
                          </FormLabel>
                          <ReactQuill theme="snow" value={values.ee_content} onChange={field.onChange(field.name)} modules={modules} />
                          <FormErrorMessage>
                            {form.errors.ee_content}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ee_destination"
                      validate={validateDestination}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl id='ee_destination' mb="12px" isInvalid={
                          form.errors.ee_destination && form.touched.ee_destination
                        }>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">Destination</FormLabel>
                          <Select  {...field}
                            id="ee_destination"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Select Destination"
                            size="lg"
                            value={values.ee_destination}
                            onChange={handleChange}>
                            {destinations?.map((d, index) => (
                              <option key={`ev-d-${index}`} value={d.id}>{d.name}</option>
                            ))
                            }
                          </Select>
                          <FormErrorMessage>
                            {form.errors.ee_destination}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <FormControl
                      mb="12px"
                    >
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Categories
                          </FormLabel>
                      <DragDropContext onDragEnd={onDragEndEventsCategories}>
                        <Droppable droppableId="droppable-cat">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {sortEventsCategories.map((item, index) => (
                                <Draggable key={`dragckee-${item.id}`} draggableId={`dragceee-${item.id}`} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <Box pl={1}>
                                        <HStack>
                                          <FaArrowsAlt />
                                          <Select
                                            borderRadius="15px"
                                            fontSize="sm"
                                            placeholder="Select Category"
                                            size="lg"
                                            bg="white"
                                            value={item.category}
                                            onChange={(ev) => { item.category = ev.target.value; handleChange(ev); }}
                                          >
                                            {allEventsCategories?.map((c, ii) => (
                                              <option key={`ldaccee-${index}-${ii}`} value={c.id}>{c.name}</option>
                                            ))
                                            }
                                          </Select>
                                        </HStack>
                                      </Box>

                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                      <Flex mt={2}>
                        <Spacer />
                        <ButtonGroup>
                          <Button onClick={resetEventsCatList} size="sm">Reset Categories</Button>
                          <Button onClick={addEventsCatItem} size="sm">Add Category</Button>
                        </ButtonGroup>
                      </Flex>
                    </FormControl>

                    <FormControl
                      mb="12px"
                    >
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Event Photo
                      </FormLabel>
                      <HStack>
                        <FileInput accept="image/*" onChange={onEventImageChange}></FileInput>
                      </HStack>
                    </FormControl>

                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                      <Field
                        name="ee_email"
                        width={"100%"}
                      >
                        {({ field, form }) => (
                          <FormControl
                            mb="12px"
                          >
                            <FormLabel htmlFor="ee_email" ms="4px" fontSize="sm" fontWeight="normal">
                              Email
                            </FormLabel>
                            <Input
                              {...field}
                              id="ee_email"
                              borderRadius="15px"
                              fontSize="sm"
                              type="text"
                              placeholder="Email"
                              size="lg"
                              value={values.ee_email}
                              onChange={handleChange}
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="ee_phone"
                        width={"100%"}
                      >
                        {({ field, form }) => (
                          <FormControl
                            mb="12px"
                          >
                            <FormLabel htmlFor="ee_phone" ms="4px" fontSize="sm" fontWeight="normal">
                              Phone
                            </FormLabel>
                            <Input
                              {...field}
                              as={InputMask}
                              id="ee_phone"
                              borderRadius="15px"
                              fontSize="sm"
                              type="text"
                              placeholder="Phone"
                              size="lg"
                              value={values.ee_phone}
                              onChange={handleChange}
                              mask="999-999-9999"
                            />
                          </FormControl>
                        )}
                      </Field>
                    </SimpleGrid>

                    <Field
                      name="ee_website"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ee_website" ms="4px" fontSize="sm" fontWeight="normal">
                            Website (example: https://happyvalley.com)
                          </FormLabel>
                          <Input
                            {...field}
                            id="ee_website"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ee_website}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="ee_social"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="ee_social" ms="4px" fontSize="sm" fontWeight="normal">
                            Social Link
                          </FormLabel>
                          <Input
                            {...field}
                            id="ee_social"
                            borderRadius="15px"
                            fontSize="sm"
                            type="url"
                            placeholder="https://"
                            size="lg"
                            value={values.ee_social}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Box height="30vh">

                    </Box>

                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <ButtonGroup>
                      <Button variant="outline" onClick={onEditEventClose}>
                        Cancel
                      </Button>
                      <Button
                        variant="outline"
                        onClick={removeEvent}
                      >
                        Remove
                      </Button>
                      <Button
                        colorScheme="green"
                        type="submit"
                        isLoading={isSubmitting}
                      >
                        Save
                      </Button>
                    </ButtonGroup>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>

      <Drawer
        isOpen={isEditListOpen}
        placement="left"
        size="lg"
        initialFocusRef={firstEditDestinationField}
        closeOnOverlayClick={false}
        onClose={onEditListClose}
      >
        <DrawerOverlay />
        <Formik
          enableReinitialize
          initialValues={editListFields}
          onSubmit={(values, actions) => {
            let itemsError = false;
            sortItems.forEach((item) => {
              if (!item.destination) {
                itemsError = true;
              }
            });
            if (itemsError) {
              showToast('A list must include at least 3 items with destinations selected.', 'error');
            }
            updateList(values);
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    Edit List
                  </DrawerHeader>

                  <DrawerBody>

                    <Field
                      name="el_title"
                      validate={validateTitle}
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                          isInvalid={
                            form.errors.el_title && form.touched.el_title
                          }
                        >
                          <FormLabel htmlFor="el_title" ms="4px" fontSize="sm" fontWeight="normal">
                            List Title
                          </FormLabel>
                          <Input
                            {...field}
                            id="el_title"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Take a Happy Valley Agventures tour!"
                            size="lg"
                            value={values.el_title}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {form.errors.el_title}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="el_content"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl
                          mb="12px"
                        >
                          <FormLabel htmlFor="el_content" ms="4px" fontSize="sm" fontWeight="normal">
                            Local List Information
                          </FormLabel>
                          <ReactQuill theme="snow" value={values.el_content} onChange={field.onChange(field.name)} placeholder="A list of the best destinations in Happy Valley!" modules={modules} />
                        </FormControl>
                      )}
                    </Field>

                    <FormControl
                      mb="12px"
                    >

                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable-list-edit">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {sortItems.map((item, index) => (
                                <Draggable key={`draglek-${item.id}`} draggableId={`dragle-${item.id}`} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <Box pl={1}>
                                        <HStack>
                                          <Box minWidth="5%">
                                            <FaArrowsAlt />
                                          </Box>
                                          <Box minWidth="70%" bg="white" borderBottomRadius={15}>
                                            <ReactQuill theme="snow" value={item.information} onChange={(content) => { item.information = content }} placeholder={item.content} modules={modules} />
                                          </Box>
                                          <Select
                                            borderRadius="15px"
                                            fontSize="sm"
                                            placeholder="Select Destination"
                                            size="lg"
                                            bg="white"
                                            defaultValue={item.id}
                                            value={item.destination}
                                            onChange={(ev) => { item.destination = ev.target.value; handleChange(ev); }}
                                          >
                                            {allDestinations?.map((d, ii) => (
                                              <option key={`lde-${index}-${ii}`} value={d.id}>{d.name}</option>
                                            ))
                                            }
                                          </Select>
                                        </HStack>
                                      </Box>

                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                      <Flex mt={2}>
                        <Spacer />
                        <ButtonGroup flexDirection={{ base: "column", md: "row" }}>
                          <Button onClick={resetList} size="sm">Reset List</Button>
                          <Button onClick={addListItem} size="sm" mt={{ base: 2, md: 0 }}>Add List Item</Button>
                        </ButtonGroup>
                      </Flex>

                    </FormControl>

                    <FormControl
                      mb="12px"
                    >
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        List Photo
                      </FormLabel>
                      <HStack>
                        <FileInput accept="image/*" onChange={onListImageChange}></FileInput>
                      </HStack>
                    </FormControl>

                    <Field
                      name="el_destination"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl id='el_destination' mb="12px">
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">Main List Destination</FormLabel>
                          <Select  {...field}
                            id="el_destination"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Select Destination"
                            size="lg"
                            value={values.el_destination}
                            onChange={handleChange}>
                            {allDestinations?.map((d, index) => (
                              <option key={`ld-${index}`} value={d.id}>{d.name}</option>
                            ))
                            }
                          </Select>
                        </FormControl>
                      )}
                    </Field>

                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <ButtonGroup>
                      <Button variant="outline" onClick={onEditListClose}>
                        Cancel
                      </Button>
                      <Button
                        variant="outline"
                        onClick={removeList}
                      >
                        Remove
                      </Button>
                      <Button
                        colorScheme="green"
                        type="submit"
                        isLoading={isSubmitting}
                      >
                        Save
                      </Button>
                    </ButtonGroup>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>

      <Drawer
        isOpen={isEditReservationOpen}
        placement="left"
        size="lg"
        initialFocusRef={firstEditDestinationField}
        closeOnOverlayClick={false}
        onClose={onEditReservationClose}
      >
        <DrawerOverlay />
        <Formik
          enableReinitialize
          initialValues={editReservationFields}
          onSubmit={(values, actions) => {
            //updateReservation(values);
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    Edit Reservation
                  </DrawerHeader>

                  <DrawerBody>



                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <ButtonGroup>
                      <Button variant="outline" onClick={onEditReservationClose}>
                        Cancel
                      </Button>
                      <Button
                        variant="outline"

                      >
                        Remove
                      </Button>
                      <Button
                        colorScheme="green"
                        type="submit"
                        isLoading={isSubmitting}
                      >
                        Save
                      </Button>
                    </ButtonGroup>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">

            </AlertDialogHeader>

            <AlertDialogBody>

            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="green" ml={3}>

              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </Container>
  );
};

export default Profile;

