import React from "react";
import { Box, Heading, Link } from "@chakra-ui/react";

const ImageBlock = ({ page, block }) => {
    return (
        <Box>
          <Link href={block.entry.link} _hover={{ opacity: 0.8 }}>
            <Box backgroundImage={`url(${block.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="500px" position="relative">
              <Heading textAlign="left" position="absolute" bottom="10px" left="20px" color="white" textShadow="0px 0px 5px #000" lineHeight="1em">{block.title}</Heading>
            </Box>
          </Link>
        </Box>
      )
};

export default ImageBlock;