import React, { useState, useEffect} from "react";
import parser from "html-react-parser";
import { Box, Button, Image, Container, Flex, Center, Grid, GridItem, Heading, Link, SimpleGrid, Text, HStack, Input } from "@chakra-ui/react";

const BookBlock = ({ page, block }) => {
    const [baseLink, setBaseLink] = useState('https://happyvalley.bookdirect.net/#/lodgings?campaign=PennState-interior-bookingwidget-lodging');
    const [link, setLink] = useState('https://happyvalley.bookdirect.net/#/lodgings?campaign=PennState-interior-bookingwidget-lodging');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleCheckinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const replaced = event.target.value.replace(/-/g, "");
        setStartDate(replaced);
        setLink(baseLink + '&checkin=' + replaced + '&checkout=' + endDate + '&locale=en');
    };

    const handleCheckoutChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const replaced = event.target.value.replace(/-/g, "");
        setEndDate(replaced);
        setLink(baseLink + '&checkin=' + startDate + '&checkout=' + replaced + '&locale=en');
    };

    useEffect(() => {
        if (block.entry.link && block.entry.link !== '') {
            setBaseLink(block.entry.link);
            setLink(block.entry.link);
        }
    }, []);

    return (
        <Box>
            <Box bg="#DF6F39" zIndex={40} position="relative" minHeight={'180px'} pt={2} pr={10} pb={'50px'} pl={10} textAlign="left">
                <Heading fontSize={{ base: "2.5em", md: "3xl", lg: "5xl", xl: "6xl" }} textAlign="center" lineHeight={0.8} color="#fff" mt={5}>
                    {block.title}
                </Heading>
                <Text as={'p'} fontSize="1.25em" textAlign="center" color="#fff">
                    {block.entry.text} {' '}
                Use our easy <Link href={link} target="_blank" borderBottom="2px solid">BookDirect tool</Link> to find your perfect room.
                </Text>
            </Box>
            <Container maxW={'5xl'}>

                <Box bg="#8A8AE5" zIndex={40} position="relative" minHeight={'80px'} mt={-10} pt={5} pr={10} pb={5} pl={10} textAlign="left">

                    <SimpleGrid columns={{ base: 1, md: 4 }} spacing={2}>
                        <Text as={'h2'} fontSize="1.65em" className="header" color="white">
                            BOOK NOW
                        </Text>
                        <Input type="date" placeholder="Check In" bg="#19325B" color="#A1C7C6" border="0" onChange={handleCheckinChange}></Input>
                        <Input type="date" placeholder="Check Out" bg="#19325B" color="#A1C7C6" border="0" onChange={handleCheckoutChange}></Input>
                        <Button type="submit" as={Link} href={link} target="_blank" borderTopStartRadius={{ base: 5, md: 0 }} borderBottomStartRadius={{ base: 5, md: 0 }} borderTopEndRadius={5} borderBottomEndRadius={5}>
                            LETS GO!
                        </Button>
                    </SimpleGrid>
                </Box>
            </Container >
        </Box>
    )
};

export default BookBlock;