import * as React from "react";
import { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Container,
  useToast,
  Flex,
  SimpleGrid,
  StackDivider,
  SpaceProps,
  HStack,
  Tag,
  Link,
  Center,
  Grid,
  GridItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  List,
  ListItem,
  ListIcon
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, baseUrl } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import headerBg from "../assets/images/header-background.jpg";
import footerBg from "../assets/images/footer-background.jpg";
import cloudsBg from "../assets/images/cloud-background.png";
import parser from "html-react-parser";
import { MotionBox } from "../components/motion";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon, LatLng } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { CrowdRiffGallery } from 'react-crowdriff-gallery';
import { MdCheckCircle } from "react-icons/md";
import MediaBlock from "../components/media-block";
import FavoritesBlock from "../components/favorites-block";
import ImageBlock from "../components/image-block";
import CrowdBlock from "../components/crowd-block";
import MapBlock from "components/map-block";
import CTABlock from "components/cta-block";
import ImagesBlock from "components/images-block";
import ImagesGridBlock from "components/images-grid-block";
import TabsBlock from "components/tabs-block";
import SlidesBlock from "components/slides-block";
import HighlightsBlock from "components/highlights-block";
import EmbedBlock from "components/embed-block";
import BookBlock from "components/book-block";
import ContentBlock from "components/content-block";

const markdownTheme = {
  p: props => {
    const { children } = props;
    return (
      <Text mb={2} fontSize={'0.8em'}>
        {children}
      </Text>
    );
  },
};

const Destination = ({ match }) => {
  const toast = useToast();

  const [destination, setDestination] = useState({});
  const [loading, setLoading] = useState(true);
  const [variables, setVariables] = useState({});

  const [position, setPosition] = useState([40.7880456, -77.870124]);

  const getDestination = () => {
    let data = {};
    axios.get(`${apiHost}/destinations/get/${match.params.id}`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setDestination(data);

          let geomarkers = [];
          geomarkers.push({ data: data, location: new LatLng(data.location_latitude, data.location_longitude) })
          setMarkers(geomarkers);

          setPosition([data.location_latitude, data.location_longitude]);

          setLoading(false);
        }
        return res;
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });

    return data;
  };

  const initialMarkers = [];
  const [markers, setMarkers] = useState(initialMarkers);

  const LocationMarkers = () => {
    return (
      <React.Fragment>
        {markers.map(marker => <Marker
          position={marker.location}
          icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
          eventHandlers={{
            click: (e) => {
              //setSelectedDestination(marker.data);
              //onOpen();
            },
          }}>
          <Popup>
            {destination.name}<br />
            {marker.data.address}<br />
            {marker.data.city}, {marker.data.state} {marker.data.zip}<br />
            <Link href={`tel:+1${destination.phone}`}>{destination.phone}</Link>
          </Popup>
        </Marker>)}
      </React.Fragment>
    );
  }

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }

  const getVariables = () => {
    let data = [];
    axios.get(`${apiHost}/variables/get/global`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          console.log(data);
          setVariables(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDestination();
    getVariables();
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title>{destination.name}</title>
        <meta name="description" content={destination.meta_description} />
        <script>
            {destination.scripts}
        </script>
      </Helmet>
      {!loading && !destination.slug && (
        <Box>
          <Container maxW={'5xl'}>
            <Heading
              as={'h3'}
              fontFamily="american"
              fontWeight={500}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
              lineHeight={'100%'}
              textAlign="left"
              mt={{ base: 10, md: 5 }}>
              404 Page Not Found
            </Heading>
            <Box textAlign="left">
              <Button as={Link} href="/">Go to Home Page</Button>
            </Box>
          </Container>
        </Box>
      )}
      {destination.header_img != null ? (
        <Box className="home-header-container" backgroundImage={`url(${destination.header_img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="60dvh">
          <Container maxW={'5xl'}>

          </Container>
        </Box>
      ) : null}
      <Box bg="#15315A">
        <Container maxW={'5xl'}>
          <Grid templateColumns='repeat(6, 1fr)'>
            <GridItem colSpan={{ base: 12, md: 6 }}>
              <Text
                as="h1"
                textAlign="left"
                fontFamily="american"
                fontWeight={500}
                fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                lineHeight={'100%'}
                color="#BDECDE"
                mt={{ base: 4, md: 0 }}
              >{destination.name}</Text>
            </GridItem>
          </Grid>
        </Container>
      </Box>
      {destination.slug && (
        <Box bg="#BEE3DA">
          <Container maxW={'5xl'}>
            <Stack direction={{ base: 'column', md: 'row' }}>
              <Box w={{ base: '100%', md: '50%', lg: '65%' }} >
                <Box position="relative">
                  {destination.main_img ? (
                    <Image
                      src={`${destination.main_img}`}
                      layout={'fill'}
                      w={'100%'}
                      roundedBottomLeft={20}
                      alt={'main image'}
                    />
                  ) : null}
                  {!destination.main_img && variables.default_destination_img ? (
                    <Image
                      src={`${variables.default_destination_img}`}
                      layout={'fill'}
                      w={'100%'}
                      roundedBottomLeft={20}
                      alt={'main image'}
                    />
                  ) : null}
                  {destination.bd_lowest_rate && (
                    <Link
                      href={destination.reservations_link}
                      target="_blank"
                      fontWeight={'bold'}
                      fontSize="15px"
                      color="white"
                      whiteSpace={'nowrap'}
                      bg="#8A89E5"
                      borderTopStartRadius={"0.375rem"}
                      borderBottomStartRadius={"0.375rem"}
                      borderTopEndRadius={"0.375rem"}
                      borderBottomEndRadius={"0.375rem"}
                      p={2}
                      position="absolute"
                      bottom="10px"
                      right="10px"
                    ><small><em>starting at</em></small><br />${destination.bd_lowest_rate}</Link>
                  )}
                </Box>
              </Box>
              <Box w={{ base: '100%', md: '50%', lg: '35%' }} textAlign="left" bg="white" pl={10} pr={10} pb={5} roundedBottomRight={20}>
                <Heading
                  as={'h3'}
                  fontFamily="american"
                  fontWeight={500}
                  fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                  lineHeight={'100%'}
                  mt={{ base: 10, md: 5 }}
                  className="mobile-center">
                  {destination.name}
                </Heading>

                <Stack className="mobile-center">
                  <Text color="gray.500">{destination.address}<br />
                    {destination.city}, {destination.state} {destination.zip}</Text>
                  <Link href={`tel:+1${destination.phone}`} fontSize="16px;">{destination.phone}</Link>
                </Stack>

                <Box>
                  {destination.sidebar_callout_content ? (
                    <Box
                      color="#222"
                      fontWeight={500}>
                      {parser(destination.sidebar_callout_content)}
                    </Box>
                  ) : null}
                </Box>

                <Stack mt={5}>
                  {destination.website ? (
                    <Button as={Link} href={`${destination.website}`} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">Website</Button>
                  ) : null}
                  {destination.phone ? (
                    <Button as={Link} href={`tel:+1${destination.phone}`} borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">Call</Button>
                  ) : null}
                  {destination.address ? (
                    <Button as={Link} href={`https://www.google.com/maps/dir/?api=1&destination=${destination.address} ${destination.city} ${destination.state} ${destination.zip}`} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">Get Directions</Button>
                  ) : null}
                  {destination.map_link ? (
                    <Button as={Link} href={destination.map_link} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">Map</Button>
                  ) : null}
                  {destination.reservations_link ? (
                    <Button as={Link} href={destination.reservations_link} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" color="white" bg="#8A89E5">Reservations</Button>
                  ) : null}
                  {destination.restaurant_link ? (
                    <Button as={Link} href={destination.restaurant_link} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">Menu</Button>
                  ) : null}
                  {destination.golf_link ? (
                    <Button as={Link} href={destination.golf_link} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">Golf</Button>
                  ) : null}
                  {destination.facebook_link ? (
                    <Button as={Link} href={destination.facebook_link} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">Facebook</Button>
                  ) : null}
                  {destination.instagram_link ? (
                    <Button as={Link} href={destination.instagram_link} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">Instagram</Button>
                  ) : null}
                  {destination.twitter_link ? (
                    <Button as={Link} href={destination.twitter_link} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">Twitter</Button>
                  ) : null}
                  {destination.youtube_link ? (
                    <Button as={Link} href={destination.youtube_link} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">YouTube</Button>
                  ) : null}
                  {destination.pinterest_link ? (
                    <Button as={Link} href={destination.pinterest_link} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">Pinterest</Button>
                  ) : null}
                  {1 == 0 ? (
                    <Button as={Link} href={'/calendar'} borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white">View The Calendar</Button>
                  ) : null}
                </Stack>
              </Box>
            </Stack>
          </Container>
          <Tabs variant="enclosed" w="100%" mt={5} bg="white">
            <Container maxW={'5xl'}>
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box textAlign="left" ml={5} mt={5}>
                  <Text textAlign="left"
                    fontFamily="american"
                    fontWeight={500}
                    lineHeight={'1em'}
                    fontSize={{ base: '2xl', sm: '3xl', md: '3xl' }}>
                    About the {destination.name}
                  </Text>
                </Box>
                <Center>
                  <TabList borderBottom={0}>
                    <Tab
                      whiteSpace={'nowrap'}
                      textTransform="uppercase"
                      borderRadius={0}
                      fontWeight="bold"
                      color="#17325B"
                      pt={0}
                      pb={0}
                      p={5}
                      _selected={{ color: "#17325B", bg: "#ffffff" }}
                    >Why Us</Tab>
                    <span class="vertical-divider"></span>
                    <Tab
                      whiteSpace={'nowrap'}
                      textTransform="uppercase"
                      borderRadius={0}
                      fontWeight="bold"
                      color="#17325B"
                      pt={0}
                      pb={0}
                      p={5}
                      _selected={{ color: "#17325B", bg: "#ffffff" }}
                    >Amenities</Tab>
                    <span class="vertical-divider"></span>
                    <Tab
                      whiteSpace={'nowrap'}
                      textTransform="uppercase"
                      borderRadius={0}
                      fontWeight="bold"
                      color="#17325B"
                      pt={0}
                      pb={0}
                      p={5}
                      _selected={{ color: "#17325B", bg: "#ffffff" }}
                    >Gallery</Tab>
                  </TabList>
                </Center>

              </SimpleGrid>

              <TabPanels>
                <TabPanel textAlign="left">
                  {destination.callout_content ? (
                    <Box backgroundColor="white"
                      color="#222"
                      fontFamily="american"
                      fontWeight={500}
                      fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                      borderRadius={5}>
                      {parser(destination.callout_content)}
                    </Box>
                  ) : null}

                  {destination.content ? (
                    <Box className="content-block">
                      {parser(destination.content)}
                    </Box>
                  ) : null}

                </TabPanel>
                <TabPanel>

                  <List spacing={3} textAlign="left">
                    {destination.d_amenities?.map((amenity, index) => (
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='green.500' />
                        {amenity.name}
                      </ListItem>
                    ))}
                  </List>

                </TabPanel>
                <TabPanel>
                  <SimpleGrid columns={{ base: 2, md: 3 }} spacing={5}>
                    {destination.d_gallery_images?.map((img_url, index) => (
                      <Box backgroundImage={`url(${img_url})`} backgroundPosition="center center" backgroundSize="cover" minHeight={{ base: "200px", md: "250px" }}>

                      </Box>
                    ))}
                  </SimpleGrid>
                </TabPanel>
              </TabPanels>
            </Container>
          </Tabs>

          {destination.content_blocks?.map((block, index) => (
            <Box key={`block-${index}`}>
              {
                {
                  'anomaly.extension.favorites_block_block': <FavoritesBlock page={destination} block={block} />,
                  'anomaly.extension.media_block_block': <MediaBlock page={destination} block={block} />,
                  'anomaly.extension.image_block_block': <ImageBlock page={destination} block={block} />,
                  'anomaly.extension.crowd_block_block': <CrowdBlock page={destination} block={block} />,
                  'anomaly.extension.map_block_block': <MapBlock page={destination} block={block} />,
                  'anomaly.extension.cta_block_block': <CTABlock page={destination} block={block} />,
                  'anomaly.extension.images_block_block': <ImagesBlock page={destination} block={block} />,
                  'anomaly.extension.images_grid_block_block': <ImagesGridBlock page={destination} block={block} />,
                  'anomaly.extension.tabs_block_block': <TabsBlock page={destination} block={block} />,
                  'anomaly.extension.slides_block_block': <SlidesBlock page={destination} block={block} />,
                  'anomaly.extension.highlights_block_block': <HighlightsBlock page={destination} block={block} />,
                  'anomaly.extension.embed_block_block': <EmbedBlock page={destination} block={block} />,
                  'anomaly.extension.content_block_block': <ContentBlock page={destination} block={block} />,
                  'anomaly.extension.book_block_block': <BookBlock page={destination} block={block} />,
                }[block.extension]
              }
            </Box>
          ))}

          <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{ minHeight: '60vh' }}>
            <ChangeView center={position} zoom={13} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarkers />
          </MapContainer>

        </Box>
      )}

    </PageSlideFade >
  );
};

export default Destination;
