import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "style/theme";
import Fonts from "style/fonts";
import Layout from "./layouts/layout";
import { AuthProvider } from "./context/AuthContext";

export const App = () => (
  <ChakraProvider theme={theme} resetCSS={true}>
    <Fonts />
    <AuthProvider>
      <Layout />
    </AuthProvider>
  </ChakraProvider>
);
