import React from "react";
import parser from "html-react-parser";
import { Box, Button, Image, Container, Flex, Center, Grid, GridItem, Heading, Link, SimpleGrid, Text, HStack } from "@chakra-ui/react";

const CTABlock = ({ page, block }) => {

    return (
        <Box bg="white" pt={10}>
            <Container maxW={'6xl'}>
                <Box bg="#8A89E5" borderTopStartRadius={15} borderTopEndRadius={15} >
                    <SimpleGrid columns={{ base: 1, md: 2 }} >
                        <Box>
                            <Link href={block.entry.link}>
                                <HStack>
                                    <Image src={block.img} height="60px" m={4} />

                                    <Heading fontSize="6xl" textAlign="left" color="white" pt="6px">
                                        {block.title}
                                    </Heading>
                                </HStack>
                            </Link>
                        </Box>
                        <Box p={6} justifyContent="flex-end" textAlign="right">
                            <Button as={Link} href={block.entry.link} bg="white">{block.entry.button_text}</Button>
                        </Box>
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>
    )
};

export default CTABlock;