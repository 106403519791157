import * as React from "react";
import { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Container,
  useToast,
  Flex,
  SimpleGrid,
  StackDivider,
  Icon,
  SpaceProps,
  HStack,
  Tag,
  Link
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, baseUrl } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import headerBg from "../assets/images/header-background.jpg";
import cloudsBg from "../assets/images/cloud-background.png";
import Masonry from "react-masonry-css";
import "../style/style.css";

const markdownTheme = {
  p: props => {
    const { children } = props;
    return (
      <Text mb={2} fontSize={'0.8em'}>
        {children}
      </Text>
    );
  },
};

const Activity = ({ match }) => {
  const toast = useToast();

  const [destination, setDestination] = useState({});
  const [loading, setLoading] = useState(true);

  const breakpointColumnsObj = {
    default: 3,
    1300: 2,
    900: 1
  };  

  const getActivity = () => {
    let data = {};
    axios.get(`${apiHost}/activities/get/${match.params.id}`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setDestination(data);
          setLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getActivity();
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title></title>
        <meta name="description" content={null} />
      </Helmet>
      <Box className="home-header-container" backgroundImage={`url(${destination.header_image_url})`} backgroundPosition="center center" backgroundSize="cover" minHeight="60vh">
        <Container maxW={'5xl'}>
          <Stack
            textAlign={'center'}
            align={'center'}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 28 }}>
            <Heading
              as={'h1'}
              fontFamily="american"
              fontWeight={500}
              fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
              color="white"
              lineHeight={'100%'}
              mt={{ base: 20, md: 0 }}>
              Experience {' '}
              <Text as={'div'} fontFamily="brush" fontWeight={500}
                fontSize={{ base: '6rem', md: '13rem' }} color="white" textShadow="2px 2px 8px rgba(0,0,0,0.5)" mt="3.5rem">
                Happy Valley
              </Text>
            </Heading>
          </Stack>

        </Container>
      </Box>
      <Box backgroundImage={`url(${cloudsBg})`} backgroundPosition="top center" backgroundRepeat="no-repeat" backgroundSize="fit" marginTop="-350px" paddingTop="250px">
        <Container maxW={'5xl'}>
          <Heading>{destination.name}</Heading>
          <Text>{destination.description}</Text>
          <Box pt={10} pb={10}>
          { parser (destination.content) }
          </Box>
          <Masonry breakpointCols={breakpointColumnsObj}
              className="masonry-grid"
              columnClassName="masonry-grid_column">
      
            {destination.destinations?.map((solution, index) => (
              <Box
                w={'100%'}
                boxShadow={'0 0 10px 2px #ccc'}
                rounded={'md'}
                _hover={{
                  bg: '#E0CEBE'
                }}
                d="inline-block"
                mb={5}
                >
                <Box
                  p={4}
                >
                  <Link href={`/#/d/${solution.slug}`}>
                    <Image
                      src={`${solution.main_image}`}
                      layout={'fill'}
                      w={'100%'}
                      rounded={'md'}
                    />
                  </Link>
                  <Box position="relative">
                  <Link href={`/#/d/${solution.slug}`}>
                    <Heading
                      fontSize={'3xl'}
                      fontFamily={'american'}
                      pt={3}
                      lineHeight={'100%'}
                      pb={0}>
                      {solution.name}
                    </Heading>
                    </Link>
                  </Box>
                </Box>
              </Box>
            ))
            }
          </Masonry>
        </Container>
      </Box>

    </PageSlideFade>
  );
};

export default Activity;
