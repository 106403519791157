import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import { CrowdRiffGallery } from 'react-crowdriff-gallery';

const CrowdBlock = ({ page, block }) => {
    return (
        <Box>
            <Heading fontSize="3em" lineHeight="1em" paddingTop="20px" pl="10px" pr="10px" color="#17325B">{block.title}</Heading>
            <CrowdRiffGallery hash={block.entry.crowdriff_hash} />
        </Box>
    )
};

export default CrowdBlock;