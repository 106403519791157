import React, { useState, useEffect } from "react";
import parser from "html-react-parser";
import { Box, Button, Image, Container, Flex, Center, Grid, GridItem, Heading, Link, SimpleGrid, Text, HStack } from "@chakra-ui/react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon, LatLng } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";

const MapBlock = ({ page, block }) => {
    const position = [40.7880456, -77.870124];

    const initialMarkers = [];
    const [markers, setMarkers] = useState(initialMarkers);
    const [towns, setTowns] = useState(initialMarkers);

    useEffect(() => {
        let geomarkers = [];
        if (block.entry.destinations) {
            block.entry.destinations.forEach(item => {
                geomarkers.push({ data: item, location: new LatLng(item.location_latitude, item.location_longitude) })
            });
            setMarkers(geomarkers);
        }
        geomarkers = [];
        if (block.entry.towns) {
            block.entry.towns.forEach(item => {
                geomarkers.push({ data: item, location: new LatLng(item.location_latitude, item.location_longitude) })
            });
            setTowns(geomarkers);
        }
    }, []);

    const LocationMarkers = () => {
        return (
            <React.Fragment>
                {markers.map(marker => <Marker
                    position={marker.location}
                    icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
                    eventHandlers={{
                        click: (e) => {
                            //setSelectedDestination(marker.data);
                            //onOpen();
                        },
                    }}>
                    <Popup>
                        <Link href={`/d/${marker.data.slug}`}>{marker.data.name}</Link><br />
                        {marker.data.address}<br />
                        {marker.data.city}, {marker.data.state} {marker.data.zip}<br />
                        <Link href={`tel:+1${marker.data.phone}`}>{marker.data.phone}</Link>
                    </Popup>
                </Marker>)}
            </React.Fragment>
        );
    }

    const LocationTowns = () => {
        return (
            <React.Fragment>
                {towns.map(marker => <Marker
                    position={marker.location}
                    icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
                    eventHandlers={{
                        click: (e) => {
                            //setSelectedDestination(marker.data);
                            //onOpen();
                        },
                    }}>
                    <Popup>
                        <Link href={`/towns/${marker.data.slug}`}>{marker.data.name}</Link><br />
                    </Popup>
                </Marker>)}
            </React.Fragment>
        );
    }

    return (
        <Box>
            {block.title ? (
                <Heading
                    as={'h2'}
                    fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                    textAlign={{ base: 'center', md: 'center' }}
                    lineHeight={'110%'}
                    mt={5}
                    color="#17325B">
                    {block.title}
                </Heading>
            ) : null}
            <MapContainer center={position} zoom={10} scrollWheelZoom={false} style={{ minHeight: '60vh' }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarkers />
                <LocationTowns />
            </MapContainer>
        </Box>
    )
};

export default MapBlock;