import { Box, Grid, GridItem, Heading, Link, SimpleGrid, Text, Container, Button } from "@chakra-ui/react";
import React from "react";
import parser from "html-react-parser";

const HighlightsBlock = ({ page, block }) => {

    return (
        <Box className="category-wrapper" pt={10} backgroundImage={`url(${block.img})`} backgroundPosition="center center" backgroundSize="cover">
            <Container maxW={'6xl'}>
                <div class="experiences-header">
                    <Heading
                        as={'h2'}
                        fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                        textAlign={{ base: 'center', md: 'center' }}
                        lineHeight={'110%'}
                        color="#17325B">
                        {block.title}
                    </Heading>
                    {block.entry.content != null ? (
                        <Text className="content-block" textAlign={{ base: 'center', md: 'center' }}>
                            {parser(block.entry.content)}
                        </Text>
                    ) : null}
                </div>
                <Box pb={10}>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                        {block.entry.experiences.map((slide, index) => (
                            <Box>
                                {slide.img && (
                                    <Box backgroundImage={`url(${slide.img})`} backgroundPosition="center center" backgroundRepeat="no-repeat" backgroundSize="cover" minHeight="400px">

                                    </Box>
                                )}
                                <Box textAlign="left">
                                    {slide.title != null && (
                                        <Heading as={'h3'} fontSize="7xl" textAlign="left" lineHeight={0.8} color="#5A729A" mt={5}>
                                            {slide.title}
                                        </Heading>
                                    )}
                                    {slide.content != null && (
                                        <Box>{parser(slide.content)}</Box>
                                    )}
                                    {slide.link != null && (
                                        <Button as={Link} href={slide.link} bg="#F26624" color="white" fontSize="12px" marginTop="20px" letterSpacing="2px" borderTopStartRadius={20} borderBottomStartRadius={20} borderTopEndRadius={20} borderBottomEndRadius={20}>{slide.button_text}</Button>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </SimpleGrid>
                    <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={5} mt={10}>
                        {block.entry.slides.map((slide, index) => (
                            <Box>
                                {slide.img && (
                                    <Box backgroundImage={`url(${slide.img})`} backgroundPosition="center center" backgroundRepeat="no-repeat" backgroundSize="cover" minHeight="200px">

                                    </Box>
                                )}
                                <Box textAlign="left">
                                    {slide.title != null && (
                                        <Heading as={'h3'} fontSize="2xl" textAlign="left" lineHeight={0.8} color="#5A729A" mt={5}>
                                            {slide.title}
                                        </Heading>
                                    )}
                                    {slide.content != null && (
                                        <Box>{parser(slide.content)}</Box>
                                    )}
                                    {slide.link != null && (
                                        <Button as={Link} href={slide.link} bg="#F26624" color="white" fontSize="12px" marginTop="20px" letterSpacing="2px" borderTopStartRadius={20} borderBottomStartRadius={20} borderTopEndRadius={20} borderBottomEndRadius={20}>{slide.button_text}</Button>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>
    )
};

export default HighlightsBlock;