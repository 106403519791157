import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Center,
  Image,
  HStack,
  Button,
  Link,
  Tooltip,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Spacer,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Input,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  List,
  ListItem,
  UnorderedList,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  SimpleGrid,
  Container,
  Grid,
  GridItem,
  Skeleton,
  CloseButton,
  Img,
  Text,
} from "@chakra-ui/react";
import { NavLink as RouterNavLink, useLocation, useHistory } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { ColorModeSwitcher } from "ColorModeSwitcher";
import HeaderLogo from "assets/images/header-logo.png";
import { AuthContext } from "../context/AuthContext";
import { FaFacebook, FaInstagram, FaSearch } from "react-icons/fa";
import headerBg from "../assets/images/popular-destinations.jpg";
import { initContainer, initPopup, initBubble, getPopupActions, getBubbleActions } from "typebot-js";
import { apiHost, baseUrl } from "utils/Cons/Constants";
import axios from "axios";
import parser from "html-react-parser";
import debounce from 'lodash.debounce';
import { randomInt } from "crypto";
import { Menu, MenuItem } from './menu';

interface NavLinkProps {
  index?: string;
  name: string;
  path: string;
  color: string;
  onClose: () => void;
}

const NavLink = (props: NavLinkProps) => {
  return (
    <Link
      as={RouterNavLink}
      px={2}
      py={1}
      rounded={"md"}
      textTransform={"uppercase"}
      fontSize={15}
      letterSpacing={1.5}
      fontWeight="bold"
      bg={props.color}
      _hover={{
        textDecoration: "none",
        color: '#B58455'
      }}
      _activeLink={{
      }}
      onClick={() => props.onClose()}
      to={props.path}
    >
      {props.name}
    </Link>
  );
};

const analytics = ((w: Window, d: Document, s: string, l: string, i: string) => {
  (w as any).dataLayer = (window as any).dataLayer || [];
  (w as any).dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  var dl = l != 'dataLayer' ? '&l=' + l : '';
  var scr = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  /*
      To avoid Multiple installations of google tag manager detected warning
  */
  if (!scriptExists(scr)) {
    var f = d.getElementsByTagName(s)[0],
      j: HTMLScriptElement = d.createElement("script")
    j.async = true;
    j.src = scr;
    f?.parentNode?.insertBefore(j, f);
  }
})
const scriptExists = (url: string) => {
  var scripts = document.getElementsByTagName('script');
  for (var i = scripts.length; i--;) {
    if (scripts[i].src == url) return true;
  }
  return false;
}

const TopNav = (props) => {
  const history = useHistory();
  const routeLocation = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tabIndex, setTabIndex] = useState(-1);
  const [submenuLinks, setSubmenuLinks] = useState([]);
  const [topLinks, setTopLinks] = useState([]);
  const [variables, setVariables] = useState({ nav_bar_color: '#19325b' });
  const [destinations, setDestinations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pages, setPages] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const [tabs, setTabs] = useState([]);

  const [stickyLinks, setStickyLinks] = useState([
    [],
  ]);

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose
  } = useDisclosure()

  const authContext = useContext(AuthContext);
  const signInHoverColor = useColorModeValue("#222", "#fff");
  const bgColor = useColorModeValue("gray.200", "gray.900");

  const getNavigation = () => {
    let data = {};
    axios.get(`${apiHost}/nav`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          let tabs = [];
          let stickyMenu = [];
          data.forEach(tab => {
            tabs.push({
              name: tab.title,
              path: '/tab',
              color: tab.fields.text_color,
              bg: tab.fields.background_color,
              alternate_color: tab.fields.alternate_color,
              content: tab.fields.content,
              featured_links: tab.featured_links,
              featured_blocks: tab.featured_blocks,
              menu_categories: tab.sticky_menu_categories,
              main_link: tab.fields.link
            });
            if (tab.sticky_menu_categories)
              stickyMenu.push(tab.sticky_menu_categories);
            else
              stickyMenu.push([]);
          });
          setTabs(tabs);
          setStickyLinks(stickyMenu);
          setSubmenuLinks(stickyLinks[0]);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getTopMenu = () => {
    let data = {};
    axios.get(`${apiHost}/nav/get/top_menu`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          console.log(data);
          setTopLinks(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getVariables = () => {
    let data = [];
    axios.get(`${apiHost}/variables/get/global`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          console.log(data);
          setVariables(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  let typebotCommands = null;

  const handleTabsChange = (index) => {
    setTabIndex(index)
    if (index >= 0) {
      if (stickyLinks[index]) {
        setSubmenuLinks(stickyLinks[index]);
      }
    }
  }

  const handleAccordionChange = (index) => {
    if (index >= 0) {
      if (stickyLinks[index]) {
        setSubmenuLinks(stickyLinks[index]);
      }
    }
  }

  function initTypebot() {
    typebotCommands = initBubble({
      url: "https://bot.happyvalley.com/hvab",
      proactiveMessage: {
        avatarUrl:
          "https://api.happyvalley.com/app/cms/assets/images/HV+Badge_CMYK+Color+3.svg",
        textContent: "We have a question for you!",
        delay: 3000,
      },
      button: { color: "#25265D" },
    });
  }

  const resetSearch = (e, path = '') => {
    document.getElementById("searchValue").value = "";
    setDestinations([]);
    history.push("/d/" + path);
    history.go(0);
  };

  const resetSearchPage = (e, path = '') => {
    document.getElementById("searchValue").value = "";
    setPages([]);
    history.push("/" + path);
    history.go(0);
  };

  const resetSearchCategory = (e, path = '') => {
    document.getElementById("searchValue").value = "";
    setCategories([]);
    history.push("/c/" + path);
    history.go(0);
  };

  const onSearchChange = (e) => {
    if (e.target.value == '' || e.target.value.length <= 3) {
      setDestinations([]);
      setCategories([]);
      setPages([]);
    } else {
      let values = {};
      values.name = e.target.value;
      getDestinations(values);

      values = {};
      values.q = e.target.value;
      getPages(values);
      getCategories(values);
    }
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(onSearchChange, 300)
    , [setDestinations]);

  const getDestinations = (values = null) => {
    let data = [];
    setDestinations([]);
    setIsSearching(true);
    axios.post(`${apiHost}/destinations/search`, values)
      .then(res => {
        data = res.data;
        if (res.status == 200 && data.length > 0) {
          setDestinations(data);
        }
        setIsSearching(false);
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getCategories = (values = null) => {
    let data = [];
    setCategories([]);
    setIsSearching(true);
    axios.post(`${apiHost}/destinations/categories/search`, values)
      .then(res => {
        data = res.data;
        if (res.status == 200 && data.length > 0) {
          setCategories(data);
        }
        setIsSearching(false);
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const resetSearchList = () => {
    document.getElementById("searchValueMobile").value = "";
    setDestinations([]);
    setPages([]);
    setCategories([]);
  };

  const getPages = (values = null) => {
    let data = [];
    setPages([]);
    setIsSearching(true);
    axios.post(`${apiHost}/pages/search`, values)
      .then(res => {
        data = res.data;
        if (res.status == 200 && data.length > 0) {
          setPages(data);
        }
        setIsSearching(false);
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  useEffect(() => {
    analytics(window, document, 'script', 'dataLayer', 'GTM-N2CMRMN');
    analytics(window, document, 'script', 'dataLayer', 'AW-1001544441');

    setTabIndex(-1);
    getNavigation();
    getTopMenu();
    getVariables();

    if (routeLocation.pathname != "profile") {
      initTypebot();
    }

    /*
    const interval = setInterval(() => {
      getNavigation();
    }, 30000);
    */

    //return () => clearInterval(interval);
    return () => {
      debouncedChangeHandler.cancel();
    }
  }, [routeLocation]);

  return (
    <>
      <Box bg="#8A8AE5" display={{ base: 'none', md: 'block' }}>
        <Grid templateColumns='repeat(4, 1fr)'>
          <GridItem colSpan={3}>
            <Flex
              alignItems={"center"}
              justifyContent={"right"}
              w={["95%", "100%"]}
              mx="auto"
              pt={2}
              pb={1}
              fontSize={{ base: '1rem', md: '0.8rem', lg: '1rem' }}
            >
              {topLinks.map((link, index) => (
                <Box key={`tl-${index}`} color="white" mr={1}>
                  <Link
                    key={index}
                    name={link.title}
                    href={link.entry.url}
                    fontWeight={'bold'}
                    color={'white'}
                    _hover={{ color: 'blue.100' }}
                    whiteSpace={'nowrap'}
                    onClick={() => { setTabIndex(-1); }}
                  >{link.title}</Link> • </Box>
              ))}
              {!authContext.isAuth ? (
                <Box>
                  <Link
                    href={'/login'}
                    fontWeight={'bold'}
                    color={'white'}
                    _hover={{ color: 'blue.100' }}
                    whiteSpace={'nowrap'}
                    bg="#F26624"
                    marginTop="20px"
                    borderTopStartRadius={"0.375rem"}
                    borderBottomStartRadius={"0.375rem"}
                    borderTopEndRadius={"0.375rem"}
                    borderBottomEndRadius={"0.375rem"}
                    p={2}
                    mr={2}
                  >Sign In</Link>
                  <Link
                    href={'/register'}
                    fontWeight={'bold'}
                    color={'white'}
                    _hover={{ color: 'blue.100' }}
                    whiteSpace={'nowrap'}
                    bg="#F26624"
                    marginTop="20px"
                    borderTopStartRadius={"0.375rem"}
                    borderBottomStartRadius={"0.375rem"}
                    borderTopEndRadius={"0.375rem"}
                    borderBottomEndRadius={"0.375rem"}
                    p={2}
                    mr={2}
                  >Sign Up</Link>
                </Box>
              ) : (
                  <Box>
                    <Link
                      href={'/profile'}
                      fontWeight={'bold'}
                      color={'white'}
                      _hover={{ color: 'blue.100' }}
                      whiteSpace={'nowrap'}
                      bg="#F26624"
                      marginTop="20px"
                      borderTopStartRadius={"0.375rem"}
                      borderBottomStartRadius={"0.375rem"}
                      borderTopEndRadius={"0.375rem"}
                      borderBottomEndRadius={"0.375rem"}
                      p={2}
                      mr={2}
                    >My HVAB Profile</Link>
                  </Box>
                )}
            </Flex>
          </GridItem>
          <GridItem colSpan={1} bg="#fff">
            <Input id="searchValue" type="search" placeholder="SEARCH" _placeholder={{ color: "#19325B" }} color="#19325B" border="0" borderRadius="0" onChange={debouncedChangeHandler} autoFocus={false} />
            <Box>
              <Box position="absolute" right="0" zIndex="9999999" maxHeight="100vh" overflow="auto" w={"450px"} marginLeft="-20px">
                <List>
                  {isSearching ? (
                    <ListItem bg="#8A8BE5" p={1} pr={2} pl={2} w="100%">
                      <Box bg="white" fontSize="1rem" p={2} w="100%">
                        <Skeleton h={"50px"} />
                      </Box>
                    </ListItem>
                  ) : null}
                  {destinations.map((destination, index) => (
                    <ListItem key={`sr-${index}`} bg="#8A8BE5" p={1} pr={2} pl={2} w="100%">
                      {destination ? (
                      <Link onClick={(e) => { resetSearch(e, destination.slug) }} w="100%" _hover={{ opacity: 0.8 }}>
                        <Box bg="white" fontSize="1rem" p={2} w="100%">
                          {destination.name}<br /><small>Destination - {destination.city} - {destination.zip}</small>
                        </Box>
                      </Link>
                      ) : null}
                    </ListItem>
                  ))}
                  {categories.map((page, index) => (
                    <ListItem key={`srdcat-${index}`} bg="#8A8BE5" p={1} pr={2} pl={2} w="100%">
                      {page ? (
                      <Link onClick={(e) => { resetSearchCategory(e, page.slug) }} w="100%" _hover={{ opacity: 0.8 }}>
                        <Box bg="white" fontSize="1rem" p={2} w="100%">
                          {page.name}<br /><small>Destinations</small>
                        </Box>
                      </Link>
                      ) : null}
                    </ListItem>
                    
                  ))}
                  {pages.map((page, index) => (
                    <ListItem key={`srp-${index}`} bg="#8A8BE5" p={1} pr={2} pl={2} w="100%">
                      {page ? (
                      <Link onClick={(e) => { resetSearchPage(e, page.link ) }} w="100%" _hover={{ opacity: 0.8 }}>
                        <Box bg="white" fontSize="1rem" p={2} w="100%">
                          {page.title}<br /><small>HappyValley.com</small>
                        </Box>
                      </Link>
                      ) : null}
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </GridItem>
        </Grid>
        <Container maxW={'5xl'}>

        </Container>
      </Box>
      <Box px={4} bg={variables.nav_bar_color} height={{ base: "auto", md: "117px" }} sx={{
        '@media (max-width: 48em)': {
          position: '-webkit-sticky', 'z-index': 1100, /* Safari */ position: 'sticky', top: '0'
        },
      }}>
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          w={["95%", "100%"]}
          maxW={1200}
          mx="auto"
        >
          <Flex direction="row" width="100%">
            <Box minWidth="100px">
              <Center>
                <Link
                  as={Link}
                  size={"sm"}
                  href={"/"}
                  src={HeaderLogo}
                >
                  <div className="header-logo">
                    <Image src={HeaderLogo} width="110px" alt={'logo image'} mt="0px" mb="0px" />
                  </div>
                </Link>
              </Center>
            </Box>
            <Spacer />
            <Box display={{ base: "none", md: "inherit" }} w="100%" pos="relative">
              <Center w="100%">
                <Menu>
                  {tabs.map((tab, index) => (
                    <MenuItem key={`mi-${index}`} item={tab.name} bg={tab.bg} color={tab.color} main_link={tab.main_link} cats={tab.menu_categories.length}>
                      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={0} w="100%">
                        <Box minHeight="200px" bg="white" p={5}>
                          {tab.content !== null && (
                            <Box>
                              {parser(tab.content)}
                            </Box>
                          )}

                          <VStack
                            as={"nav"}
                            spacing={4}
                            mt={'5px'}
                            alignItems={'left'}
                            overflowX="hidden"
                          >
                            {tab.menu_categories.map((link, index) => (
                              <Link
                                key={`ns-${index}`}
                                name={link.name}
                                href={`/c/${link.slug}`}
                                className="nav-link"
                                fontWeight="600"
                                color={'#17335B'}
                                _hover={{ color: 'blue.500' }}
                              >{link.name}</Link>
                            ))}
                            {tab.featured_links && tab.featured_links.map((link, index) => (
                              <Link
                                key={`ntf-${index}`}
                                name={link.title}
                                href={link.link}
                                className="nav-link"
                                fontWeight="600"
                                color={'#17335B'}
                                _hover={{ color: 'blue.500' }}
                                display="none"
                              >{link.title}</Link>
                            ))}
                          </VStack>
                        </Box>
                        <Box>
                          {tab.featured_blocks && tab.featured_blocks.map((block, index) => (
                            index % 2 ? (
                              <Box key={`ntfb-${block.id}`} p="0 0 0 30px" bg={tab.alternate_color}>
                                <Grid templateColumns='repeat(6, 1fr)' gap={3}>
                                  <GridItem colSpan={4} className="nav-feature">
                                    <Link className="subtitle" href={block.fields.link}>
                                      {block.fields.subtitle}
                                    </Link>
                                    <Link className="title" href={block.fields.link}>
                                      {block.title}
                                    </Link>
                                    <Link href={block.fields.link} className="nav-featured-link">{block.fields.button_text}</Link>
                                  </GridItem>
                                  <GridItem colSpan={2}>
                                    <Link href={block.fields.link}>
                                      <Image src={block.img} padding="5px" />
                                    </Link>
                                  </GridItem>
                                </Grid>
                              </Box>
                            ) : (
                                <Box key={`ntfb-${block.id}`} p="0 0 0 30px" bg={tab.bg}>
                                  <Grid templateColumns='repeat(6, 1fr)' gap={3}>
                                    <GridItem colSpan={4} className="nav-feature">
                                      <Link className="subtitle" href={block.fields.link}>
                                        {block.fields.subtitle}
                                      </Link>
                                      <Link className="title" href={block.fields.link}>
                                        {block.title}
                                      </Link>
                                      <Link href={block.fields.link} className="nav-featured-link">{block.fields.button_text}</Link>
                                    </GridItem>
                                    <GridItem colSpan={2}>
                                      <Link href={block.fields.link}>
                                        <Image src={block.img} padding="5px" />
                                      </Link>
                                    </GridItem>
                                  </Grid>
                                </Box>
                              )
                          ))}
                        </Box>
                      </SimpleGrid>
                    </MenuItem>
                  ))}
                </Menu>
              </Center>
            </Box>
            <Box display={{ base: "inherit", md: "none" }} overflow="hidden" mr="20px">
              <HStack
                as={"nav"}
                spacing={4}
              >
                {!authContext.isAuth ? (
                  <Box>
                    <Link
                      href={'/login'}
                      fontWeight={'bold'}
                      fontSize="14px"
                      color={'white'}
                      _hover={{ color: 'blue.100' }}
                      whiteSpace={'nowrap'}
                      bg="#F26624"
                      marginTop="20px"
                      borderTopStartRadius={"0.375rem"}
                      borderBottomStartRadius={"0.375rem"}
                      borderTopEndRadius={"0.375rem"}
                      borderBottomEndRadius={"0.375rem"}
                      p={2}
                      mr={5}
                    >Sign In</Link>
                    <Link
                      href={'/register'}
                      fontWeight={'bold'}
                      fontSize="14px"
                      color={'white'}
                      _hover={{ color: 'blue.100' }}
                      whiteSpace={'nowrap'}
                      bg="#F26624"
                      marginTop="20px"
                      borderTopStartRadius={"0.375rem"}
                      borderBottomStartRadius={"0.375rem"}
                      borderTopEndRadius={"0.375rem"}
                      borderBottomEndRadius={"0.375rem"}
                      p={2}
                      mr={5}
                    >Sign Up</Link>
                  </Box>
                ) : (
                    <Box>
                      <Link
                        href={'/profile'}
                        fontWeight={'bold'}
                        fontSize="14px"
                        color={'white'}
                        _hover={{ color: 'blue.100' }}
                        whiteSpace={'nowrap'}
                        bg="#F26624"
                        marginTop="20px"
                        borderTopStartRadius={"0.375rem"}
                        borderBottomStartRadius={"0.375rem"}
                        borderTopEndRadius={"0.375rem"}
                        borderBottomEndRadius={"0.375rem"}
                        p={2}
                        mr={5}
                      >My HVAB Profile</Link>
                    </Box>
                  )}
              </HStack>
            </Box>
            <Spacer />
            <IconButton
              size={"md"}
              icon={isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
              aria-label={"Open Menu"}
              display={["inherit", "inherit", "none"]}
              onClick={isOpen ? onClose : onOpen}
              bg={'white'}
              mt={'23px'}
              mb={'23px'}
            />
          </Flex>
        </Flex>

        {isOpen ? (
          <Box
            p={4}
            mt={4}
            w={"100%"}
            maxW={800}
            h={"86dvh"}
            display={["inherit", "inherit", "none"]}
            bg="white"
            overflowY="auto"
          >
            <Stack as={"nav"} spacing={4}>
              <Input id="searchValueMobile" type="search" placeholder="SEARCH" _placeholder={{ color: "#19325B" }} color="#19325B" borderRadius="0" onChange={debouncedChangeHandler} autoFocus={false} />

              {destinations.length > 0 || categories.length > 0 || pages.length > 0 ? (
                <List maxHeight="400px" overflow="auto" bg="#8A8BE5" pt={1} pb={1}>
                  {isSearching ? (
                    <ListItem p={1} pr={2} pl={2} w="100%">
                      <Box bg="white" fontSize="1rem" p={2} w="100%">
                        <Skeleton h={"50px"} />
                      </Box>
                    </ListItem>
                  ) : null}
                  {destinations.map((destination, index) => (
                    <ListItem key={`srm-${index}`} p={1} pr={2} pl={2} w="100%">
                      {destination ? (
                      <Link onClick={(e) => { resetSearch(e, destination.slug) }} w="100%" _hover={{ opacity: 0.8 }}>
                        <Box bg="white" fontSize="1rem" p={2} w="100%">
                          {destination.name}<br /><small>Destination - {destination.city} - {destination.zip}</small>
                        </Box>
                      </Link>
                      ) : null}
                    </ListItem>
                  ))}
                  {categories.map((page, index) => (
                    <ListItem key={`srdcm-${index}`} p={1} pr={2} pl={2} w="100%">
                      {page ? (
                      <Link onClick={(e) => { resetSearchCategory(e, page.slug) }} w="100%" _hover={{ opacity: 0.8 }}>
                        <Box bg="white" fontSize="1rem" p={2} w="100%">
                          {page.name}<br /><small>Destinations</small>
                        </Box>
                      </Link>
                      ) : null}
                    </ListItem>
                  ))}
                  {pages.map((page, index) => (
                    <ListItem key={`srpm-${index}`} p={1} pr={2} pl={2} w="100%">
                      {page ? (
                      <Link onClick={(e) => { resetSearchPage(e, page.link) }} w="100%" _hover={{ opacity: 0.8 }}>
                        <Box bg="white" fontSize="1rem" p={2} w="100%">
                          {page.title}<br /><small>HappyValley.com</small>
                        </Box>
                      </Link>
                      ) : null}
                    </ListItem>
                  ))}
                </List>
              ) : null}
              {destinations.length > 0 || categories.length > 0 || pages.length > 0 ? (
                <Box>
                  <Button onClick={resetSearchList} float="right">Reset Search</Button>
                </Box>
              ) : null}

              <Accordion allowToggle onChange={handleAccordionChange}>
                {tabs.map((tab, index) => (
                  <AccordionItem key={`nac-${index}`} bg={tab.bg}>
                    <h2>
                      <AccordionButton>
                        <Box flex='1' textAlign='left' color="#19325B" fontWeight="700">
                          {tab.name}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} bg={tab.bg} height={'50dvh'} overflowY="auto">

                      <VStack bg="white" p={5} mb={5} textAlign="center">
                        {tab.featured_links && tab.featured_links.map((link, index) => (
                          <Link
                            key={`ntf-${index}`}
                            name={link.title}
                            href={link.link}
                            className="nav-link"
                            fontSize="1.35em"
                            color={tab.color}
                            _hover={{ color: 'blue.500' }}
                            onClick={() => { setTabIndex(-1); }}
                          >{link.title}</Link>
                        ))}

                        {submenuLinks.map((link, index) => (
                          <Link
                            key={`ns-${index}`}
                            name={link.name}
                            href={`/c/${link.slug}`}
                            className="nav-link"
                            fontSize="1em"
                            _hover={{ color: 'blue.500' }}
                            onClick={() => { setTabIndex(-1); }}
                          >{link.name}</Link>
                        ))}
                      </VStack>

                      {tab.featured_blocks && tab.featured_blocks.map((block, index) => (
                        index % 2 ? (
                          <Box key={`ntfbm-${block.id}`} pl={{ base: "10px", md: "30px" }} bg={tab.alternate_color}>
                            <Grid templateColumns='repeat(6, 1fr)' gap={3}>
                              <GridItem colSpan={3} className="nav-feature">
                                <Box pt={{ base: "25px", md: "0px" }}></Box>
                                {block.fields.subtitle ? (
                                  <Link className="subtitle" href={block.fields.link}>
                                    {block.fields.subtitle}
                                  </Link>
                                ) : null}
                                <Link className="title" href={block.fields.link}>
                                  {block.title}
                                </Link>
                                <Link href={block.fields.link} className="nav-featured-link">{block.fields.button_text}</Link>
                              </GridItem>
                              <GridItem colSpan={3}>
                                <Link href={block.fields.link}>
                                  <Image src={block.img} padding="5px" />
                                </Link>
                              </GridItem>
                            </Grid>
                          </Box>
                        ) : (
                            <Box key={`ntfbm-${block.id}`} pl={{ base: "10px", md: "30px" }} bg={tab.bg}>
                              <Grid templateColumns='repeat(6, 1fr)' gap={3}>
                                <GridItem colSpan={3} className="nav-feature">
                                  <Box pt={{ base: "25px", md: "0px" }}></Box>
                                  {block.fields.subtitle ? (
                                    <Link className="subtitle" href={block.fields.link}>
                                      {block.fields.subtitle}
                                    </Link>
                                  ) : null}
                                  <Link className="title" href={block.fields.link}>
                                    {block.title}
                                  </Link>
                                  <Link href={block.fields.link} className="nav-featured-link">{block.fields.button_text}</Link>
                                </GridItem>
                                <GridItem colSpan={3}>
                                  <Link href={block.fields.link}>
                                    <Image src={block.img} padding="5px" />
                                  </Link>
                                </GridItem>
                              </Grid>
                            </Box>
                          )
                      ))}

                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Stack>
          </Box>
        ) : null}

      </Box>
    </>
  );
}

export default TopNav;
