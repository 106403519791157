import { Box, Container, SimpleGrid, Stack } from "@chakra-ui/react";
import React, { useState, useEffect} from "react";

const FcAdBlock = ({ page, block }) => {

    useEffect(() => {
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549820308-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549850746-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725549878192-0') });
    }, []);

    return (
        <Box className="signals-wrapper" pt={5} pb={5}>
            <Container maxW={'8xl'}>
                <Stack direction={{ base: 'column', lg: 'row' }}>
                    <Box w={{ base: '100%', lg: '65%' }} >
                        <div id='div-gpt-ad-1725549820308-0'>
                        </div>
                    </Box>
                    <Box w={{ base: '100%', lg: '32%' }}>
                        <div id='div-gpt-ad-1725549850746-0'>
                        </div>
                        <div id='div-gpt-ad-1725549878192-0'>
                        </div>
                    </Box>
                </Stack>
            </Container>
        </Box>
    )
};

export default FcAdBlock;