import { Box, Container, Flex, Grid, GridItem, Heading, Link, SimpleGrid, Spacer, Text, Image, Tabs, Center, TabList, TabPanels, TabPanel, Tab, Button } from "@chakra-ui/react";
import React from "react";
import parser from "html-react-parser";
import Slider from "react-slick";

const SlidesBlock = ({ page, block }) => {

    const settingsSlick = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 8000,
    };

    return (
        <Box>
            {block.title && block.title != '' && block.entry ? (
                <Box textAlign="left" pt={5}>
                    <div class="experiences-header">
                        <Heading
                            as={'h2'}
                            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                            textAlign={{ base: 'center', md: 'center' }}
                            lineHeight={'110%'}>
                            {block.title}
                        </Heading>
                    </div>
                </Box>
            ) : null}
            <Slider {...settingsSlick} className="guide-slider">

                {block.entry.slides?.map((slide, index) => (
                    <div>
                        <SimpleGrid columns={{ base: 1, md: 2 }}>
                            {slide.img != null && (
                                <Box backgroundImage={`url(${slide.img})`} backgroundPosition="center center" backgroundRepeat="no-repeat" backgroundSize="cover" minHeight="400px">

                                </Box>
                            )}
                            <Box pt="50px" pb="50px" pl={{ base: '20px', md: "100px" }} pr={{ base: '20px', md: "100px" }} bg="white" textAlign="left" mr={{ base: 0, md: '50px' }}>
                                {slide.top_title != null && (
                                    <Button as={Link} href={slide.top_link} bg="#F26624" color="white" fontSize="12px" marginTop="20px" letterSpacing="2px" borderTopStartRadius={20} borderBottomStartRadius={20} borderTopEndRadius={20} borderBottomEndRadius={20}>{slide.top_title}</Button>
                                )}
                                {slide.title != null && (
                                    <Heading fontSize="7xl" textAlign="left" lineHeight={0.8} color="#5A729A" mt={5}>
                                        {slide.title}
                                    </Heading>
                                )}
                                {slide.content != null && (
                                    <Box>{parser(slide.content)}</Box>
                                )}
                                {slide.link != null && (
                                    <Link href={slide.link}>{slide.button_text}</Link>
                                )}
                            </Box>
                        </SimpleGrid>
                    </div>
                ))}

            </Slider>

        </Box>
    )
};

export default SlidesBlock;