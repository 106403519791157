import { Box, Grid, GridItem, Heading, Link, SimpleGrid, Text, Container } from "@chakra-ui/react";
import React from "react";
import parser from "html-react-parser";

const ContentBlock = ({ page, block }) => {

    return (
        <Box className="category-wrapper" pt={10} pb={10} backgroundImage={`url(${block.img})`} backgroundPosition="center center" backgroundSize="cover">
            <Container maxW={'5xl'}>
                <div class="experiences-header">
                    <Heading
                        as={'h2'}
                        fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                        textAlign={{ base: 'center', md: 'center' }}
                        lineHeight={'110%'}
                        color="#17325B">
                        {block.title}
                    </Heading>
                </div>
                {block.entry.content != null ? (
                    <Box className="content-block" bg="white" p={10} roundedBottomRight={20}>
                        {parser(block.entry.content)}
                    </Box>
                ) : null}
            </Container>
        </Box>
    )
};

export default ContentBlock;