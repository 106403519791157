import { Input, InputProps, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

const FileInput = (props: InputProps) => {
    const styles = useMultiStyleConfig("Button", { variant: "outline", size: "sm" });

    return (
        <Input
            type="file"
            sx={{
                "::file-selector-button": {
                    border: "none",
                    outline: "none",
                    mr: 2,
                    mt: "2px",
                    cursor: "pointer",
                    ...styles,
                },
            }}
            {...props}
        />
    );
};

export default FileInput;