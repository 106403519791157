import axios from "axios";

const Authenticate = async (url, token) => {
  let auth = { user: {} };
  await axios
    .get(`${url}/api/user`, {
      headers: { "Authorization": "Bearer " + token }
    })
    .then(response => {
      auth = { user: response.data };
      return auth;
    });
  return auth;
};

export default Authenticate;
