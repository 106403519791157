import React, { useEffect, useState, createContext } from "react";
import Authenticate from "../utils/Auth/Authenticate";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { apiHost } from "../utils/Cons/Constants";

const AuthContext = createContext();

const AuthProvider = props => {
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState({});
  const [token, setToken] = useState({});
  const toast = useToast();

  const authenticateUser = () => {
    let storedToken = localStorage.getItem("token");
    if (storedToken && storedToken != '') {
      setIsAuth(true);
    } else {
      Authenticate(apiHost, storedToken)
        .then(response => {
          if (response.user.id != null) {
            setIsAuth(true);
            setUser(response.user);
          } else {
            setIsAuth(false);
          }
        })
        .catch(err => console.log(err));
    }
  };

  useEffect(() => {
    authenticateUser();
  }, []);

  const updateUser = (user, file, history, action, onEditSave) => {
    let token = localStorage.getItem("token");
    const url = `${apiHost}/api/profile/update`;

    const userData = { 
      company_name: user.company_name,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      new_image: user.new_image,
      phone: user.phoneNumber,
      city: user.city,
      zip_code: user.zip,
      state: user.state,
      country: user.country,
      company_address: user.company_address,
      company_city: user.company_city,
      company_zip: user.company_zip,
      company_content: user.company_content,
      company_image: user.company_image,
      website: user.website,
      social_link: user.social_link,
      calendar: user.calendar,
      eat: user.eat,
      stay: user.stay,
      explore: user.explore
    }

    axios
      .post(url, userData, {
        headers: { "Authorization": "Bearer " + token }
      })
      .then(response => {
        console.log(response);
        setUser(response.data);
        onEditSave();
        showToast("Profile updated successfully", "success");
      })
      .catch(err => console.log(err));
  };

  const registerUser = (user, history, action, onRegister = null) => {
    axios
      .post(
        `${apiHost}/api/register`,
        user,
      )
      .then(response => {
        let message = "",
          status = "";
        if (response.data != undefined && response.data.status == 'OK') {
            localStorage.removeItem('token');
            setIsAuth(false);
            history.push("/login");
            message = "Please check your email to activate your account!";
          status = "success";
        } else {
          message = "Registration error, could be you already registered with that email or something else";
          status = "error";
        }
        showToast(message, status);
      })
      .catch(error => {
        showToast(error.toString(), 'error');
      });
  };

  const logout = (history) => {
    let token = localStorage.getItem("token");
    axios
      .post(`${apiHost}/api/logout`, {}, {
        headers: {
          "Authorization": "Bearer " + token
        }
      })
      .then(resp => {
        localStorage.removeItem("token");
        setIsAuth(false);
        setUser({});
        showToast("Logged out successfully", "success");
        // history.push("/");
        window.location.href = "/login";
      })
      .catch(err => {
        localStorage.removeItem("token");
        window.location.href = "/login";
      });
  };

  const loginUser = (values, history) => {
    let message = "",
      status = "";

    axios
      .post(`${apiHost}/api/login`, { "username": values.email, "password": values.password })
      .then(response => {
        console.log(response);
        if (response.data.access_token != undefined) {
          localStorage.setItem("token", response.data.access_token);
          setIsAuth(true);
          //setUser(response.data.user);
          setToken(response.data.access_token);
          history.push("/profile");
          message = "Logged in successfully";
          status = "success";
        } else {
          message = "Something went wrong";
          status = "error";
        }
        showToast(message, status);
      })
      .catch(error => {
        if (error) {
          message = "Wrong password or account is not activated";
          status = "error";
        }
        showToast(message, status);
      });
  };

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      email: "Notification",
      description: message,
      status: status,
      duration: 6000,
      isClosable: true
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth: isAuth,
        user: user,
        token: token,
        authenticateUser: authenticateUser,
        updateUser: updateUser,
        registerUser: registerUser,
        loginUser: loginUser,
        logout: logout
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
