import * as React from "react";
import {
  Heading,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Button,
  Input,
  Textarea,
  useColorModeValue,
  FormErrorMessage,
  useToast
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, recaptchaKey } from "../utils/Cons/Constants";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { useCallback, useEffect, useState } from "react";
import { Field, Formik } from "formik";
import axios from "axios";

const Support = () => {
  const toast = useToast();
  const titleColor = useColorModeValue("red.300", "red.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validateMessage(value) {
    let error;
    if (!value) {
      error = "Message is required";
    }
    return error;
  }

  const messageSupport = (values) => {
    axios.post(`${apiHost}/messages/support`, values)
      .then(res => {
        if (res.statusText == '' || res.statusText == 'OK') {
          showToast('Avalbl support received your message!', 'success');
          document.getElementById('message').value = '';
        }
        return res;
      })
      .catch(err => showToast(err.toString(), "error"));
  }

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  return (
    <PageSlideFade>

      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
          <Flex
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "100px", sm: "30px" }}
            bg={bgColor}
            boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Need Help?
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Enter your email and message
            </Text>
            <GoogleReCaptchaProvider
              reCaptchaKey={recaptchaKey}
              scriptProps={{
                async: false,
                defer: false
              }}
            >
              <Formik
                enableReinitialize
                initialValues={{ email: "", message: "" }}
                onSubmit={(values, actions) => {
                  window.grecaptcha.ready(function () {
                    window.grecaptcha.execute(recaptchaKey, { action: 'submit' }).then(function (token) { 
                      messageSupport({ token: token, email: values.email, content: values.message })
                    });
                  });

                  actions.setSubmitting(false);
                }}
              >
                {({ values, handleChange, handleSubmit, isSubmitting }) => {
                  return (
                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>

                      <Field
                        name="email"
                        validate={validateEmail}
                        width={"100%"}
                      >
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.email && form.touched.email
                            }
                            mb="24px"
                          >
                            <FormLabel htmlFor="email" ms="4px" fontSize="sm" fontWeight="normal">
                              Email
                            </FormLabel>
                            <Input
                              {...field}
                              id="email"
                              borderRadius="15px"
                              fontSize="sm"
                              type="text"
                              placeholder="Your email address"
                              size="lg"
                              value={values.email}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field
                        name="message"
                        validate={validateMessage}
                        width={"100%"}
                      >
                        {({ field, form }) => (
                          <FormControl isInvalid={
                            form.errors.message && form.touched.message
                          }
                          >
                            <FormLabel htmlFor="message" ms="4px" fontSize="sm" fontWeight="normal">
                              Message
                            </FormLabel>
                            <Textarea
                              {...field}
                              id="message"
                              borderRadius="15px"
                              fontSize="sm"
                              placeholder="Your message to Avalbl Support!"
                              size="lg"
                              value={values.message}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {form.errors.message}
                            </FormErrorMessage>
                          </FormControl>

                        )}
                      </Field>

                      <Button
                        fontSize="10px"
                        type="submit"
                        bg="red.300"
                        w="100%"
                        h="45"
                        mb="20px"
                        color="white"
                        mt="20px"
                        _hover={{
                          bg: "red.200",
                        }}
                        _active={{
                          bg: "red.400",
                        }}
                        isLoading={isSubmitting}
                      >
                        SEND MESSAGE
                      </Button>

                    </form>
                  );
                }}
              </Formik>
            </GoogleReCaptchaProvider>
          </Flex>
        </Flex>
      </Flex>
    </PageSlideFade>
  );
};

export default Support;
