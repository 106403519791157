import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import React, { useState, useEffect} from "react";

const FeaturedAdBlock = ({ page, block }) => {

    useEffect(() => {
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725550021431-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725550052100-0') });
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1725550083230-0') });
    }, []);

    return (
        <Box className="signals-wrapper" pt={10} pb={10}>
            <Container maxW={'7xl'}>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 3 }} spacing={2}>
                    <Box>
                    <div id='div-gpt-ad-1725550021431-0'>
                    </div>
                    </Box>
                    <Box>
                    <div id='div-gpt-ad-1725550052100-0'>
                    </div>
                    </Box>
                    <Box>
                    <div id='div-gpt-ad-1725550083230-0'>
                    </div>
                    </Box>
                </SimpleGrid>
            </Container>
        </Box>
    )
};

export default FeaturedAdBlock;