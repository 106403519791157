import { Box, Container, Flex, Grid, GridItem, Heading, Link, SimpleGrid, Spacer, Text, Image, Tabs, Center, TabList, TabPanels, TabPanel, Tab } from "@chakra-ui/react";
import React from "react";
import parser from "html-react-parser";

const TabsBlock = ({ page, block }) => {

    let tabs = [];
    if (block.entry.tabs) {
        tabs = block.entry.tabs;
    }

    return (
        <Box>

            <Tabs variant="enclosed" w="100%" pt={5} bg="white">
                <Container maxW={'5xl'}>
                    <SimpleGrid columns={{ base: 1, md: 2 }}>
                        <Box textAlign="left" ml={5} mt={5}>
                            <Text textAlign="left"
                                fontFamily="american"
                                fontWeight={500}
                                fontSize={{ base: '2xl', sm: '3xl', md: '3xl' }}>
                                {block.title}
                            </Text>
                        </Box>
                        <Center>
                            <TabList borderBottom={0} flexDirection={{ base: "column", md: "row" }}>
                                {tabs?.map((tab, index) => (
                                    <Tab
                                        whiteSpace={'nowrap'}
                                        textTransform="uppercase"
                                        borderRadius={0}
                                        fontWeight="bold"
                                        color="#17325B"
                                        pt={0}
                                        pb={0}
                                        p={5}
                                        _selected={{ color: "#17325B", bg: "#ffffff" }}
                                    >{tab.title} <span class="vertical-divider"></span></Tab>
                                ))}
                            </TabList>
                        </Center>

                    </SimpleGrid>

                    <TabPanels>

                        {tabs?.map((tab, index) => (
                            <TabPanel textAlign="left">
                                {tab.content ? (
                                    <Box className="content-block">
                                        {parser(tab.content)}
                                    </Box>
                                ) : null}
                            </TabPanel>
                        ))}

                    </TabPanels>
                </Container>
            </Tabs>

        </Box>
    )
};

export default TabsBlock;