import { Box, Grid, GridItem, Heading, Link, SimpleGrid, Text, Container } from "@chakra-ui/react";
import React from "react";
import parser from "html-react-parser";

const ImagesBlock = ({ page, block }) => {

    let firstImage = {};
    let secondImage = {};
    let thirdImage = {};
    let fourthImage = {};
    let fifthImage = {};
    let sixthImage = {};
    if (block.entry.images) {
        if (block.entry.images[0]) {
            firstImage = block.entry.images[0];
        }
        if (block.entry.images[1]) {
            secondImage = block.entry.images[1];
        }
        if (block.entry.images[2]) {
            thirdImage = block.entry.images[2];
        }
        if (block.entry.images[3]) {
            fourthImage = block.entry.images[3];
        }
        if (block.entry.images[4]) {
            fifthImage = block.entry.images[4];
        }
        if (block.entry.images[5]) {
            sixthImage = block.entry.images[5];
        }
    }

    return (
        <Box className="category-wrapper" pt={10} backgroundImage={`url(${block.img})`} backgroundPosition="center center" backgroundSize="cover">
            <Container maxW={'6xl'}>
                <div class="experiences-header">
                    <Heading
                        as={'h2'}
                        fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                        textAlign={{ base: 'center', md: 'center' }}
                        lineHeight={'110%'}
                        color="#17325B">
                        {block.title}
                    </Heading>
                    {block.entry.content != null ? (
                        <Text className="content-block" textAlign={{ base: 'center', md: 'center' }}>
                            {parser(block.entry.content)}
                        </Text>
                    ) : null}
                </div>
                <Box pb={10}>
                    <SimpleGrid columns={{ base: 1, md: 2 }} pt={10}>
                        <Box>
                            <Link href={firstImage.link} _hover={{ opacity: 0.8 }}>
                                <Box backgroundImage={`url(${firstImage.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="520px" position="relative">
                                    <Heading textAlign="left" position="absolute" bottom="10px" left="20px" color="white" textShadow="0px 0px 5px #000" lineHeight="1em">{firstImage.title}</Heading>
                                    <Text fontSize="2xl" textAlign="left" position="absolute" bottom="10px" left="20px" color="white">{firstImage.subtitle}</Text>
                                </Box>
                            </Link>
                        </Box>
                        <Box pl={{ base: 0, md: 5}}>

                            <Link href={secondImage.link} _hover={{ opacity: 0.8 }}>
                                <Box>
                                    <Box backgroundImage={`url(${secondImage.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="250px" position="relative">
                                        <Heading textAlign="left" position="absolute" bottom="10px" left="20px" color="white" textShadow="0px 0px 5px #000" lineHeight="1em">{secondImage.title}</Heading>
                                        <Text fontSize="2xl" textAlign="left" position="absolute" bottom="10px" left="20px" color="white">{secondImage.subtitle}</Text>
                                    </Box>
                                </Box>
                            </Link>

                            <Link href={thirdImage.link} _hover={{ opacity: 0.8 }}>
                                <Box pt={5}>
                                    <Box backgroundImage={`url(${thirdImage.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="250px" position="relative">
                                        <Heading textAlign="left" position="absolute" bottom="10px" left="20px" color="white" textShadow="0px 0px 5px #000" lineHeight="1em">{thirdImage.title}</Heading>
                                        <Text fontSize="2xl" textAlign="left" position="absolute" bottom="10px" left="20px" color="white">{thirdImage.subtitle}</Text>
                                    </Box>
                                </Box>
                            </Link>

                        </Box>
                    </SimpleGrid>
                    {fourthImage.img || fifthImage.img || sixthImage.img ? (
                    <SimpleGrid columns={{ base: 1, md: 2 }} pt={10}>
                        <Box>

                            <Link href={fourthImage.link} _hover={{ opacity: 0.8 }}>
                                <Box>
                                    <Box backgroundImage={`url(${fourthImage.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="250px" position="relative">
                                        <Heading textAlign="left" position="absolute" bottom="10px" left="20px" color="white" textShadow="0px 0px 5px #000" lineHeight="1em">{fourthImage.title}</Heading>
                                        <Text fontSize="2xl" textAlign="left" position="absolute" bottom="10px" left="20px" color="white">{fourthImage.subtitle}</Text>
                                    </Box>
                                </Box>
                            </Link>

                            <Link href={fifthImage.link} _hover={{ opacity: 0.8 }}>
                                <Box pt={5}>
                                    <Box backgroundImage={`url(${fifthImage.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="250px" position="relative">
                                        <Heading textAlign="left" position="absolute" bottom="10px" left="20px" color="white" textShadow="0px 0px 5px #000" lineHeight="1em">{fifthImage.title}</Heading>
                                        <Text fontSize="2xl" textAlign="left" position="absolute" bottom="10px" left="20px" color="white">{fifthImage.subtitle}</Text>
                                    </Box>
                                </Box>
                            </Link>

                        </Box>
                        <Box pl={{ base: 0, md: 5}}>
                            <Link href={sixthImage.link} _hover={{ opacity: 0.8 }}>
                                <Box backgroundImage={`url(${sixthImage.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="520px" position="relative">
                                    <Heading textAlign="left" position="absolute" bottom="10px" left="20px" color="white" textShadow="0px 0px 5px #000" lineHeight="1em">{sixthImage.title}</Heading>
                                    <Text fontSize="2xl" textAlign="left" position="absolute" bottom="10px" left="20px" color="white">{sixthImage.subtitle}</Text>
                                </Box>
                            </Link>
                        </Box>
                    </SimpleGrid>
                    ) : null}
                </Box>
            </Container>
        </Box>
    )
};

export default ImagesBlock;