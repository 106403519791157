import { React } from "@chakra-ui/react";
import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Briston';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url('/fonts/Briston-Regular.woff2') format('woff2');
      }
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url('/fonts/Roboto-Medium.woff2') format('woff2');
      }
      @font-face {
        font-family: 'Titling Wide Bold';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url('/fonts/TitlingGothicFB Wide Bold.woff2') format('woff2');
      }
      @font-face {
        font-family: 'Titling Normal Bold';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url('/fonts/TitlingGothicFB Normal Bold.woff') format('woff');
      }
      @font-face {
        font-family: 'Titling Skyline Standard';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url('/fonts/TitlingGothicFB Skyline Standard.woff') format('woff');
      }
      @font-face {
        font-family: 'Titling Wide Standard';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url('/fonts/TitlingGothicFB Wide Standard.woff2') format('woff2');
      }
      @font-face {
        font-family: 'Titling Comp Standard';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url('/fonts/TitlingGothicFB Comp Standard.woff2') format('woff2');
      }
      @font-face {
        font-family: 'Titling Comp Medium';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url('/fonts/TitlingGothicFB Comp Medium.woff2') format('woff2');
      }
      @font-face {
        font-family: 'Titling Normal';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url('/fonts/TitlingGothicFBNormalMedium.woff2') format('woff2');
      }

      `}
  />
)

export default Fonts