import * as React from "react";
import { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Container,
  useToast,
  Flex,
  SimpleGrid,
  StackDivider,
  Icon,
  SpaceProps,
  HStack,
  Tag,
  Link
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, baseUrl } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import headerBg from "../assets/images/header-background.jpg";
import cloudsBg from "../assets/images/cloud-background.png";
import parser from "html-react-parser";
import Masonry from "react-masonry-css";
import "../style/style.css";
import { MotionBox } from "../components/motion";

const markdownTheme = {
  p: props => {
    const { children } = props;
    return (
      <Text mb={2} fontSize={'0.8em'}>
        {children}
      </Text>
    );
  },
};

const Post = ({ match }) => {
  const toast = useToast();

  const [destination, setDestination] = useState({});
  const [loading, setLoading] = useState(true);

  const breakpointColumnsObj = {
    default: 3,
    1300: 2,
    900: 1
  };

  const getExperience = () => {
    let data = {};
    axios.get(`${apiHost}/news/get/${match.params.id}`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setDestination(data);
          setLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getExperience();
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title></title>
        <meta name="description" content={null} />
      </Helmet>
      <Box className="home-header-container" backgroundImage={`url(${headerBg})`} backgroundPosition="center center" backgroundSize="cover" minHeight="60vh">
        <Container maxW={'5xl'}>
          <Stack
            textAlign={'center'}
            align={'center'}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 28 }}>
            <MotionBox
              w="100%"
              opacity="0"
              initial={{
                translateY: 180
              }}
              animate={{
                translateY: 0,
                opacity: 1,
                transition: {
                  delay: 0.4,
                  duration: 0.5
                }
              }}
            >
              <Heading
                as={'h1'}
                fontFamily="american"
                fontWeight={500}
                fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                color="white"
                lineHeight={'100%'}
                mt={{ base: 20, md: 0 }}>
                Latest In {' '}
                <Text as={'div'} fontFamily="brush" fontWeight={500}
                  fontSize={{ base: '6rem', md: '13rem' }} color="white" textShadow="2px 2px 8px rgba(0,0,0,0.5)" mt="3.5rem">
                  Happy Valley
                </Text>
              </Heading>
            </MotionBox>
          </Stack>

        </Container>
      </Box>
      <Box backgroundImage={`url(${cloudsBg})`} backgroundPosition="top center" backgroundRepeat="no-repeat" backgroundSize="fit" marginTop="-350px" paddingTop="250px">
        <Container maxW={'5xl'}>
          <Heading>{destination.title}</Heading>
          <Text>{destination.summary}</Text>
          {destination.content && (
            <Box className="content-block" pt={10} pb={10}>
              {parser(destination.content)}
            </Box>
          )}

        </Container>
      </Box>

    </PageSlideFade>
  );
};

export default Post;
