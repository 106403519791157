import { Box, Container, Flex, Grid, GridItem, Heading, Link, SimpleGrid, Spacer, Text, Image, Button } from "@chakra-ui/react";
import React from "react";
import parser from "html-react-parser";

const MeetingsBlock = ({ page, block }) => {

    let experiences = [];
    if (block.entry.experiences) {
        experiences = block.entry.experiences;
    }

    let firstImage = {};
    let secondImage = {};
    let thirdImage = {};
    if (block.entry.images) {
        firstImage = block.entry.images[0];
        if (block.entry.images[1]) {
            secondImage = block.entry.images[1];
        }
        if (block.entry.images[2]) {
            thirdImage = block.entry.images[2];
        }
    }

    return (
        <Box backgroundImage={`url(${block.img})`} backgroundPosition="center center" backgroundSize="cover">
            <Box pt={0} pb={10}>
                <Container maxW={'6xl'}>
                    <Box bg="#BDEDDF" p={4} borderBottomRadius={20} minHeight="200px">
                        <Box bg="#FFFFFF" borderBottomRadius={10} minHeight="200px">
                            <Grid templateColumns='repeat(4, 1fr)'>

                                {experiences?.map((slide, index) => (
                                    <GridItem colSpan={{ base: 2, md: 1 }}>
                                        {index % 2 == 0 ? (
                                            <Box>
                                                <div class="dark-top">
                                                    <Box textAlign="left" bg="#17325B" height="180px" p={5}>
                                                        <Heading
                                                            as={'h3'}
                                                            fontSize={{ base: '1xl', sm: '1xl', md: '2xl' }}
                                                            textAlign="left"
                                                            lineHeight="80%"
                                                            color="#BDEDDF">
                                                            {slide.top_title}
                                                        </Heading>
                                                        <Heading
                                                            as={'h4'}
                                                            fontSize={'1.25rem'}
                                                            textAlign="left"
                                                            textTransform="uppercase"
                                                            lineHeight="80%"
                                                            color="#88B597">
                                                            {slide.title}
                                                        </Heading>
                                                        <Button as={Link} href={slide.link} size="small">{slide.button_text}</Button>
                                                    </Box>
                                                </div>
                                                <Box backgroundImage={`url(${slide.img})`} backgroundPosition="center center" backgroundSize="cover" height="180px">

                                                </Box>
                                            </Box>
                                        ) : (
                                                <Box>
                                                    <Box backgroundImage={`url(${slide.img})`} backgroundPosition="center center" backgroundSize="cover" height="180px">

                                                    </Box>
                                                    <div class="light-bottom">
                                                        <Box textAlign="left" bg="#3C5A7E" height="180px" p={5}>
                                                            <Heading
                                                                as={'h3'}
                                                                fontSize={{ base: '1xl', sm: '1xl', md: '2xl' }}
                                                                textAlign="left"
                                                                lineHeight="80%"
                                                                color="#BDEDDF">
                                                                {slide.top_title}
                                                            </Heading>
                                                            <Heading
                                                                as={'h4'}
                                                                fontSize={'1.25rem'}
                                                                textAlign="left"
                                                                textTransform="uppercase"
                                                                lineHeight="80%"
                                                                color="#88B597">
                                                                {slide.title}
                                                            </Heading>
                                                            <Button as={Link} href={slide.link} size="small">{slide.button_text}</Button>
                                                        </Box>
                                                    </div>
                                                </Box>
                                            )}
                                    </GridItem>
                                ))}

                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </Box>


            <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box pt={5} pr={{ base: 0, md: 5 }}>
                    {firstImage ? (
                        <Link href={firstImage.link} _hover={{ opacity: 0.8 }}>
                            <Box backgroundImage={`url(${firstImage.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="250px" position="relative">
                                <Heading textAlign="left" position="absolute" bottom="30px" left="20px" color="white">{firstImage.title}</Heading>
                                <Text fontSize="2xl" textAlign="left" position="absolute" bottom="10px" left="20px" color="white">{firstImage.subtitle}</Text>
                            </Box>
                        </Link>
                    ) : null}
                    <Grid templateColumns='repeat(5, 1fr)'>
                        {secondImage ? (
                            <GridItem colSpan={3}>
                                <Link href={secondImage.link} _hover={{ opacity: 0.8 }}>
                                    <Box pt={5} pr={5}>
                                        <Box backgroundImage={`url(${secondImage.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="250px" position="relative">
                                            <Heading textAlign="left" position="absolute" bottom="30px" left="20px" color="white">{secondImage.title}</Heading>
                                            <Text fontSize="2xl" textAlign="left" position="absolute" bottom="10px" left="20px" color="white">{secondImage.subtitle}</Text>
                                        </Box>
                                    </Box>
                                </Link>
                            </GridItem>
                        ) : null}
                        {thirdImage ? (
                            <GridItem colSpan={2}>
                                <Link href={thirdImage.link} _hover={{ opacity: 0.8 }}>
                                    <Box pt={5}>
                                        <Box backgroundImage={`url(${thirdImage.img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="250px" position="relative">
                                            <Heading textAlign="left" position="absolute" bottom="30px" left="20px" color="white">{thirdImage.title}</Heading>
                                            <Text fontSize="2xl" textAlign="left" position="absolute" bottom="10px" left="20px" color="white">{thirdImage.subtitle}</Text>
                                        </Box>
                                    </Box>
                                </Link>
                            </GridItem>
                        ) : null}
                    </Grid>
                </Box>
                <Box>
                    {block.entry && block.entry.embed ? (
                        <Box backgroundImage={`url(${page.header_image_url})`} backgroundPosition="center center" backgroundSize="cover" minHeight="560px">
                            {parser(block.entry.embed)}
                        </Box>
                    ) : null}
                </Box>
            </SimpleGrid>

            <Box pt={10} pb={0}>
                <Container maxW={'6xl'}>
                    <Box bg="#BDEDDF" p={4} borderTopRadius={20} minHeight="200px">
                        <Box bg="#FFFFFF" borderTopRadius={10} minHeight="200px">
                            <Grid templateColumns='repeat(5, 1fr)'>

                                <GridItem colSpan={{ base: 5, md: 3 }}>
                                    <Box bg="#17325B" minHeight="200px" p={5}>
                                        {block.entry && block.entry.content ? (
                                            <Text fontSize="3xl" textAlign="left" color="white">
                                                {parser(block.entry.content)}
                                            </Text>
                                        ) : null}
                                    </Box>
                                </GridItem>

                                <GridItem colSpan={{ base: 5, md: 2 }}>
                                    <Box p={5} className="content-block">
                                        {block.entry && block.entry.sidebar_content ? (
                                            <Text fontSize="3xl" textAlign="left">
                                                {parser(block.entry.sidebar_content)}
                                            </Text>
                                        ) : null}
                                    </Box>
                                </GridItem>

                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
};

export default MeetingsBlock;