import * as React from "react";
import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  ButtonGroup,
  useColorModeValue,
  Container,
  useToast,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  Link,
  Select,
  Center,
  Grid,
  GridItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spacer,
  Flex,
  Switch,
  Skeleton
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, baseUrl, recaptchaKey } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import { MotionBox } from "../components/motion";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Field, Formik } from "formik";
import headerBg from "../assets/images/bellefonte-header.jpg";
import yourGuide from "../assets/images/your-adventure.png";
import tastingTrail from "../assets/images/tasting-trail.jpg";
import biking from "../assets/images/biking.jpg";
import bellefonte from "../assets/images/victorian-bellefonte.jpg";
import cloudsBg from "../assets/images/cloud-background.png";
import grainyMap from "../assets/images/centre-county.png";
import formArrow from "../assets/images/form-arrow.png";
import popularBg from "../assets/images/popular-destinations.jpg";
import outdoorsIcon from "../assets/images/explore-outdoors-icon.svg";
import indoorsIcon from "../assets/images/explore-indoors-icon.svg";
import drinkingIcon from "../assets/images/drinking-adventure-icon.svg";
import culinaryIcon from "../assets/images/culinary-adventure-icon.svg";
import { initContainer, initPopup, initBubble, getPopupActions, getBubbleActions } from "typebot-js";
import Slider from "react-slick";
import { AuthContext } from "../context/AuthContext";
import "../style/style.css";
import parser from "html-react-parser";
import bg from '../../workercode';
import useWorker from '../useWorker';
import {
  showNotification,
  // startBackgroundProcess,
  resetTimer,
  notifyMe
} from '../utils/Worker/Worker';
import { CrowdRiffGallery } from 'react-crowdriff-gallery';
import { sliceEventStore } from "@fullcalendar/react";
import { useHistory } from "react-router-dom";
import MediaBlock from "../components/media-block";
import FavoritesBlock from "../components/favorites-block";
import ImageBlock from "../components/image-block";
import CrowdBlock from "../components/crowd-block";
import MapBlock from "components/map-block";
import CTABlock from "components/cta-block";
import ImagesBlock from "components/images-block";
import ImagesGridBlock from "components/images-grid-block";
import TabsBlock from "components/tabs-block";
import SlidesBlock from "components/slides-block";
import FpAdBlock from "components/fp-ad-block";
import FcAdBlock from "components/fc-ad-block";
import FeaturedAdBlock from "components/featured-ad-block";

const ValleysSlider = ({ page, block }) => {
  return (
    <Box className="explore">
      <Box>

        <div class="explore-header">
          <SimpleGrid columns={{ base: 1, md: 2 }}>
            <Box></Box>
            <Box pt={20}>
              <Heading
                as={'h2'}
                fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                lineHeight={'110%'}
                color="#17325B">
                Explore The Valleys
              </Heading>
              <Container maxW={'2xl'}>
                <Text color="#17325B" mt={1}>Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet.</Text>
              </Container>
            </Box>
          </SimpleGrid>
        </div>

        <Tabs variant="enclosed" w="100%" mt={5}>
          <SimpleGrid columns={{ base: 1, md: 2 }}>
            <Box></Box>
            <Center>
              <TabList borderBottom={0}>
                <Tab
                  whiteSpace={'nowrap'}
                  textTransform="uppercase"
                  borderRadius={0}
                  fontWeight="bold"
                  color="#17325B"
                  pt={0}
                  pb={0}
                  p={5}
                  _selected={{ color: "#17325B", bg: "#ffffff" }}
                >Things To Do</Tab>
                <span class="vertical-divider"></span>
                <Tab
                  whiteSpace={'nowrap'}
                  textTransform="uppercase"
                  borderRadius={0}
                  fontWeight="bold"
                  color="#17325B"
                  pt={0}
                  pb={0}
                  p={5}
                  _selected={{ color: "#17325B", bg: "#ffffff" }}
                >Food & Beverage</Tab>
                <span class="vertical-divider"></span>
                <Tab
                  whiteSpace={'nowrap'}
                  textTransform="uppercase"
                  borderRadius={0}
                  fontWeight="bold"
                  color="#17325B"
                  pt={0}
                  pb={0}
                  p={5}
                  _selected={{ color: "#17325B", bg: "#ffffff" }}
                >Lodging</Tab>
              </TabList>
            </Center>

          </SimpleGrid>

          <TabPanels>
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box mt={{ base: 0, md: '-250px' }} textAlign="left">
                  <Image src={page.header_image_url} mb={10} ml={-10} borderTopEndRadius={20} borderBottomEndRadius={20} />
                  <Box ml={20}>
                    <Heading
                      as={'h3'}
                      fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                      lineHeight={'110%'}
                      color="#17325B"
                      textAlign="left">
                      Valley Name 1
                    </Heading>
                    <strong>Valley Subhead</strong>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
                    <Button bg="#17325B" color="#B7E7D9">Explore More</Button>
                  </Box>
                </Box>
                <Box p={0}>
                  <Image src={grainyMap} />
                </Box>
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box mt={{ base: 0, md: '-250px' }} textAlign="left">
                  <Image src={headerBg} mb={10} ml={-10} mr={0} borderTopEndRadius={20} borderBottomEndRadius={20} />
                  <Box ml={20}>
                    <Heading
                      as={'h3'}
                      fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                      lineHeight={'110%'}
                      color="#17325B"
                      textAlign="left">
                      Valley Name 1
                    </Heading>
                    <strong>Valley Subhead</strong>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
                    <Button bg="#17325B" color="#B7E7D9">Explore More</Button>
                  </Box>
                </Box>
                <Box p={10}>
                  <Image src={headerBg} />
                </Box>
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box mt={{ base: 0, md: '-250px' }} textAlign="left">
                  <Image src={page.header_image_url} mb={10} ml={-10} borderTopEndRadius={20} borderBottomEndRadius={20} />
                  <Box ml={20}>
                    <Heading
                      as={'h3'}
                      fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                      lineHeight={'110%'}
                      color="#17325B"
                      textAlign="left">
                      Valley Name 1
                    </Heading>
                    <strong>Valley Subhead</strong>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
                    <Button bg="#17325B" color="#B7E7D9">Explore More</Button>
                  </Box>
                </Box>
                <Box p={10}>
                  <Image src={page.header_image_url} />
                </Box>
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Container maxW={'5xl'}>

          <Box bg="#8A8BE5" minHeight="100px" borderTopRadius={20} mt={5}>
            <SimpleGrid columns={{ base: 1, md: 2 }}>
              <Box p={2}>
                <Heading
                  as={'h2'}
                  fontSize={{ base: '2xl', sm: '5xl', md: '6xl' }}
                  lineHeight={'110%'}
                  color="#fff"
                  mb={0}
                  mt={1}>
                  Explore The Region
                </Heading>
              </Box>
              <Box p={2}>
                <Button bg="white" w="80%" borderRadius={20} mt={{ base: 0, md: 5 }}>View the Valleys</Button>
              </Box>
            </SimpleGrid>

          </Box>

        </Container>



      </Box>
    </Box>
  )
}

const EventsBlock = ({ page, block }) => {
  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState([]);

  const settingsSlickEvents = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const getEvents = (values = null) => {
    let data = [];
    axios.post(`${apiHost}/events/search`, values)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setEvents(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getCategories = () => {
    let data = [];
    axios.get(`${apiHost}/events/categories`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setCategories(data.slice(0, 5));

          const after = new Date().getDate() + 365;
          const afterDate = new Date().setDate(after);
          if (data.length > 0) {
              let values = { categories: [data[0].id], limit: 50 };
              values.dates = new Date().toISOString() + '|' + new Date(afterDate).toISOString();
              getEvents(values);
          }
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategories();
  }, []);

  return (
    <Box backgroundPosition="center center" backgroundSize="cover">
      <Box className="annual-events">
        <div class="events-header">
          <Heading
            as={'h2'}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}
            color="white">
            {block.title}
          </Heading>
          <Container maxW={'2xl'}>
            <Text color="white" mt={1}>
              {parser(block.entry.content)}
            </Text>
          </Container>
        </div>

        <Tabs variant="enclosed" w="100%">
          <Center>
            <TabList borderBottom={0} flexDirection={{ base: "column", lg: "row" }} overflowX="auto">
              {categories?.map((category, index) => (
                <Tab
                  key={`eventtab1-${index}`}
                  minWidth="80px"
                  whiteSpace={'nowrap'}
                  textTransform="uppercase"
                  pt={{ base: '5px', md: '25px' }}
                  borderRadius={0}
                  fontWeight="bold"
                  color="#BDEBDD"
                  _selected={{ color: "#8888E2" }}
                  onClick={() => {
                    const after = new Date().getDate() + 365;
                    const afterDate = new Date().setDate(after);
                    let values = { categories: [category.id], limit: 50 };
                    values.dates = new Date().toISOString() + '|' + new Date(afterDate).toISOString();

                    getEvents(values)
                  }}
                >{category.name}</Tab>
              ))}
            </TabList>
          </Center>

          <TabPanels>

            {categories?.map((category, index) => (
              <TabPanel key={`tabp-${category.id}`}>
                <Slider {...settingsSlickEvents} className="events-slider">

                  {events?.map((event, index) => (
                    <div key={`tabpe-${category.id}${event.id}`}>
                      <Box bg="#8FBD99" borderRadius={10} m={3}>
                        <Link href={`/events/${event.slug}`}>
                          <Image src={event.main_img} borderTopRadius={10} />
                          <Grid templateColumns='repeat(4, 1fr)'>
                            <GridItem colSpan={1} bg="#BDEDDF" p={2} pb={0} borderBottomStartRadius={10}>
                              <Text className="date-month">{event.starts_month}</Text>
                              <Text className="date-day">{event.starts_day}</Text>
                            </GridItem>
                            <GridItem colSpan={3} p={2} pb={0}>
                              <Heading fontSize="1.45rem" fontFamily="Roboto" fontWeight="500" textAlign="left" lineHeight="115%">{event.title}</Heading>
                            </GridItem>
                          </Grid>
                        </Link>
                      </Box>
                    </div>
                  ))}

                </Slider>
              </TabPanel>
            ))}

          </TabPanels>
        </Tabs>

        <Container maxW={'3xl'} pb={20}>

          <div class="calendar-container">
            <Box bg="#F26624" minHeight="100px" borderRadius={10} mt={5} p={5} pt={{ base: 5, md: 2 }}>
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box>
                  <Heading
                    as={'h2'}
                    fontSize={{ base: '2xl', sm: '3xl', md: '3xl' }}
                    lineHeight="80%"
                    color="#fff"
                    textAlign={{ base: "center", md: "left" }}
                    mb={0}
                    mt={2}>
                    Check Out Our Calendar
                  </Heading>
                  <Text fontSize="0.8rem" color="white" textAlign={{ base: "center", md: "left" }} maxWidth={{ base: "", md: "350px" }}>There are many other events happening in the Happy Valley region. Check out our calendar to see them all.</Text>
                </Box>
                <Box p={2}>
                  <Button as={Link} href="/events" bg="white" w="80%" fontSize={{ base: "0.6em", md: "14px" }} borderTopStartRadius={20} borderBottomStartRadius={20} borderRadius={20} mt={{ base: 0, md: 5 }} mb={{ base: 5, md: 0 }}>View the Calendar</Button>
                </Box>
              </SimpleGrid>
            </Box>
          </div>

        </Container>

      </Box>

    </Box>
  )
}

const ArticlesBlock = ({ page, block }) => {
  const [posts, setPosts] = useState([]);
  const [morePosts, setMorePosts] = useState([]);

  const settingsSlick = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 8000,
  };

  const getPosts = () => {
    let data = [];
    axios.get(`${apiHost}/news/list?limit=15`)
      .then(res => {
        data = res.data.data;
        if (res.status == 200) {
          setPosts(data.slice(0, 10));
          setMorePosts(data.slice(10, 14));
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <Box minHeight="900px" pb={20} className="dispatch-block">
      <Container maxW={'5xl'} zIndex={50} position="relative">
        <Center>
          <Image src={block.img} w="260px" mt={10} mb={10} />
        </Center>

        <Text color="white" mb={10}>
          {parser(block.entry.content)}
        </Text>

        <Slider {...settingsSlick} className="slick-dispatch">

          {posts?.map((post, index) => (
            <div key={`articlet1-${index}`}>
              <Grid templateColumns='repeat(6, 1fr)'>
                <GridItem colSpan={{ base: 6, md: 3 }}>
                  <Box bg="white" h={'100%'} minHeight={'400px'} backgroundImage={post.main_img} backgroundSize="cover">

                  </Box>
                </GridItem>
                <GridItem colSpan={{ base: 6, md: 2 }}>
                  <Box bg="white" textAlign="left" p={10} h={'100%'} borderTopEndRadius={15} borderBottomEndRadius={15}>
                    <Heading fontSize="1.75em" lineHeight="1.25em" fontFamily="Titling Comp Standard">{post.title}</Heading>
                    <Button as={Link} href={`${post.source_link}`} target="_blank" fontSize="0.7em" lineHeight="1em" p="4px 24px" borderTopStartRadius={5} borderBottomStartRadius={5} borderTopEndRadius={5} borderBottomEndRadius={5}>Read More</Button>
                  </Box>

                </GridItem>
                <GridItem colSpan={1}>

                  <div className="dispatch-counter">
                    <Box pt={"100%"}>{index + 1} <span>of</span> 10</Box>
                  </div>

                </GridItem>
              </Grid>
            </div>
          ))}

        </Slider>
        <Box className="dispatch-articles">
          <Grid templateColumns='repeat(4, 1fr)' mt={10}>
            {morePosts?.map((post, index) => (
              <GridItem key={`articlet2-${index}`} colSpan={{ base: 2, md: 1 }} borderEnd="3px solid #8fbd99" textAlign="left" p={5} className="dispatch-article">
                <Text color="white" as={'h3'}>{post.title}</Text>
                <Link href={`${post.source_link}`} target="_blank">Learn More</Link>
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

const NewsletterSignup = ({ page, block }) => {

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const history = useHistory();

  const [link, setLink] = useState('https://happyvalley.bookdirect.net/#/lodgings?campaign=PennState-interior-bookingwidget-lodging');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const settingsSlick = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 8000,
  };

  const getCategories = () => {
    let data = [];
    axios.get(`${apiHost}/events/categories`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setCategories(data);

          if (data[0]) {
            setSelectedCategory(data[0].slug)
          }
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const saveContact = (email) => {
    let data = [];
    axios.post(`${apiHost}/contacts/create`, { email })
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          console.log(data)
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(event.target.value);
  };

  const onEventCategoryClick = () => {
    history.push('/events/category/' + selectedCategory);
  };

  const handleCheckinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const replaced = event.target.value.replace(/-/g, "");
    setStartDate(replaced);
    setLink('https://happyvalley.bookdirect.net/#/lodgings?campaign=PennState-interior-bookingwidget-lodging&checkin=' + replaced + '&checkout=' + endDate + '&category_ids%5B%5D=103&locale=en');
  };

  const handleCheckoutChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const replaced = event.target.value.replace(/-/g, "");
    setEndDate(replaced);
    setLink('https://happyvalley.bookdirect.net/#/lodgings?campaign=PennState-interior-bookingwidget-lodgings&checkin=' + startDate + '&checkout=' + replaced + '&category_ids%5B%5D=103&locale=en');
  };

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  const downloadGuide = (email) => {
    //todo get guide link from variables
    saveContact(email);
    window.open('https://happyvalley.com/happy-valley-guides', '_blank');
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Box bg="#8FBD99">
      <Box bg="#DF6F39" zIndex={40} position="relative" minHeight={'180px'} pt={2} pr={10} pb={'50px'} pl={10} textAlign="left">
        <Heading fontSize={{ base: "2.5em", md: "3xl", lg: "5xl", xl: "6xl" }} textAlign="center" lineHeight={0.8} color="#fff" mt={5}>
          { block.title }
        </Heading>
        <Text as={'p'} fontSize="1.25em" textAlign="center" color="#fff">
          {block.entry.text} {' '}
          Use our easy <Link href={link} target="_blank" borderBottom="2px solid">BookDirect tool</Link> to find your perfect room.
        </Text>
      </Box>
      <Container maxW={'5xl'}>

        <Box bg="#8A8AE5" zIndex={40} position="relative" minHeight={'80px'} mt={-10} pt={5} pr={10} pb={5} pl={10} textAlign="left">

          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={2}>
            <Text as={'h2'} fontSize="1.65em" className="header" color="white">
              BOOK NOW
            </Text>
            <Input type="date" placeholder="Check In" bg="#19325B" color="#A1C7C6" border="0" onChange={handleCheckinChange}></Input>
            <Input type="date" placeholder="Check Out" bg="#19325B" color="#A1C7C6" border="0" onChange={handleCheckoutChange}></Input>
            <Button type="submit" as={Link} href={link} target="_blank" borderTopStartRadius={{ base: 5, md: 0 }} borderBottomStartRadius={{ base: 5, md: 0 }} borderTopEndRadius={5} borderBottomEndRadius={5}>
              LETS GO!
            </Button>
          </SimpleGrid>
        </Box>
      </Container >

      <Box mt={-10} zIndex={30} position="relative">
        <Slider {...settingsSlick} className="featured-slider">

          {block.entry.slides?.map((slide, index) => (
            <div key={`sl1-${index}`}>
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box pt="50px" pb="50px" pl={{ base: '20px', md: '100px' }} pr={{ base: '20px', md: '100px' }} bg="white" textAlign="left" ml={{ base: 0, md: '0px', lg: "0px", xl: "50px" }}>
                  {slide.top_title != null && (
                    <Button as={Link} href={slide.top_link} bg="#F26624" color="white" fontSize="12px" marginTop="20px" letterSpacing="2px" borderTopStartRadius={20} borderBottomStartRadius={20} borderTopEndRadius={20} borderBottomEndRadius={20}>{slide.top_title}</Button>
                  )}
                  {slide.title != null && (
                    <Heading fontSize={{ base: "2.5em", md: "3xl", lg: "5xl", xl: "6xl" }} textAlign="left" lineHeight={0.8} color="#5A729A" mt={5}>
                      {slide.title}
                    </Heading>
                  )}
                  {slide.content != null && (
                    <Box>{parser(slide.content)}</Box>
                  )}
                  {slide.link != null && (
                    <Link href={slide.link}>{slide.button_text}</Link>
                  )}
                </Box>
                {slide.img != null && (
                  <Box backgroundImage={`url(${slide.img})`} backgroundPosition="center center" backgroundRepeat="no-repeat" backgroundSize="cover" minHeight="400px">

                  </Box>
                )}
              </SimpleGrid>
            </div>
          ))}

        </Slider>
      </Box>
      <Container maxW={'5xl'} zIndex={50} position="relative">
        <Box bg="#587199" mt={-10} minHeight={{ base: "180px", md: '80px' }} pt={3} pb={3} pl={{ base: "10px", md: "30px" }} pr={{ base: "10px", md: "30px" }} textAlign="left" position="relative">
          <Formik
            enableReinitialize
            initialValues={{ email: "" }}
            onSubmit={(values, actions) => {
              downloadGuide(values.email);
              actions.setSubmitting(false);
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <SimpleGrid columns={{ base: 1, md: 3 }} spacing={2}>
                    <Box>
                      <Text as={'h2'} fontSize="1.55em" className="header" color="#BEE3DA">
                        FIND YOUR GUIDE
                      </Text>
                      <Text fontSize="0.8em" color="#BEE3DA">
                        Download your free guide.
                      </Text>
                    </Box>
                    <FormControl pt={2}>
                      <Field
                        name="email"
                        validate={validateEmail}
                      >
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.email && form.touched.email
                            }
                          >
                            <FormErrorMessage mt="-15px">
                              {form.errors.email}
                            </FormErrorMessage>
                            <Input
                              {...field}
                              id="email"
                              type="email"
                              placeholder="Your email address"
                              _placeholder={{ color: "#A1C7C6" }}
                              bg="#19325B"
                              color="#A1C7C6"
                              border="0"
                              value={values.email}
                              onChange={handleChange}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </FormControl>
                    <Box float="right" display="inline-block" position={{ base: "relative", md: "absolute" }} bottom="0" right="0px" pt={2}>
                      <Button right={{ base: "0px", md: "130px" }} borderTopStartRadius={{ base: 5, md: 0 }} borderBottomStartRadius={{ base: 5, md: 0 }} borderTopEndRadius={5} borderBottomEndRadius={5} type="submit">
                        DOWNLOAD
                      </Button>
                      <Image src={yourGuide} mt={{ base: "-130px", md: "-117px" }} ml={{ base: "160px", md: "50px" }} maxWidth="170px" className="adventure-img" />
                    </Box>
                  </SimpleGrid>
                </form>
              );
            }}
          </Formik>
        </Box>
      </Container >


      <Box mt={-10} zIndex={30} position="relative">
        <Slider {...settingsSlick} className="guide-slider">

          {block.entry.slider2_slides?.map((slide, index) => (
            <div key={`sl2-${index}`}>
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                {slide.img != null && (
                  <Box backgroundImage={`url(${slide.img})`} backgroundPosition="center center" backgroundRepeat="no-repeat" backgroundSize="cover" minHeight="400px">

                  </Box>
                )}
                <Box pt={'12px'} bg="white" w={{ base: "100%", md: "90%" }}>
                  <Box pt="50px" pb="50px" pl={{ base: '20px', md: '100px' }} pr={{ base: '20px', md: '100px' }} bg="white" textAlign="left" ml={{ base: 0, md: '0px', lg: "0px", xl: "50px" }}>
                    {slide.top_title != null && (
                      <Button as={Link} href={slide.top_link} bg="#F26624" color="white" fontSize="12px" marginTop="10px" letterSpacing="2px" borderTopStartRadius={20} borderBottomStartRadius={20} borderTopEndRadius={20} borderBottomEndRadius={20}>{slide.top_title}</Button>
                    )}
                    {slide.title != null && (
                      <Heading fontSize={{ base: "2.5em", md: "3xl", lg: "5xl", xl: "6xl" }} textAlign="left" lineHeight={0.8} color="#5A729A" mt={5}>
                        {slide.title}
                      </Heading>
                    )}
                    {slide.content != null && (
                      <Box>{parser(slide.content)}</Box>
                    )}
                    {slide.link != null && (
                      <Link href={slide.link}>{slide.button_text}</Link>
                    )}
                  </Box>
                </Box>
              </SimpleGrid>
            </div>
          ))}

        </Slider>
      </Box>

      <Container maxW={'5xl'} zIndex={50} position="relative">
        <Box bg="#19325B" mt={-10} pr={10} minHeight={{ base: "180px", md: '80px' }} pt={3} pb={0} pl={10} textAlign="left" position="relative">
          <Grid templateColumns='repeat(4, 1fr)' spacing={2}>
            <GridItem colSpan={{ base: 6, md: 1 }}>
              <Box>
                <Text as={'h2'} fontSize="1.55em" className="header" color="#BDEDE0">
                  FIND YOUR EVENT
                </Text>
                <Text fontSize="0.8em" color="#fff">
                  Events in Happy Valley
                </Text>
              </Box>
            </GridItem>
            <GridItem colSpan={{ base: 6, md: 2 }}>
              <FormControl className="getyourguide" pt={2}>
                <Select onChange={handleCategoryChange} bg="#19325B" color="#A1C7C6" border="0">
                  {categories?.map((cat, index) => (
                    <option key={index} value={cat.slug}>{cat.name}</option>
                  ))
                  }
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 6, md: 1 }}>
              <Button type='submit' borderTopStartRadius={{ base: 5, md: 0 }} borderBottomStartRadius={{ base: 5, md: 0 }} borderTopEndRadius={5} borderBottomEndRadius={5} w="100%" mt={2} onClick={onEventCategoryClick}>
                LETS GO!
              </Button>
            </GridItem>
          </Grid>
        </Box>
      </Container >


      <Box mt={'-48px'} zIndex={30} position="relative">
        <Slider {...settingsSlick} className="event-slider">

          {block.entry.slider3_slides?.map((slide, index) => (
            <div key={`sl3-${index}`}>
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box pt="50px" pb="50px" pl={{ base: '20px', md: '100px' }} pr={{ base: '20px', md: '100px' }} bg="white" textAlign="left" ml={{ base: 0, md: '0px', lg: "0px", xl: "50px" }}>
                  {slide.top_title != null && (
                    <Button as={Link} href={slide.top_link} bg="#F26624" color="white" fontSize="12px" marginTop="20px" letterSpacing="2px" borderTopStartRadius={20} borderBottomStartRadius={20} borderTopEndRadius={20} borderBottomEndRadius={20}>{slide.top_title}</Button>
                  )}
                  {slide.title != null && (
                    <Heading fontSize={{ base: "2.5em", md: "3xl", lg: "5xl", xl: "6xl" }} textAlign="left" lineHeight={0.8} color="#5A729A" mt={5}>
                      {slide.title}
                    </Heading>
                  )}
                  {slide.content != null && (
                    <Box>{parser(slide.content)}</Box>
                  )}
                  {slide.link != null && (
                    <Link href={slide.link}>{slide.button_text}</Link>
                  )}
                </Box>
                {slide.img != null && (
                  <Box backgroundImage={`url(${slide.img})`} backgroundPosition="center center" backgroundRepeat="no-repeat" backgroundSize="cover" minHeight="400px">

                  </Box>
                )}
              </SimpleGrid>
            </div>
          ))}

        </Slider>
      </Box>

    </Box >
  )
}

const HeroSlider = ({ page, block }) => {
  const settingsSlick = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 8000,
  };

  const authContext = useContext(AuthContext);

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 2 }}>
        {block.entry.embed != null && (
          <Box>
            {parser(block.entry.embed)}
          </Box>
        )}
        <Slider {...settingsSlick} className="hero-slider">

          {block.entry.slides?.map((slide, index) => (
            <div key={`sl-${block.id}${slide.id}`}>
              <Box backgroundImage={`url(${slide.img})`} backgroundPosition="center center" backgroundRepeat="no-repeat" backgroundSize="cover" minHeight={{ base: "700px", md: "560px" }}>
                <Box ml={{ base: 5, md: 20 }} className="slide-wrapper" pt={'125px'}>
                  <Text as="h3" textAlign="left" textTransform="uppercase" color="#BFE2DB" fontWeight="bolder" lineHeight="1.25em">{slide.top_title}</Text>

                  <Heading fontSize="2em" textAlign="left" lineHeight="0.8em" color="white">
                    {slide.title}
                  </Heading>

                  <Text textAlign="left" color="white" w={{ base: "90%", md: "70%" }}>
                    {slide.content != null && (
                      <Box>{parser(slide.content)}</Box>
                    )}
                    {slide.button_text != null && (
                      <Button as={Link} borderTopStartRadius={5} borderBottomStartRadius={5} href={slide.link}>{slide.button_text}</Button>
                    )}
                  </Text>
                </Box>
              </Box>
            </div>
          ))}

        </Slider>
      </SimpleGrid>
    </Box>
  )
}

const Home = (props) => {
  const toast = useToast();

  const [featured, setFeatured] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(true);

  const [experiences, setExperiences] = useState([]);
  const [activities, setActivities] = useState([]);

  const [page, setPage] = useState({ title: '', fields: {} });

  const bgColor = useColorModeValue('white', 'gray.900');
  const headingColor = useColorModeValue('gray.700', 'white');

  const authContext = useContext(AuthContext);

  const getPage = () => {
    let data = [];
    axios.get(`${apiHost}/page/get/headless-home`)
      .then(res => {
        data = res.data;
        console.log(data);
        if (res.status == 200) {
          setPage(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPage();
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title>{page.meta_title}</title>
        <meta name="description" content={page.meta_description} />
      </Helmet>

      <Box zIndex="banner">
        <Box bg="#15315A">
          <Container maxW={'5xl'}>
            <Grid templateColumns='repeat(6, 1fr)'>
              <GridItem colSpan={{ base: 12, md: 6 }}>
                <Text
                  textAlign="center"
                  fontFamily="american"
                  fontWeight={500}
                  fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                  lineHeight={'100%'}
                  color="#BDECDE"
                  pt={4}
                  pb={3}
                >{page.title}</Text>
              </GridItem>
            </Grid>
          </Container>
        </Box>
      </Box>

      {page.fields.home_blocks?.map((block, index) => (
        <Box key={`block-${index}`}>
          {
            {
              'anomaly.extension.hero_slider_block': <HeroSlider page={page} block={block} />,
              'anomaly.extension.newsletter_signup_block_block': <NewsletterSignup page={page} block={block} />,
              'anomaly.extension.article_slider_block': <ArticlesBlock page={page} block={block} />,
              'anomaly.extension.events_block_block': <EventsBlock page={page} block={block} />,
              'anomaly.extension.favorites_block_block': <FavoritesBlock page={page} block={block} />,
              'anomaly.extension.media_block_block': <MediaBlock page={page} block={block} />,
              'anomaly.extension.valleys_slider_block': <ValleysSlider page={page} block={block} />,
              'anomaly.extension.image_block_block': <ImageBlock page={page} block={block} />,
              'anomaly.extension.crowd_block_block': <CrowdBlock page={page} block={block} />,
              'anomaly.extension.map_block_block': <MapBlock page={page} block={block} />,
              'anomaly.extension.cta_block_block': <CTABlock page={page} block={block} />,
              'anomaly.extension.images_block_block': <ImagesBlock page={page} block={block} />,
              'anomaly.extension.images_grid_block_block': <ImagesGridBlock page={page} block={block} />,
              'anomaly.extension.tabs_block_block': <TabsBlock page={page} block={block} />,
              'anomaly.extension.slides_block_block': <SlidesBlock page={page} block={block} />,
              'anomaly.extension.fp_ad_block_block': <FpAdBlock page={page} block={block} />,
              'anomaly.extension.fc_ad_block_block': <FcAdBlock page={page} block={block} />,
              'anomaly.extension.featured_ad_block_block': <FeaturedAdBlock page={page} block={block} />,
            }[block.extension]
          }
        </Box>
      ))}

    </PageSlideFade>
  );
};

export default Home;
