import * as React from "react";
import { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Container,
  useToast,
  Flex,
  SimpleGrid,
  StackDivider,
  SpaceProps,
  HStack,
  Tag,
  Link,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Center,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Skeleton
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, baseUrl } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import { FaAccessibleIcon, FaBullseye, FaSearch, FaVolumeUp } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon, LatLng } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { MotionBox } from "../components/motion";
import ReactMarkdown from 'react-markdown';
import headerBg from "../assets/images/header-background.jpg";
import cloudsBg from "../assets/images/cloud-background.png";
import { Field, Formik } from "formik";
import Masonry from "react-masonry-css";
import "../style/style.css";
import useSound from "use-sound";
import parser from "html-react-parser";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listMonth from "@fullcalendar/list";
import listWeek from "@fullcalendar/list";
import listDay from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

const markdownTheme = {
  p: props => {
    const { children } = props;
    return (
      <Text mb={2} fontSize={'0.8em'}>
        {children}
      </Text>
    );
  },
};

const Calendar = (props) => {
  const toast = useToast();

  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(true);

  const [experiencesloading, setExperiencesLoading] = useState(false);
  const [experiences, setExperiences] = useState([]);

  const [activitiesloading, setActivitiesLoading] = useState(false);
  const [activities, setActivities] = useState([]);


  const [categoriesloading, setCategoriesLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const [selectedDestination, setSelectedDestination] = useState({});

  const [page, setPage] = useState({ fields: {} });

  const bgColor = useColorModeValue('white', 'gray.900');
  const headingColor = useColorModeValue('gray.700', 'white');

  const position = [40.7880456, -77.870124];

  const calendarRef = React.createRef();

  const [calendarDataCalendar, setCalendarData] = useState([]);

  // date picker
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);
  const propsConfigs = {
    inputProps: {
      borderRadius: "15px",
      size: "lg",
      fontSize: "sm"
    },
    dateNavBtnProps: {
      //variant: "outline"
    },
    dayOfMonthBtnProps: {
      defaultBtnProps: {
        borderColor: "tan.300",
        _hover: {
          background: 'blue.400',
        }
      },
      isInRangeBtnProps: {
        background: "blue.800",
        color: "tan",
      },
      selectedBtnProps: {
        background: "blue.800",
        color: "tan",
      },
      todayBtnProps: {
        borderColor: "blue.300",
        color: "blue.300",
        _hover: {
          color: 'blue.300',
        }
      }
    },
  };

  // drawers
  const { isOpen, onOpen, onClose } = useDisclosure()
  const firstField = useRef()

  const initialMarkers = [];
  const [markers, setMarkers] = useState(initialMarkers);

  const breakpointColumnsObj = {
    default: 3,
    1300: 2,
    900: 1
  };

  const LocationMarkers = () => {
    return (
      <React.Fragment>
        {markers.map(marker => <Marker
          position={marker.location}
          icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
          eventHandlers={{
            click: (e) => {
              setSelectedDestination(marker.data);
              onOpen();
            },
          }}>
        </Marker>)}
      </React.Fragment>
    );
  }

  const getDestinations = (values = null) => {
    let data = [];
    axios.post(`${apiHost}/events/search`, values)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setDestinations(data);

          /*
          let geomarkers = [];
          data.forEach(marker => {
            geomarkers.push({ data: marker, location: new LatLng(marker.location_latitude, marker.location_longitude) })
          });
          setMarkers(geomarkers);
          */

          let events = [];
          data.forEach((d, index) => {
            events.push({
              id: index,
              title: d.title,
              borderColor: "transparent",
              backgroundColor: "tan",
              className: "event-cal-title",
              start: d.starts_at,
              end: d.ends_at
            });
          });
          setCalendarData(events);

          setLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getCategories = () => {
    let data = [];
    axios.get(`${apiHost}/events/categories`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setCategories(data);
          setCategoriesLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getExperiences = () => {
    let data = [];
    axios.get(`${apiHost}/experiences/list`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setExperiences(data);
          setExperiencesLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getActivities = () => {
    let data = [];
    axios.get(`${apiHost}/activities/list`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setActivities(data);
          setActivitiesLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getPage = () => {
    let data = [];
    axios.get(`${apiHost}/page/get/calendar`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setPage(data);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  const showNotification = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 10000,
      isClosable: true
    });
  };

  const handleReset = (values = null) => {
    document.getElementById("category").value = "";
    const newDate = new Date(new Date().setFullYear(new Date().getFullYear() + 2));
    setSelectedDates([new Date(), new Date(newDate)]);

    values.dates = new Date().toISOString() + '|' + new Date(newDate).toISOString();
    getDestinations(values);

    let button = document.getElementsByClassName("fc-today-button")[0];
    button.click();

    //document.getElementById("experience").value = "";
    //document.getElementById("activity").value = "";
  };

  const handleDestinationClick = (destination) => {
    setSelectedDestination(destination);
    onOpen();
  }

  const eventClick = function (info) {
    info.jsEvent.preventDefault();
    console.log(info.event.id);
    setSelectedDestination(destinations[info.event.id]);
    onOpen();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getPage();
    //getExperiences();
    //getActivities();
    getCategories();
    //showNotification("Search for destinations notification", "info");

    const before = new Date().getDate() - 7;
    const after = new Date().getDate() + 180;
    const newDate = new Date().setDate(after);
    const beforeDate = new Date().setDate(before);
    let values = {};
    values.dates = new Date(beforeDate).toISOString() + '|' + new Date(newDate).toISOString();
    calendarRef.current.getApi().changeView('dayGridMonth', new Date());
    setSelectedDates([new Date(beforeDate), new Date(newDate)]);
    getDestinations(values);

    if (window.innerWidth < 900) {
      calendarRef.current.getApi().changeView('listWeek');
    }
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title>{page.meta_title}</title>
        <meta name="description" content={page.meta_description} />
      </Helmet>

      <Box className="home-header-container" backgroundImage={`url(${page.header_image_url})`} backgroundPosition="center center" backgroundSize="cover" minHeight="60vh">
        <Container maxW={'6xl'}>
          <Box display="flex" flexDirection="column" flex="1" minHeight="60vh">
            <Box mt={'auto'}>
              <Heading
                as={'h1'}
                fontFamily="american"
                fontWeight={500}
                fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                color="white"
                lineHeight={'100%'}
                textAlign="left"
                textShadow="0px 0px 5px #000"
              >
                {page.title} {' '}
              </Heading>
            </Box>
          </Box>
        </Container>
      </Box>
      {page.entry && page.entry.subtitle ? (
        <Box minHeight="25vh" bg="#8FBD99">
          <Container maxW={'6xl'}>
            <Box pt={10} pb={10}>
              <Text fontSize="3xl" color="white" textAlign="left">
                {page.entry.subtitle}
              </Text>
            </Box>
          </Container>
        </Box>
      ) : null}

      <Box p={10}>

        {loading && (
          <Box>
            <Skeleton height="80px" />
            <Text mt="-50px" mb="50px" fontWeight="bold">
              Loading Happy Valley Events ...
              </Text>
          </Box>
        )}

        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin, listMonth, listWeek, listDay]}
          initialView="dayGridMonth"
          headerToolbar={{
            start: 'title',
            center: 'dayGridMonth listWeek',
            end: 'today prevYear,prev,next,nextYear'
          }}
          contentHeight="600"
          events={calendarDataCalendar}
          editable={false}
          width="100%"
          minHeight="1000px"
          height="auto"
          slotWidth={10}
          customIcons={false}
          eventClick={eventClick}
        />

      </Box>

      <Drawer
        isOpen={isOpen}
        size="lg"
        placement="left"
        closeOnOverlayClick={true}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent
                  motionProps={{
                    initial: 'none',
                    animate: 'none',
                    exit: 'none',
                  }}
                >
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <Heading pb={0}>{selectedDestination.title}</Heading>
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing="24px">
              <Center>
                {selectedDestination.main_img && (
                  <Box
                    backgroundImage={`${selectedDestination.main_img}`}
                    backgroundSize="cover"
                    backgroundPosition="center center"
                    height="300px"
                    width={'100%'}
                    borderBottomStartRadius={'md'}
                  />
                )}
              </Center>

              {selectedDestination.content && (
                <Box className="content-block">{parser(selectedDestination.content)}</Box>
              )}

              <Stack>
                <Center><Text>{selectedDestination.summary}</Text></Center>
                <Center><Text>{selectedDestination.address}</Text></Center>
                <Center><Link href={`tel:+1${selectedDestination.phone}`}>{selectedDestination.phone}</Link></Center>
              </Stack>

            </Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            {selectedDestination ? (
              <Button as={Link} href={`/events/${selectedDestination.slug}`} >More Information</Button>
            ) : null}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

    </PageSlideFade>
  );
};

export default Calendar;
